import { isSameDay } from "date-fns";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useDrop } from "react-dnd";
import { Dates } from "../../calendar";
import * as types from "../../calendar-types";
import { DayEventsPerBed } from "./day-events-per-bed";
import Suggestion from "../../../../models/Suggestion";
import emitter from '../../../../util/event';
import APICenter from "../../../../apis/APICenter";

interface Props {
  date: Date;
  dates: Dates;
  room: types.Room;
  status?: types.RoomStatus;
  events: types.Event[];
  onChangeEvent: (event: types.Event) => void;
  suggestion: Suggestion[];
  suggestionPatientId: number | null;
}

function DayRoom({ date, dates, room, status, events, onChangeEvent, suggestion, suggestionPatientId }: Props) {

  const delteTransRequest = async (requestID) => {
    const apiCenter = new APICenter();
    const msg = await apiCenter.deleteTransferRequest(requestID);
    if (!msg) {// accepted view does nothing...
      // backend creation was successfull
      console.log("Transfer request could not be deltetd!");

    }
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "DAY",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item: any) {
      const event = item.ev as types.Event;

      // this is for handling the assignments that are dropped to the aktionsbereich

      // all drops to the aktionsbereich are handeled normally
      // except the ones that already come from the aktionsbereich
      // these cases are ignored

      // only allow DnD (drag and drops) from 
      /**
       * Zu belegende -> Ausgehende : show transferrequest
       * Zu belegende -> Nicht-Aktionsbereich : action as used
       */
      console.log("day-room to", room.id)
      console.log("from ", event.roomId)

      if (room.id === "Ausgehende_Anfragen") {
        // destination is Ausgehende_Anfragen
        if (event.roomId == "Zu_belegende_Patienten") {
          // drop into Ausgehende_Anfragen from Zu_belegende_Patienten -> open create transferrequest
          emitter.emit('showTransferRequest', "sender", event.assignment, suggestion, suggestionPatientId, date);
        }
        else {
          // destroy transfer request
          // if (event.assignment?.request) {
          //   delteTransRequest(event.assignment.request.id);

          // }
        }

      }
      // dont allow drops if they are from "zu belgende" to "zu belegende"
      if (!(room.id === "Zu_belegende_Patienten" && event.roomId == "Zu_belegende_Patienten")
        && !(["Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id))
        && !(["Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(event.roomId))) {
        /*
          (DropLogic)
          Dont remove the CustomEvent: The drop event is listened in the scheduler
        */
        const info = {
          assignmentId: event.assignmentId,
          bedId: room.id,
          from: date,
          dateDrag: item.date,
          fromBed: event.bedId,
          patientId: event.patient.id,
          split: isSameDay(date, event.from) ? false : true,
        };

        const newEvent = {
          assignmentId: event.assignmentId,
          bedId: room.id,
          roomId: room.id,
          from: event.from,
          to: event.to,
          dateDrag: item.date,
        };

        const droppedPatientInfo = new CustomEvent("droppedPatientInfo", {
          detail: {
            ...info,
          },
        });
        document.dispatchEvent(droppedPatientInfo);

        onChangeEvent({
          ...event,
          ...newEvent,
        });

      }

    },
  }));

  const eventsByBed = useMemo(
    () =>
      events.reduce((byBed, event) => {
        const eventsByBed = byBed[event.bedId] ?? [];
        return { ...byBed, [event.bedId]: [...eventsByBed, event] };
      }, {} as Record<string, types.Event[]>),
    [events]
  );

  let firstBlocked = false;
  let secondBlocked = false;

  if (events.length > 0 && events.length >= room.beds.length) {
    const evs = events.map((ev) => {
      return {
        from: ev.from,
        to: ev.to,
        bed: ev.bedId,
      };
    });

    let blockedBeds: string[] = [];

    firstBlocked =
      evs
        .map((ev) => {
          return {
            blocked:
              ev.from <
              dayjs(dayjs(date).format("YYYY-MM-DD")).add(12, "hour").toDate(),
            bed: ev.bed,
          };
        })
        .filter((fb) => {
          const bedBlocked = blockedBeds.includes(fb.bed);
          if (fb.blocked && !bedBlocked) {
            blockedBeds.push(fb.bed);
            return true;
          }
          return false;
        }).length === room.beds.length;

    blockedBeds = [];

    secondBlocked =
      evs
        .map((ev) => {
          return {
            blocked:
              ev.to >
              dayjs(dayjs(date).format("YYYY-MM-DD")).add(12, "hour").toDate(),
            bed: ev.bed,
          };
        })
        .filter((fb) => {
          const bedBlocked = blockedBeds.includes(fb.bed);
          if (fb.blocked && !bedBlocked) {
            blockedBeds.push(fb.bed);
            return true;
          }
          return false;
        }).length === room.beds.length;
  }

  const hover = isOver ? "bg-slate-200 rounded-md" : "";
  const notBlocked =
    !(["Zu_belegende_Patienten", "Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id)) && !room.id.includes("ward") && !status?.expanded
      ? "bg-green-600 border-y"
      : `border border-x ${(["Zu_belegende_Patienten", "Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id)) ? "bg-[#E6F6ED] text-white" : "bg-gray-200"}`;
  const blocked =
    !(["Zu_belegende_Patienten", "Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id)) && !room.id.includes("ward")
      ? "bg-red-600 border-y -mt-[1px]"
      : `border border-x ${(["Zu_belegende_Patienten", "Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id)) ? "bg-[#E6F6ED] text-white" : "bg-gray-200"}`;

  return (
    <div
      className={`w-full ${hover} ${(["Zu_belegende_Patienten", "Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id)) ? "mb-2" : ""}`}
      ref={(["Zu_belegende_Patienten"].includes(room.id)) ? drop : undefined}
    >
      <div className={`h-6 ${notBlocked}`}>
        {firstBlocked && !secondBlocked && !status?.expanded && (
          <div className={`h-6 w-1/2 ${blocked}`} />
        )}
        {secondBlocked && !firstBlocked && !status?.expanded && (
          <div className={`h-6 w-1/2 ml-auto ${blocked}`} />
        )}
        {secondBlocked && firstBlocked && !status?.expanded && (
          <div className={`h-6 ${blocked}`} />
        )}
      </div>
      {status?.expanded && (
        <div className="flex flex-col text-sm">
          {room.beds.map((bed) => {
            const events = eventsByBed[bed.id] ?? [];
            return (
              <DayEventsPerBed
                bed={bed}
                room={room}
                date={date}
                dates={dates}
                key={`${bed.id}-${date.getTime()}`}
                defaultEvents={events}
                onChangeEvent={onChangeEvent}
                suggestion={suggestion}
                suggestionPatientId={suggestionPatientId}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export { DayRoom };

