import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

//import Chakra UI components
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  HStack,
} from "@chakra-ui/react"
import APICenter from "../apis/APICenter"
import emitter from "../util/event"
import React from "react"
import Bed from "../models/Bed"
import DateTimeInput from "./common/DateTimeInput"
import DialogHeader from "./common/DialogHeader"

type BlockBedComponentProps = {
  bed: Bed
}
// EditPatient Modal popup in PatientView
const BlockBedComponent = ({ bed }: BlockBedComponentProps) => {
  const [showBlockBed, setShowBlockBed] = useState(false)
  const [start, setStart] = useState("")
  const [end, setEnd] = useState("")

  const apiCenter = new APICenter()
  const navigate = useNavigate()

  const dateToString = (date: Date) => {
    const dateString = date.toISOString().split(".")[0]
    if (dateString.length > 16) {
      return dateString.slice(0, 16)
    } else {
      return dateString
    }
  }

  useEffect(() => {
    const callback = () => setShowBlockBed(true)

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setStart(dateToString(today))
    setEnd(dateToString(tomorrow))

    emitter.on(`blockBed/${bed.id}`, callback)
    return () => {
      emitter.removeListener(`blockBed/${bed.id}`, callback)
    }
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault() //so it does not submit to a page

    if (end < start) {
      alert("Start muss vor Ende liegen")
      return
    }

    const msg = await apiCenter.blockBed(bed, start, end)

    if (msg.isSucc) {
      emitter.emit("update_room_view")
      setShowBlockBed(false)
      navigate("/")
    } else {
      alert("Die Blockierung ist leider nicht möglich.")
    }
  }

  return (
    <Modal
      size="3xl"
      isCentered
      isOpen={showBlockBed}
      scrollBehavior="inside"
      onClose={() => setShowBlockBed(false)}
      motionPreset="slideInBottom"
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(0deg)" />

      <ModalContent maxW="900" maxH="" alignItems="center">
        <DialogHeader>Bett {bed.name} blockieren</DialogHeader>
        <ModalCloseButton />
        <ModalBody className="addPatientBox">
          <Box as="form" my="10" onSubmit={onSubmit}>
            <HStack spacing="30" alignItems="top">
              <Box w="350" alignContent="top">
                <DateTimeInput caption="Start" name="start" value={start} onChange={v => setStart(v)} />
                <DateTimeInput caption="Ende" name="end" value={end} onChange={v => setEnd(v)} />
              </Box>
            </HStack>

            <Button width="full" type="submit" colorScheme="brand">
              Änderungen Speichern
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default BlockBedComponent
