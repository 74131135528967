import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";
import reportWebVitals from "./tests/reportWebVitals";
import PrivateRoute from "./util/PrivateRoute";
import DashboardView from "./views/DashboardView";
import LoginView from "./views/LoginView";

const App = () => {
	return (
		<>
			<BrowserRouter>
				<AuthProvider>
					<Routes>
						<Route path="login" element={<LoginView />} />
						<Route element={<PrivateRoute />}>
							<Route path="/*" element={<DashboardView />} />
						</Route>
					</Routes>
				</AuthProvider>
			</BrowserRouter>
			<Outlet />
		</>
	);
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
