import { Assignment, AssignmentType } from "../models/Assignment";
import Bed from "../models/Bed";
import Patient from "../models/Patient";
import Room from "../models/Room";
import SBFSSettings from "../models/SBFSSettings";
import Stats from "../models/Stats";
import StatsRange from "../models/StatsRange";
import Suggestion from "../models/Suggestion";
import Ward from "../models/Ward";
import apiClient from "./APIClient";
import {
	AssignmentFailMessage,
	AssignmentSuccMessage,
	FetchAssignmentsMessage,
	FetchBedsMessage,
	FetchPatientMessage,
	FetchRoomsMessage,
	FetchSBFSSettingsMessage,
	FetchStatsMessage,
	FetchStatsRangeMessage,
	FetchSuggestionMessage,
	FetchWardsMessage,
	Message,
	ProposedPlanMessage,
	SuggestionMessage,
} from "./Message";
// The global API Call center will be exposed to every component
// which want to fetch data from backend
class APICenter {

	abortController;

	constructor() {
		this.abortController = new AbortController();
	}

	abortRequest() {
		this.abortController.abort();
	}

	// called in components/TransferRequestComponent.jsx
	// add a transferRequest into the database
	async createTransferRequest(
		sender_ward,
		receiver_ward,
		comment_sender_ward,
		assignment
	) {
		try {
			const res = await apiClient.post("transferrequests/", {
				json: {
					request_sender_ward: sender_ward,
					request_receiver_ward: receiver_ward,
					comment_sender_ward: comment_sender_ward,
					assignment: assignment
				},
			});
			try {
				const data = await res.json();
				return new Message(true, data);
			} catch {
				return new Message(false);
			}

		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}
	// Called in component/TransferRequestComponent.jsx
	async updateTransferRequest(
		requestId,
		assignment
	) {
		try {
			const res = await apiClient.put(`transferrequests/${requestId}/`, {
				json: {
					assignment: assignment
				},
			});
			try {
				const data = await res.json();
				return new Message(true, data);
			} catch {
				return new Message(false);
			}

		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}
	// Called in component/TransferRequestComponent.jsx
	async deleteTransferRequest(requestId) {
		try {
			const res = await apiClient.delete(`transferrequests/${requestId}/`, {
				json: {
					id: requestId,
				},
			});
			return new Message(true, res.statusText);
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	async fetchRoomsToWard(params) {
		//Fetch all rooms by a given ward id
		const res = await apiClient.get(`wards/${params.ward_id}/room?ranking=True`)
		if (res.statusText === 'Unauthorized') {
			return new Message(false, res.statusText)
		} else {
			console.log(res);
			const data = await res.json()
			console.log(data);
			const rooms = []
			for (let idx in data) {
				const room = new Room(
					data[idx].id,
					data[idx].name,
					data[idx].size
				)
				console.log(room)
				rooms.push(room)
			}
			return new FetchRoomsMessage(true, res.statusText, rooms)
		}
	}

	async fetchBedsToRoom(params) {
		//Fetch all beds by a given room id
		const res = await apiClient.get(`rooms/${params.room_id}/bed?ranking=True`)
		if (res.statusText === 'Unauthorized') {
			return new Message(false, res.statusText)
		} else {
			console.log(res);
			const data = await res.json()
			console.log(data);
			const beds = []
			for (let idx in data) {
				const bed = new Bed(
					data[idx].active,
					data[idx].id,
					data[idx].name,
					data[idx].quick_access_possible,
					data[idx].room,
					data[idx].type
				)
				beds.push(bed)
			}
			return new FetchBedsMessage(true, res.statusText, beds)
		}
	}

	async addWard(ward, automaticCreateRequested) {
		try {
			const res = await apiClient.post('wards/', {
				json: {
					ward: ward,
					automatic_create_requested: automaticCreateRequested,
				},
			})

			const data = await res.json();
			ward.id = data.ward.id
		}
		catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}

	}
	async fetchWardById(ward_id) {
		try {
			const res = await apiClient.get(`wards/${ward_id}`)
			console.log(res);
			const data = await res.json()
			console.log(data);

			const wards = new Ward(
				data.id,
				data.name,
				data.size,
			)
			console.log(wards)
			return new FetchWardsMessage(
				true,
				res.statusText,
				wards
			)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new FetchWardsMessage(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	async addRoom(room, automaticCreateRequested) {
		try {
			const res = await apiClient.post('rooms/', {
				json: {
					room: room,
					automatic_create_requested: automaticCreateRequested,
				},
			})
			const data = await res.json();
			room.id = data.room.id
		}
		catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}

	}
	async fetchRoomById(room_id) {
		try {
			const res = await apiClient.get(`rooms/${room_id}`)
			console.log(res);
			const data = await res.json()
			console.log(data);
			const rooms = new Room(
				data.id,
				data.name,
				data.size,
				data.ward
			)
			console.log(rooms)
			return new FetchRoomsMessage(
				true,
				res.statusText,
				rooms
			)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new FetchRoomsMessage(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	async addBed(bed) {
		try {
			const res = await apiClient.post('beds/', {
				json: {
					bed: bed,
				},
			})
			const data = await res.json();
		}
		catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	//DUPLICATE? 
	// Called in component/AddPatientComponent.jsx
	// Add a new patient into database
	async addPatient(patient, dischargeDate, automaticAssignmentRequested, arrivalDate) {
		try {
			const res = await apiClient.post('patients/', {
				json: {
					patient: patient,
					discharge_date: dischargeDate,
					automatic_assignment_requested: automaticAssignmentRequested,
					arrival_date: arrivalDate,
				},
			})

			try {
				const res = await apiClient.get(`patients/${patientId}`)
				console.log(res);
				const data = await res.json()
				console.log(data);

				const dataPatient = data.patient
				const dataHomeWard = data.home_ward
				let bed = undefined
				let room = undefined
				let homeWard = undefined

				const patient = new Patient(
					dataPatient.lastname,
					dataPatient.firstname,
					dataPatient.isolation_needed,
					dataPatient.bed_place_isolation_needed,
					dataPatient.private_insurance,
					dataPatient.single_room_needed,
					dataPatient.birth_date,
					dataPatient.gender,
					dataPatient.disturbs,
					dataPatient.monitor_bed_needed,
					dataPatient.quick_access_needed,
					dataPatient.double_room_needed,
					dataPatient.id,
					dataPatient.home_ward,
					dataPatient.additional_patient_information
				)
				console.log(dataPatient.additional_patient_information)
				console.log(patient)
				if (dataHomeWard) {
					homeWard = new Ward(
						dataHomeWard.id,
						dataHomeWard.name,
						dataHomeWard.size,
					)
				}

				const assignments = []
				for (let idx in data.assignment) {
					const dataAssignment = data.assignment[idx]
					const assignment = new Assignment(
						stringToAssignmentType(dataAssignment.assignment_status),
						dataAssignment.bed,
						dataAssignment.start,
						dataAssignment.end,
						dataAssignment.id,
						dataAssignment.proposed,
						patient,
						dataAssignment.request
					)
					assignments.push(assignment)
				}

				const suggestionAvailable = Boolean(data.suggestions_exists)
				const suggestions = []
				if (suggestionAvailable) {
					for (let idx in data.suggestions) {
						const dataSuggestion = data.suggestions[idx]
						const suggestion = new Suggestion(
							dataSuggestion.id,
							dataSuggestion.suggestion_patient_id,
							dataSuggestion.patient,
							dataSuggestion.patient_name,
							dataSuggestion.start,
							dataSuggestion.end,
							dataSuggestion.bed_old,
							dataSuggestion.bed_new,
							dataSuggestion.bed_name_old,
							dataSuggestion.bed_name_new,
							dataSuggestion.room_id_old,
							dataSuggestion.room_id_new
						)
						suggestions.push(suggestion)
					}
				}
				return new FetchPatientMessage(
					true,
					res.statusText,
					patient,
					homeWard,
					assignments,
					suggestionAvailable,
					suggestions
				)
			} catch (error) {
				if (error.name === 'HTTPError') {
					const errorJson = await error.response.json()
					return new FetchPatientMessage(false, errorJson)
				}
				return new Message(false, error)
			}
		} catch (error) {
			console.log(error);
		}
	}

	// Called in views/RoomView.jsx
	// Get all Beds information
	async fetchBeds(requestParams) {
		try {
			const res = await apiClient.get("beds", {
				...requestParams
			});
			const data = await res.json();
			const dataArray = [];
			for (let idx in data) {
				const dataItem = data[idx];
				dataArray.push(
					new Bed(
						dataItem.active,
						dataItem.id,
						dataItem.name,
						dataItem.quick_access_possible,
						dataItem.room,
						dataItem.type
					)
				);
			}
			return new FetchBedsMessage(true, res.statusText, dataArray);
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	async fetchBedById(bed_id) {
		try {
			const res = await apiClient.get(`beds/${bed_id}`)
			console.log(res);
			const data = await res.json()
			console.log(data);

			const beds = new Bed(
				data.active,
				data.id,
				data.name,
				data.quick_access_possible,
				data.room,
				data.type
			)
			console.log(beds)
			return new FetchBedsMessage(
				true,
				res.statusText,
				beds
			)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new FetchBedsMessage(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Called in views/RoomView.jsx
	// Get all Rooms information
	async fetchRooms() {
		try {
			const res = await apiClient.get('rooms')
			const data = await res.json()
			const dataArray = []
			for (let idx in data) {
				const dataItem = data[idx]
				dataArray.push(
					new Room(dataItem.id, dataItem.name, dataItem.size, dataItem.ward)
				)
			}
			return new FetchRoomsMessage(true, res.statusText, dataArray)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// fetch assignments information
	async fetchAssignments(params, requestParams) {
		try {
			const res = await apiClient.get("assignments/", {
				...requestParams,
				searchParams: params,
			});
			const data = await res.json();
			const dataArray = this.getAssignmentArray(data)

			return new FetchAssignmentsMessage(true, res.statusText, dataArray);
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	// Fetch assignments of given time frame of all beds in all rooms
	async fetchAssignmentsInAllRooms(params) {
		try {
			const response = await apiClient.get('assignments/room/', {
				searchParams: params,
			})
			const data = await response.json()
			return {
				assignments_of_rooms: data.assignments_of_rooms,
				assignments_without_bed: data.assignments_without_bed,
			}
		} catch (error) {
			console.error(error)
		}
	}

	// Update assignment
	async updateAssignment(assignmentId, data) {
		try {
			await apiClient.put(`assignments/${assignmentId}/`, {
				json: data,
			})
			return {
				isSuccessful: true,
			}
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				console.error(errorJson)
				return {
					isSuccessful: false,
					reasonFailed: errorJson,
				}
			}
			console.error(error)
			return {
				isSuccessful: false,
				reasonFailed: 'Unknown Error',
			}
		}
	}


	// Set the information of a pre-existed room
	async setRoom(editedRoom) {
		try {
			const res = await apiClient.put(`rooms/${editedRoom.id}/`, {
				json: {
					room: editedRoom,
				},
			})
			return new Message(true, res.statusText)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Set the information of a pre-existed ward
	async setWard(editedWard) {
		try {
			const res = await apiClient.put(`wards/${editedWard.id}/`, {
				json: {
					ward: editedWard,
				},
			})
			return new Message(true, res.statusText)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Set the information of a pre-existed bed
	async setBed(editedBed) {
		try {
			const res = await apiClient.put(`beds/${editedBed.id}/`, {
				json: {
					bed: editedBed,
				},
			})
			return new Message(true, res.statusText)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Called in views/RoomView.jsx
	// fetch current suggestion in server
	async fetchSuggestion() {
		try {
			const res = await apiClient.get('suggestedchanges/')
			const data = await res.json()

			if (data.suggestion_exists) {
				const patientId = data.patient_id
				const suggestions = []
				for (let idx in data.suggestions) {
					const dataSuggestion = data.suggestions[idx]
					const suggestion = new Suggestion(
						dataSuggestion.id,
						dataSuggestion.suggestion_patient_id,
						dataSuggestion.patient,
						dataSuggestion.patient_name,
						dataSuggestion.start,
						dataSuggestion.end,
						dataSuggestion.bed_old,
						dataSuggestion.bed_new,
						dataSuggestion.bed_name_old,
						dataSuggestion.bed_name_new,
						dataSuggestion.room_id_old,
						dataSuggestion.room_id_new
					)
					suggestions.push(suggestion)
				}
				return new FetchSuggestionMessage(
					true,
					res.statusText,
					patientId,
					suggestions
				)
			} else {
				return new Message(false, 'No suggestion avaliable')
			}
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Called in views/PatientView.jsx
	// Change the Patient Status (F,P,R,W,...)
	async setPatientStatus(assignment, type) {
		try {
			const res = await apiClient.put(`assignments/${assignment.id}/status/`, {
				json: {
					patient: assignment.patient.id,
					assignment_status: type.toString(),
				},
			})
			return new Message(true, res.statusText)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Called in views/PatientView.jsx => PatientDetailComponent
	// Calculate the schedule optimization
	async calculateSuggestion(assignment) {
		try {
			const res = await apiClient.put(
				`suggestedchanges/${assignment.id}/compute/`
			)
			const data = await res.json()
			if (Object.hasOwn(data, 'suggestions')) {
				const suggestions = []
				for (let idx in data.suggestions) {
					const dataSuggestion = data.suggestions[idx]
					const suggestion = new Suggestion(
						dataSuggestion.id,
						dataSuggestion.suggestion_patient_id,
						dataSuggestion.patient,
						dataSuggestion.patient_name,
						dataSuggestion.start,
						dataSuggestion.end,
						dataSuggestion.bed_old,
						dataSuggestion.bed_new,
						dataSuggestion.bed_name_old,
						dataSuggestion.bed_name_new,
						dataSuggestion.room_id_old,
						dataSuggestion.room_id_new
					)
					suggestions.push(suggestion)
				}
				return new SuggestionMessage(true, res.statusText, suggestions)
			}
			if (Object.hasOwn(data, 'reason')) {
				return new SuggestionMessage(false, res.statusText, [], data.reason)
			}
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Called in component/EditPatientComponent.jsx
	// Delete the patient with specified patient id
	async deletePatient(patientId) {
		try {
			const res = await apiClient.delete(`patients/${patientId}/`, {
				json: {
					id: patientId,
				},
			})
			return new Message(true, res.statusText)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Called in component/EditPatientComponent.jsx
	// Set the information of a pre-existed patient
	async setPatient(editedPatient) {
		try {
			const res = await apiClient.put(`patients/${editedPatient.id}/`, {
				json: {
					patient: editedPatient,
				},
			})
			return new Message(true, res.statusText)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Called in component/EditAssignmentComponent.jsx
	async setAssignment(editedAssignment) {
		try {
			let res
			if (editedAssignment.bed) {
				res = await apiClient.put(`assignments/${editedAssignment.id}/`, {
					json: {
						id: editedAssignment.id,
						patient: editedAssignment.patient.id,
						start: editedAssignment.start,
						end: editedAssignment.end,
						assignment_status: editedAssignment.assignment_status,
						bed: editedAssignment.bed.id
					},
				})
			} else {
				res = await apiClient.put(`assignments/${editedAssignment.id}/`, {
					json: {
						id: editedAssignment.id,
						patient: editedAssignment.patient.id,
						start: editedAssignment.start,
						end: editedAssignment.end,
						assignment_status: editedAssignment.assignment_status,
					},
				})
			}
			return new Message(true, res.statusText)
		} catch (error) {
			return new Message(false, error)
		}
	}

	async deleteAssignment(assignmentId) {
		try {
			const res = await apiClient.delete(`assignments/${assignmentId}/`, {
				json: {
					id: assignmentId,
				},
			})
			return new Message(true, res.statusText)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Remove the patient from the bed
	async clearAssignment(assignmentId, date) {
		try {
			const res = await apiClient.delete(`assignments/${assignmentId}/bed/`, {
				json: {
					date: date,
				},
			})
			return new Message(true, res.statusText)
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Update the assigned bed of the patient
	async updateAssignedBed(assignmentId, bedId, date) {
		try {
			const res = await apiClient.put(`assignments/${assignmentId}/bed/`, {
				json: {
					bed: bedId,
					date: date,
				},
			})
			try {
				const data = await res.json()
				return new Message(true, data)
			} catch {
				return new Message(true)
			}
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json()
				return new Message(false, errorJson)
			}
			return new Message(false, error)
		}
	}

	// Called in component/EditBlockBedComponent.jsx
	async editBlockAssignment(assignmentId, start, end) {
		try {
			const res = await apiClient.put(`assignments/${assignmentId}/`, {
				json: {
					start: start,
					end: end,
				},
			})
			try {
				const data = await res.json()
				return new Message(true, data)
			} catch {
				return new Message(true)
			}
		} catch (error) {
			return new Message(false, error)
		}
	}



	// Called in component/AddPatientComponent.jsx
	// Add a new patient into database
	async addPatient(
		patient,
		dischargeDate,
		automaticAssignmentRequested,
		arrivalDate
	) {
		try {
			const res = await apiClient.post("patients/", {
				json: {
					patient: patient,
					discharge_date: dischargeDate,
					automatic_assignment_requested:
						automaticAssignmentRequested,
					arrival_date: arrivalDate,
				},
			});

			if (automaticAssignmentRequested === true) {
				const data = await res.json();
				patient.id = data.patient.id;
				if (data.success_algorithm) {
					const bed = new Bed(
						data.bed.active,
						data.bed.id,
						data.bed.name,
						data.bed.quick_access_possible,
						data.bed.room,
						data.bed.type
					);
					const room = new Room(
						data.room.id,
						data.room.name,
						data.room.size,
						data.room.ward
					);
					return new AssignmentSuccMessage(
						true,
						res.statusText,
						patient,
						bed,
						room
					);
				} else {
					const message = new AssignmentFailMessage(
						true,
						res.statusText,
						patient,
						data.reason
					);
					if (data.hasOwnProperty("suggestions")) {
						message.suggestions = data.suggestions;
					}
					return message;
				}
			}
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	// Called in veiws/PatientView.jsx
	// Get the patient information and assignment, bed, room, etc.
	async fetchPatient(patientId) {
		function stringToAssignmentType(s) {
			switch (s) {
				case "F":
					return AssignmentType.F;
				case "R":
					return AssignmentType.R;
				case "P":
					return AssignmentType.P;
				case "W":
					return AssignmentType.W;
				case "C":
					return AssignmentType.C;
				case "D":
					return AssignmentType.D;
				default: {
					throw new Error("No such Assignment Type.");
				}
			}
		}

		try {
			const res = await apiClient.get(`patients/${patientId}`);
			console.log(res);
			const data = await res.json();
			console.log(data);

			const dataPatient = data.patient;
			const dataHomeWard = data.home_ward;

			let bed = undefined;
			let room = undefined;
			let homeWard = undefined;

			const patient = new Patient(
				dataPatient.lastname,
				dataPatient.firstname,
				dataPatient.isolation_needed,
				dataPatient.bed_place_isolation_needed,
				dataPatient.private_insurance,
				dataPatient.single_room_needed,
				dataPatient.birth_date,
				dataPatient.gender,
				dataPatient.disturbs,
				dataPatient.monitor_bed_needed,
				dataPatient.quick_access_needed,
				dataPatient.double_room_needed,
				dataPatient.id,
				dataPatient.home_ward,
				dataPatient.additional_patient_information
			);
			console.log(dataPatient.additional_patient_information);
			console.log(patient);
			if (dataHomeWard) {
				homeWard = new Ward(
					dataHomeWard.id,
					dataHomeWard.name,
					dataHomeWard.size
				);
			}

			const assignments = [];
			for (let idx in data.assignment) {
				const dataAssignment = data.assignment[idx];
				const assignment = new Assignment(
					stringToAssignmentType(dataAssignment.assignment_status),
					dataAssignment.bed,
					dataAssignment.start,
					dataAssignment.end,
					dataAssignment.id,
					dataAssignment.proposed,
					patient,
					dataAssignment.request
				);
				assignments.push(assignment);
			}

			const suggestionAvailable = Boolean(data.suggestions_exists);
			const suggestions = [];
			if (suggestionAvailable) {
				for (let idx in data.suggestions) {
					const dataSuggestion = data.suggestions[idx];
					const suggestion = new Suggestion(
						dataSuggestion.id,
						dataSuggestion.suggestion_patient_id,
						dataSuggestion.patient,
						dataSuggestion.patient_name,
						dataSuggestion.start,
						dataSuggestion.end,
						dataSuggestion.bed_old,
						dataSuggestion.bed_new,
						dataSuggestion.bed_name_old,
						dataSuggestion.bed_name_new,
						dataSuggestion.room_id_old,
						dataSuggestion.room_id_new
					);
					suggestions.push(suggestion);
				}
			}
			return new FetchPatientMessage(
				true,
				res.statusText,
				patient,
				homeWard,
				assignments,
				suggestionAvailable,
				suggestions
			);
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new FetchPatientMessage(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	// Called in views/UtilizationView.jsx
	// Will be called for fetching status
	async fetchStats(params, requestParams) {
		try {
			const res = await apiClient.get(`stats`, {
				...requestParams,
				searchParams: params,
			});
			const data = await res.json();

			return new FetchStatsMessage(
				true,
				res.statusText,
				new Stats(
					data.current_occupancy_total,
					data.current_occupancy_percent,
					data.planned_occupancy_total,
					data.planned_occupancy_percent,
					data.free_beds,
					data.occupied_beds,
					data.num_waiting_patients,
					data.start_date,
					data.end_date
				)
			);
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	async fetchStatsRange(params, requestParams) {
		try {
			const res = await apiClient.get(`stats/range`, {
				...requestParams,
				searchParams: params,
			});
			const { stats, wards } = await res.json();

			return new FetchStatsRangeMessage(true, res.statusText, {
				stats: stats.map(
					(stat) =>
						new StatsRange(
							stat.current_occupancy_total,
							stat.current_occupancy_percent,
							stat.planned_occupancy_total,
							stat.planned_occupancy_percent,
							stat.reserved_occupancy_total,
							stat.free_beds,
							stat.occupied_beds,
							stat.num_waiting_patients,
							stat.wards,
							stat.start_date,
							stat.end_date
						)
				),
				wards,
			});
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	// Called in views/SettingsView.jsx
	async fetchSBFSSettings(settingsId) {
		try {
			const res = await apiClient.get(`sbfssettings/${settingsId}`);
			const data = await res.json();

			return new FetchSBFSSettingsMessage(
				true,
				"undefined",
				new SBFSSettings(
					data.id,
					data.weight_minimize_rooms,
					data.weight_no_disturbing,
					data.weight_group_age,
					data.config_plan_ahead,
					data.config_match_threshold,
					data.config_max_num_new_plans
				)
			);
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	// Update the sbfs settings
	async updateSBFSSettings(editedSettings) {
		try {
			const res = await apiClient.put(
				`sbfssettings/${editedSettings.id}/`,
				{
					json: editedSettings,
				}
			);
			try {
				const data = await res.json();
				return new Message(true, data);
			} catch {
				return new Message(true);
			}
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	async fetchWards(requestParams) {
		let url = `wards/`;
		// allwards causes failing
		// if (requestParams !== undefined && requestParams.hasOwnProperty('allWards') && requestParams.allWards === true) {
		// 	url = `wards/allwards`;
		// }
		const res = await apiClient.get(url, requestParams);


		if (res.statusText === "Unauthorized") {
			return new Message(false, res.statusText);
		} else {
			const data = await res.json();
			const wards = [];
			for (let idx in data) {
				const ward = new Ward(
					data[idx].id,
					data[idx].name,
					data[idx].size
				);
				wards.push(ward);
			}
			return new FetchWardsMessage(true, res.statusText, wards);
		}
	}

	async fetchAllWards(requestParams) {
		let url = `wards/allwards`;
		const res = await apiClient.get(url, Object.assign({}, requestParams, { allwards: true }));

		if (res.statusText === "Unauthorized") {
			return new Message(false, res.statusText);
		} else {
			const data = await res.json();
			const wards = [];
			for (let idx in data) {
				const ward = new Ward(
					data[idx].id,
					data[idx].name,
					data[idx].size
				);
				wards.push(ward);
			}
			return new FetchWardsMessage(true, res.statusText, wards);
		}
	}

	// Block a bed
	async blockBed(bed, startdate, enddate) {
		try {
			const res = await apiClient.put(`beds/${bed.id}/block/`, {
				json: {
					start: startdate,
					end: enddate,
				},
			});
			try {
				const data = await res.json();
				return new Message(true, data);
			} catch {
				return new Message(true);
			}
		} catch (error) {
			return new Message(false, error);
		}
	}

	// Remove an assignment completely
	async deleteAssignment(assignmentId) {
		try {
			const res = await apiClient.delete(`assignments/${assignmentId}/`);
			return new Message(true, res.statusText);
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	async createNewProposedPlan(patient_ids) {
		try {
			const res = await apiClient.post(
				`proposedplans/compute/`,
				{
					json: {
						patient_ids: patient_ids,
					}
				}
			);
			try {
				const data = await res.json();
				return new ProposedPlanMessage(true, "", data.proposed_plan_id);
			} catch {
				return new ProposedPlanMessage(true);
			}
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new ProposedPlanMessage(false, errorJson);
			}
			return new ProposedPlanMessage(false, error);
		}
	}

	async acceptProposedPlan(proposed_plan_id) {
		try {
			const res = await apiClient.delete(
				`proposedplans/${proposed_plan_id}/accept`
			);
			return new Message(true, res.statusText);
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	async rejectProposedPlan(proposed_plan_id) {
		try {
			const res = await apiClient.delete(
				`proposedplans/${proposed_plan_id}/reject`
			);
			return new Message(true, res.statusText);
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	async fetchProposalAssignments(proposed_plan_id) {
		try {
			const res = await apiClient.get(
				`proposedplans/${proposed_plan_id}/assignments`
			);
			const data = await res.json();
			return {
				assignments_of_rooms: data.assignments_of_rooms,
				assignments_without_bed: data.assignments_without_bed,
				start_date: data.start_date,
				end_date: data.end_date,
				patients_to_assign: data.patients_to_assign
			};
		} catch (error) {
			if (error.name === "HTTPError") {
				const errorJson = await error.response.json();
				return new Message(false, errorJson);
			}
			return new Message(false, error);
		}
	}

	getAssignmentArray(data) {
		const dataArray = [];
		for (let idx in data) {
			const dataItem = data[idx];
			const dataPatient = dataItem.patient;
			var patient = null;

			if (dataPatient !== null) {
				patient = new Patient(
					dataPatient.lastname,
					dataPatient.firstname,
					dataPatient.isolation_needed,
					dataPatient.bed_place_isolation_needed,
					dataPatient.private_insurance,
					dataPatient.single_room_needed,
					dataPatient.birth_date,
					dataPatient.gender,
					dataPatient.disturbs,
					dataPatient.monitor_bed_needed,
					dataPatient.quick_access_needed,
					dataPatient.double_room_needed,
					dataPatient.id,
					dataPatient.home_ward
				);
			}

			dataArray.push(
				new Assignment(
					dataItem.assignment_status,
					dataItem.bed,
					dataItem.start,
					dataItem.end,
					dataItem.id,
					dataItem.proposed,
					patient,
					dataItem.request
				)
			);
		}
		return dataArray
	}
}

export default APICenter;
