import Cookies from 'js-cookie'
import apiClient from './APIClient'
import { AuthMessage } from './Message'

// The specialized API Call center related to authentication
class AuthCenter {
  constructor() {
    if (Cookies.get('username')) {
      this.isLoginIn = true
      this.username = decodeURI(String(Cookies.get('username')))
      this.accessToken = decodeURI(String(Cookies.get('accessToken')))
      this.refreshToken = decodeURI(String(Cookies.get('refreshToken')))
    } else {
      this.isLoginIn = false
      this.username = undefined
      this.accessToken = undefined
      this.refreshToken = undefined
    }
  }

  get loginState() {
    return this.isLoginIn
  }

  get currentAccessToken() {
    return this.accessToken
  }

  get currentRefreshToken() {
    return this.refreshToken
  }

  get currentUsername() {
    return this.username
  }

  // login with the usr and psd
  async login(usr, psd) {
    if (!this.isLoginIn) {
      // Construct Login Request
      const response = await apiClient.post('users/login/', {
        json: {
          username: usr,
          password: psd,
        },
      })

      // Wait for the result
      const data = await response.json()
      if (response.status === 200) {
        // Successful Login
        this.isLoginIn = true
        this.username = data.username
        this.accessToken = data.access_token
        this.refreshToken = data.refresh_token

        Cookies.set('username', encodeURI(data.user.username))
        Cookies.set('accessToken', encodeURI(data.access_token))
        Cookies.set('refreshToken', encodeURI(data.refresh_token))

        return new AuthMessage(
          true,
          response.statusText,
          data.user.username,
          data.access_token,
          data.refresh_token
        )
      } else {
        // Login failed
        return new AuthMessage(false, response.statusText)
      }
    } else {
      return new AuthMessage(false, 'Sie sind schon angemeldet!')
    }
  }

  // logout, remove the token and username from local storage
  logout() {
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')
    Cookies.remove('username')

    this.isLoginIn = false
    this.username = undefined
    this.accessToken = undefined
    this.refreshToken = undefined
  }
}

export default AuthCenter
