import {useContext, useEffect, useState} from "react";
import emitter from "../util/event";

//import Chakra UI components
import {
    IconButton,
    Heading,
    VStack,
} from "@chakra-ui/react";

import "../index.css";
import APIUtils from "../apis/APIUtils";
import AddBaseDataRowComponent from "./AddBaseDataRowComponent";
import {AddIcon} from "../theme/icons";
import AddBaseDataComponent from "./AddBaseDataComponent";


const AddBaseDataTableComponent = () => {

    const [wards, setWards] = useState([]);
    const {getData, getSuggestions} = APIUtils();

    const getWards = async () => {
        const responseFetchWards = await getData("wards");
        if (responseFetchWards.isSuccessful) {
            setWards(responseFetchWards.data);
        }
    };

    useEffect(() => {
        getWards();
        emitter.on("update_settings_view", getWards);

        return () => {
            emitter.removeListener("update_settings_view", getWards);
        };

    }, []);


    const showWardAddForm = () => {
        emitter.emit("showAddWard", true);
    }

    return (
        <>
            {Object.keys(wards)
                .map(function (warditem) {
                    return wards[warditem];
                }).flat()
                .map(function (warditem) {
                    const wardProp = {
                        ward_id: warditem.id,
                        ward_name: warditem.name,
                    };
                    return (
                        <AddBaseDataRowComponent
                            key={`${warditem.id}`}
                            {...wardProp}/>
                    )
                })}
            <VStack align={"left"}>
                <Heading as='h4' textAlign='left' size={"24px"} color={'#0F4046'} fontWeight={400} lineHeight={"32px"}
                         paddingTop={"36px"}>
                    Neue Station anlegen
                </Heading>
                <IconButton
                    icon={<AddIcon/>}
                    onClick={showWardAddForm}
                    bg={"white"}/>
            </VStack>
            <AddBaseDataComponent //set showWardAdd to true to make sure ward add form can only be called here, not other places with this component
                showWardAdd={true}/>
        </>
    );

}

export default AddBaseDataTableComponent;
