import React from "react";
import { Room, RoomsStatus, Ward } from "../../calendar-types";
import { RoomItem } from "./roomItem";
import { WardItem } from "./wardItem";

interface Props {
	rooms: Room[];
	status: RoomsStatus;
	onChangeStatus: (status: RoomsStatus) => void;
	wards: Ward[];
	wardsStatus: RoomsStatus;
}

function Wards({ rooms, status, onChangeStatus, wards, wardsStatus }: Props) {
	const aktionsbereich: Room[] =
		rooms.filter((room) => {
			return ["Zu_belegende_Patienten", "Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id);
		});


	const sortedWards = wards.sort(
		(a, b) => parseInt(a.wardId) - parseInt(b.wardId)
	);

	return (
		<div className={"h-max border-gray-200 w-[200px] border-r"}>
			<div className={"w-60"}>
				{aktionsbereich.map((area) => (
					<div className="mb-2">

						<RoomItem
							key={area.id}
							status={status[area.id]}
							room={area}
							onClick={() => {
								const statusPerRoom = status[area.id];
								const expanded =
									statusPerRoom?.expanded ?? false;
								onChangeStatus({
									...status,
									[area.id]: {
										...statusPerRoom,
										expanded: !expanded,
									},
								});
							}}
						/>

					</div>
				))}
				{sortedWards.map((ward) => (
					<WardItem
						wardStatus={wardsStatus[ward.id]}
						ward={ward}
						key={ward.id}
						onChangeStatus={onChangeStatus}
						status={status}
					/>
				))}
			</div>
		</div>
	);
}

export { Wards };
