import {
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Spinner,
    Stack,
    Text,
    VStack,
} from "@chakra-ui/react";
import dayjs, {type Dayjs} from "dayjs";
import "dayjs/locale/de";
import Cookies from "js-cookie";
import React, {FC, useEffect, useState} from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {Formatter as LegendFormatter} from "recharts/types/component/DefaultLegendContent";
import {Formatter as LabelFormatter} from "recharts/types/component/DefaultTooltipContent";
import {useDebounce} from "use-debounce";
import APIUtils from "../apis/APIUtils";
import {WardResponse} from "../apis/Message";
import {useWards} from "../context/WardsContext";
import {default as StatsRange} from "../models/StatsRange";
import SwitchWardPanelComponent from "./SwitchWardPanelComponent";
import emitter from "../util/event";
import {Simulate} from "react-dom/test-utils";
import abort = Simulate.abort;

type Granularity = "day" | "month";

type StatsRangeWards = {
    stats: StatsRange[];
    wards: WardResponse[];
};

function getDefaultStartDate(granularity: Granularity) {
    if (granularity === "day")
        return dayjs().startOf("day").subtract(14, "days");
    else return dayjs().startOf("month").subtract(3, "months");
}

function getDefaultEndDate(granularity: Granularity) {
    if (granularity === "day") return dayjs().startOf("day").add(7, "days");
    else return dayjs().endOf("month");
}

const StatisticsGraphComponent = () => {
    const {getData} = APIUtils();
    const {wards, isLoading: wardsLoading} = useWards();

    const [statsRange, setStats] = useState<StatsRangeWards | null>(null);
    const [startDate, setStartDate] = useState<Dayjs | null>(
        getDefaultStartDate("day")
    );
    const [endDate, setEndDate] = useState<Dayjs | null>(
        getDefaultEndDate("day")
    );
    const [granularity, setGranularity] = useState<Granularity>("day");
    const [selectedWards, setSelectedWards] = useState<number[]>([]);

    const [selWardsDebounced] = useDebounce(selectedWards, 500);
    const [startDateDebounced] = useDebounce(startDate, 500);
    const [endDateDebounced] = useDebounce(endDate, 500);
    const [granularityDebounced] = useDebounce(granularity, 500);

    const fetchStats = async (abortController: AbortController) => {
        if (wardsLoading) return;
        if (startDateDebounced === null || endDateDebounced === null) return;

        // To show loading state
        setStats(null);

        const params = {
            start_date: startDateDebounced.format("YYYY-MM-DD"),
            end_date: endDateDebounced.format("YYYY-MM-DD"),
            granularity: granularityDebounced,
            wards: wards
                .filter((w) => selWardsDebounced.includes(w.id))
                .map((w) => w.id)
                .join(","),
        };

        const response: any = await getData("stats_range", params, {
            signal: abortController.signal,
        });
        if (response.isSuccessful) {
            const _stats = response.data as StatsRangeWards;
            setStats(_stats);
        } else {
            console.log("stats response error")
            setStats({wards: [], stats: []});
        }
    };

    useEffect(() => {
        if (!wardsLoading) setSelectedWards(wards.map((w) => w.id));
    }, [wardsLoading, wards]);

    useEffect(() => {
        // When granularity changes, also set default time frame
        setStartDate(getDefaultStartDate(granularity));
        setEndDate(getDefaultEndDate(granularity));
    }, [granularity]);

    // Use debounced values in order to lower the load on backend
    useEffect(() => {
        const abortController = new AbortController();

        fetchStats(abortController);

        return () => {
            abortController.abort();
        };
    }, [
        startDateDebounced,
        endDateDebounced,
        granularityDebounced,
        selWardsDebounced,
    ]);


    //used for purpose of adding event for realtime update
    useEffect(() => {
        const abortController = new AbortController();
        emitter.on("update_stats_view", () => fetchStats(abortController)
        );

        return () => {
            emitter.removeListener("update_stats_view", fetchStats);
            abortController.abort()
        };
    }, [])

    return (
        <Box w="100%">
            <VStack spacing={50}>
                <Stack
                    direction={["column", "row"]}
                    align={"center"}
                    w={["100%", "fit-content"]}
                >
                    <FormControl>
                        <FormLabel>Von</FormLabel>
                        <Input
                            name="start"
                            type="date"
                            placeholder=""
                            isInvalid={startDate === null}
                            value={startDate?.format("YYYY-MM-DD")}
                            onChange={(e) => {
                                setStartDate(
                                    e.target.valueAsDate === null
                                        ? null
                                        : dayjs(e.target.valueAsDate)
                                );
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Bis</FormLabel>
                        <Input
                            name="end"
                            type="date"
                            placeholder=""
                            isInvalid={endDate === null}
                            value={endDate?.format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setEndDate(
                                    e.target.valueAsDate === null
                                        ? null
                                        : dayjs(e.target.valueAsDate)
                                )
                            }
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Stationen</FormLabel>
                        <SwitchWardPanelComponent
                            wards={wards}
                            wardsSelected={selectedWards}
                            setWardsSelected={setSelectedWards}
                            username={Cookies.get("username")}
                        />
                    </FormControl>
                </Stack>
                <VStack w="100%">
                    <StatsLineGraph
                        granularity={granularity}
                        statsRange={statsRange}
                    />
                    <StatsBarGraph
                        granularity={granularity}
                        statsRange={statsRange}
                    />
                </VStack>
            </VStack>
        </Box>
    );
};

type StatsLineGraphProps = {
    granularity: Granularity;
    statsRange: StatsRangeWards | null;
};

const StatsLineGraph: FC<StatsLineGraphProps> = ({
                                                     granularity,
                                                     statsRange,
                                                 }) => {
    const {wardsByID} = useWards();
    const [showOccCurrent, setShowOccCurrent] = useState(true);
    const [showOccReserved, setShowOccReserved] = useState(true);

    const xAxisFormatter = (value: any) => {
        if (granularity === "day")
            return dayjs(value as string).format("YYYY-MM-DD");
        else return dayjs(value as string).format("MMMM YYYY");
    };

    const tooltipLabelFormatter = (label: any) => {
        if (granularity === "day")
            return dayjs(label as string).format("YYYY-MM-DD");
        else return dayjs(label as string).format("MMMM YYYY");
    };

    const tooltipValuesFormatter: LabelFormatter<string, string> = (
        value,
        name
    ) => {
        value = value + "%";

        if (name.startsWith("current_occupancy_percent")) {
            const wardId = parseInt(
                name.replace("current_occupancy_percent.", "")
            );
            name = "Aktuell,  " + wardsByID.get(wardId)?.name;
        }
        if (name.startsWith("planned_occupancy_percent")) {
            const wardId = parseInt(
                name.replace("planned_occupancy_percent.", "")
            );
            name = "Aktuell + Reserviert,  " + wardsByID.get(wardId)?.name;
        }

        return [value, name];
    };

    const legendFormatter: LegendFormatter = (value: string, entry, index) => {
        let name;

        if (value.startsWith("planned_occupancy_percent.")) {
            const wardId = parseInt(
                value.replace("planned_occupancy_percent.", "")
            );
            name = "Reserviert, " + wardsByID.get(wardId)?.name;
        }

        if (value.startsWith("current_occupancy_percent")) {
            const wardId = parseInt(
                value.replace("current_occupancy_percent.", "")
            );
            name = "Fix, " + wardsByID.get(wardId)?.name;
        }

        return <span>{name}</span>;
    };

    return (
        <Stack direction={["row", "column"]} align={"center"} width={"100%"}>
            <Flex direction={["row"]} wrap={"wrap"}>
                <Checkbox
                    p={2}
                    minWidth={"fit-content"}
                    color={"#37b7c7"}
                    isChecked={showOccCurrent}
                    onChange={() => setShowOccCurrent((checked) => !checked)}
                >
                    <Text whiteSpace={"nowrap"}>Aktuelle Auslastung</Text>
                </Checkbox>
                <Checkbox
                    p={2}
                    color={"#A6F2FC"}
                    isChecked={showOccReserved}
                    onChange={() => setShowOccReserved((checked) => !checked)}
                >
                    <Text whiteSpace={"nowrap"}>
                        Aktuelle + Reservierte Auslastung
                    </Text>
                </Checkbox>
            </Flex>
            {statsRange === null ? (
                <Spinner/>
            ) : (
                <ResponsiveContainer width="100%" height={350}>
                    <LineChart data={statsRange.stats}>
                        {statsRange.wards.map((ward) => (
                            <Line
                                type="monotone"
                                dataKey={`planned_occupancy_percent.${ward.id}`}
                                stroke="#A6F2FC"
                                strokeWidth={2}
                                hide={!showOccReserved}
                            />
                        ))}
                        {statsRange.wards.map((ward) => (
                            <Line
                                type="monotone"
                                dataKey={`current_occupancy_percent.${ward.id}`}
                                stroke="#37b7c7"
                                strokeWidth={2}
                                hide={!showOccCurrent}
                            />
                        ))}
                        <CartesianGrid stroke="#cccccc"/>
                        <YAxis
                            type="number"
                            tickFormatter={(value) => value + "%"}
                            domain={[
                                0,
                                (dataMax: number) => Math.max(100, dataMax),
                            ]}
                        />
                        <XAxis
                            dataKey="start_date"
                            minTickGap={20}
                            tickFormatter={xAxisFormatter}
                        />
                        <Tooltip
                            labelFormatter={tooltipLabelFormatter}
                            formatter={tooltipValuesFormatter}
                        />
                        <Legend
                            verticalAlign="top"
                            height={60}
                            formatter={legendFormatter}
                        />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </Stack>
    );
};

type StatsBarGraphProps = {
    granularity: Granularity;
    statsRange: StatsRangeWards | null;
};

const StatsBarGraph: FC<StatsBarGraphProps> = ({granularity, statsRange}) => {
    const {wardsByID} = useWards();
    const [showOccCurrent, setShowOccCurrent] = useState(true);
    const [showOccReserved, setShowOccReserved] = useState(true);
    const [showUnassigned, setShowUnassigned] = useState(true);

    const xAxisFormatter = (value: any) => {
        if (granularity === "day")
            return dayjs(value as string).format("YYYY-MM-DD");
        else return dayjs(value as string).format("MMMM YYYY");
    };

    const tooltipLabelFormatter = (label: any) => {
        if (granularity === "day")
            return dayjs(label as string).format("YYYY-MM-DD");
        else return dayjs(label as string).format("MMMM YYYY");
    };

    const tooltipValuesFormatter: LabelFormatter<string, string> = (
        value,
        name,
        props
    ) => {
        if (name.startsWith("current_occupancy_total")) {
            const wardId = parseInt(
                name.replace("current_occupancy_total.", "")
            );
            name = "Fix, " + wardsByID.get(wardId)?.name;
        }

        if (name.startsWith("reserved_occupancy_total")) {
            const wardId = parseInt(
                name.replace("reserved_occupancy_total.", "")
            );
            name = "Reserviert, " + wardsByID.get(wardId)?.name;
        }

        if (name.startsWith("num_waiting_patients")) {
            const wardId = parseInt(name.replace("num_waiting_patients.", ""));
            name = "Nicht belegt, " + wardsByID.get(wardId)?.name;
        }
        return [value, name];
    };

    const legendFormatter: LegendFormatter = (value: string, entry, index) => {
        let name;
        if (value.startsWith("current_occupancy_total")) {
            const wardId = parseInt(
                value.replace("current_occupancy_total.", "")
            );
            name = "Fix, " + wardsByID.get(wardId)?.name;
        }

        if (value.startsWith("reserved_occupancy_total")) {
            const wardId = parseInt(
                value.replace("reserved_occupancy_total.", "")
            );
            name = "Reserviert, " + wardsByID.get(wardId)?.name;
        }

        if (value.startsWith("num_waiting_patients")) {
            const wardId = parseInt(value.replace("num_waiting_patients.", ""));
            name = "Nicht belegt, " + wardsByID.get(wardId)?.name;
        }

        return <span>{name}</span>;
    };

    return (
        <>
            <br/>
            <br/>
            <Stack
                direction={["row", "column"]}
                align={"center"}
                width={"100%"}
            >
                <Flex direction={["row"]} wrap={"wrap"}>
                    <Checkbox
                        p={2}
                        minWidth={"fit-content"}
                        color={"#37b7c7"}
                        isChecked={showOccCurrent}
                        onChange={() =>
                            setShowOccCurrent((checked) => !checked)
                        }
                    >
                        <Text whiteSpace={"nowrap"}>Fix</Text>
                    </Checkbox>
                    <Checkbox
                        p={2}
                        color={"#A6F2FC"}
                        isChecked={showOccReserved}
                        onChange={() =>
                            setShowOccReserved((checked) => !checked)
                        }
                    >
                        <Text whiteSpace={"nowrap"}>Reserviert</Text>
                    </Checkbox>
                    <Checkbox
                        p={2}
                        color={"#0F4046"}
                        isChecked={showUnassigned}
                        onChange={() =>
                            setShowUnassigned((checked) => !checked)
                        }
                    >
                        <Text whiteSpace={"nowrap"}>Nicht belegt</Text>
                    </Checkbox>
                </Flex>
                {statsRange === null ? (
                    <Spinner/>
                ) : (
                    // TODO give data to bar manually
                    <ResponsiveContainer width="100%" height={350}>
                        <BarChart data={statsRange.stats}>
                            {statsRange.wards.map((ward) => (
                                <Bar
                                    dataKey={`current_occupancy_total.${ward.id}`}
                                    key={`current_occupancy_total.${ward.id}`}
                                    stackId={ward.id}
                                    fill="#37b7c7"
                                    hide={!showOccCurrent}
                                />
                            ))}
                            {statsRange.wards.map((ward) => (
                                <Bar
                                    key={`reserved_occupancy_total.${ward.id}`}
                                    stackId={ward.id}
                                    dataKey={`reserved_occupancy_total.${ward.id}`}
                                    fill="#A6F2FC"
                                    hide={!showOccReserved}
                                />
                            ))}
                            {statsRange.wards.map((ward) => (
                                <Bar
                                    key={`num_waiting_patients.${ward.id}`}
                                    stackId={ward.id}
                                    dataKey={`num_waiting_patients.${ward.id}`}
                                    fill="#0F4046"
                                    hide={!showUnassigned}
                                />
                            ))}
                            <CartesianGrid stroke="#cccccc"/>
                            <YAxis
                                type="number"
                                domain={[
                                    0,
                                    (dataMax: number) => Math.max(5, dataMax),
                                ]}
                            />
                            <XAxis
                                dataKey="start_date"
                                minTickGap={20}
                                tickFormatter={xAxisFormatter}
                            />
                            <Tooltip
                                cursor={{fill: "rgba(100, 100, 100, 0.3)"}}
                                labelFormatter={tooltipLabelFormatter}
                                formatter={tooltipValuesFormatter}
                            />
                            <Legend
                                verticalAlign="top"
                                height={60}
                                formatter={legendFormatter}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                )}
            </Stack>
        </>
    );
};

export default StatisticsGraphComponent;
