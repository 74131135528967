import dayjs from "dayjs";
import Patient from '../../models/Patient'
import { IdentificationIcon } from "@heroicons/react/24/outline";
import { Assignment } from "../../models/Assignment";

export function getRooms(assignments: any, selectedWards: any, scopeWards: Ward[]) {
  const rooms = [];
  if (scopeWards === undefined) {
    return rooms;
  }
  if (assignments?.assignmentsOfRooms && assignments?.assignmentsWithoutBed) {
    for (const room of assignments.assignmentsOfRooms) {
      // extract from this wired room-object all the home_ward ids to find external patient
      let homeWard_ids = []
      for (const ass_of_room of room.assignments_of_room) {
        if (ass_of_room.occupied) {
          for (const ass of ass_of_room.assignments_of_bed) {
            if (ass.patient) {
              homeWard_ids.push(ass.patient.home_ward)
            }
          }
        }
      }
      // find if two integer sets share same numbers: @TODO find a method online
      let containsExternalPatient = false;
      if (homeWard_ids !== []) {
        for (const oneScopeWard of scopeWards) {
          containsExternalPatient = homeWard_ids.includes(oneScopeWard)
          if (containsExternalPatient) {
            break;
          }
        }
      }

      // selectedWards.includes(room.ward.id) to show no wards if no one is selected
      if (scopeWards.includes(room.ward.id) || containsExternalPatient) {
        rooms.push({
          wardId: room.ward.id.toString(),
          id: room.room.id.toString(),
          label: room.room.name,
          beds: room.assignments_of_room.map((r: any) => {
            return { id: r.bed.id.toString(), label: "Bett " + r.bed.name };
          }),
        });
      }
    }

    const objectJson = splitAktionsbereich(assignments.assignmentsWithoutBed, scopeWards, selectedWards);
    const zuBelegendePatienten = objectJson.zubelegendePatienten
    const ausgehendeAnfragen = objectJson.ausgehendeAnfragen
    const eingehendeAnfragen = objectJson.eingehendeAnfragen

    function pushInRooms(array, title, id_area) {
      rooms.push({
        wardId: "",
        id: id_area,
        label: title,
        beds: array.map((r) => {
          return {
            id: `${r.patient.firstname} ${r.patient.lastname} ${id_area}`,
            label: `${r.patient.firstname} ${r.patient.lastname}`,
          };
        }),
      });
    }
    // These here are the IDs for 
    // "Zu belegende Patienten" "Ausgehende Anfragen" "Eingehende Anfragen"
    // "Zu_belegende_Patienten" "Ausgehende_Anfragen" "Eingehende_Anfragen"
    pushInRooms(zuBelegendePatienten, "Zu belegende Patienten", "Zu_belegende_Patienten");
    pushInRooms(ausgehendeAnfragen, "Ausgehende Anfragen", "Ausgehende_Anfragen");
    pushInRooms(eingehendeAnfragen, "Eingehende Anfragen", "Eingehende_Anfragen");

  }
  return rooms;
}

/**
 * Contains the if-statments for filtering to the aktionsbereich
 * @param assigment 
 * @param scopeWards selected wards
 * @return a string that say in which area the assigment should be assigned
 */
function indentifyAreaInAktionsbereich(assignment, scopeWards) {
  // we use an array, because as master user, the patient can be assigned to many wards
  let areas = [];
  // console.trace()
  console.log("scopes", scopeWards)
  // console.log("assignement", assignment.patient.lastname, assignment)
  if ((scopeWards.includes(assignment.patient.home_ward)
    && assignment.request === null)
    ||
    (scopeWards.includes(assignment.request?.request_receiver_ward)
      && assignment?.request.state === 'A')
  ) {
    areas.push("Zu_belegende_Patienten")
  }
  if (assignment.request !== null
    && scopeWards.includes(assignment.request.request_sender_ward)
  ) {
    areas.push("Ausgehende_Anfragen")

  }
  if ((scopeWards.includes(assignment.request?.request_receiver_ward)
    && assignment.request?.state === 'P')) {
    areas.push("Eingehende_Anfragen")

  }
  if (areas.length === 0) {
    console.log("🚨Could not assign assigment to \"Zu belegende Patienten\", \"Ausgehende Anfragen\", nor \"Eingehende Anfrage\"", assignment)
  }
  return areas;
}

/**
 * gets all assigments without a bed an returns 
 * three arrays containing the filtered aktionsbereich
 * 
 * @param assignmentsWithoutBed back end json
 * @param scopeWards all wards the user has acces to 
 * @returns zuBelegendePatienten ausgehendeAnfragen eingehendeAnfragen
 */
function splitAktionsbereich(assignmentsWithoutBed: any, scopeWards: Ward[], selectedWards: Ward[]) {
  const zubelegendePat = [];
  const ausgehendeAnfr = [];
  const eingehendeAnfr = [];
  for (const assignment of assignmentsWithoutBed) {
    const assignmentEnd = dayjs(assignment.end);
    // only show patient that are in our scope and in selected wards
    if (!(assignmentEnd.toDate() < dayjs().toDate())) {
      let areas = indentifyAreaInAktionsbereich(assignment, selectedWards)
      for (const area of areas) {
        if (area === "Zu_belegende_Patienten") {
          zubelegendePat.push(assignment);
        }
        else if (area === "Ausgehende_Anfragen") {
          ausgehendeAnfr.push(assignment)
        } else if (area === "Eingehende_Anfragen") {
          eingehendeAnfr.push(assignment)
        }
      }
    }
  }
  // console.log("ausghende anfragen", ausgehendeAnfr)
  return { zubelegendePatienten: zubelegendePat, ausgehendeAnfragen: ausgehendeAnfr, eingehendeAnfragen: eingehendeAnfr }
}

export function getEvents(assignments: any, selectedWards: any, scopeWards: Ward[]) {
  const ev = [];
  if (scopeWards === undefined) {
    return ev;
  }

  if (assignments?.assignmentsOfRooms && assignments?.assignmentsWithoutBed) {
    for (const room of assignments.assignmentsOfRooms) {
      // if (thereAreWardsSelected && selectedWards.includes(room.ward.id)) {
      for (const bed of room.assignments_of_room) {
        for (const bedAssignment of bed.assignments_of_bed) {
          const assignmentStart = dayjs(bedAssignment.start);
          const assignmentEnd = dayjs(bedAssignment.end);
          let patient = new Patient();

          if (bedAssignment.patient !== null) {
            patient = bedAssignment.patient;
          }
          ev.push({
            id: "Bett " + bed.bed.name + "-" + bedAssignment.id,
            assignmentId: bedAssignment.id,
            assignment: null,
            isDisabled: assignmentEnd.toDate() < dayjs().toDate() || patient.id == -1,
            color: "bg-[#37B7C7]",
            from: assignmentStart.toDate(),
            to: assignmentEnd.toDate(),
            bedId: bedAssignment.bed.id.toString(),
            nextBedName: getNextBedName(
              assignmentEnd.toDate(),
              patient.id,
              assignments
            ),
            wardId: room.ward.id.toString(),
            roomId: room.room.id.toString(),
            assignment_status: bedAssignment.assignment_status,
            assignment_proposed: bedAssignment.proposed,
            patient: {
              home_ward: patient.home_ward.toString(),
              isInHomeWard: scopeWards.includes(patient.home_ward),
              statusColor: "bg-red-400",
              avatarUrl: "",
              name: patient.firstname + " " + patient.lastname,
              id: patient.id.toString(),
              gender: patient.gender,
              bed_place_isolation_needed: patient.bed_place_isolation_needed,
              isolation_needed: patient.isolation_needed,
              disturbs: patient.disturbs,
              private_insurance: patient.private_insurance,
            },
          });
        }
      }
      // }
    }
    for (const assignment of assignments.assignmentsWithoutBed) {
      const assignmentStart = dayjs(assignment.start);
      const assignmentEnd = dayjs(assignment.end);
      if (!(assignmentEnd.toDate() < dayjs().toDate())) {
        const patient = assignment.patient;

        // aktionsbereich has now 3 areas...
        for (const area of indentifyAreaInAktionsbereich(assignment, selectedWards)) {
          ev.push({
            id: `${patient.firstname} ${patient.lastname}`,
            assignmentId: assignment.id,
            assignment: assignment,
            isDisabled: assignmentEnd.toDate() < dayjs().toDate(),
            color: "bg-[#63DAE9]",
            from: assignmentStart.toDate(),
            to: assignmentEnd.toDate(),
            bedId: `${patient.firstname} ${patient.lastname} ${area}`,
            nextBedName: null,
            roomId: area,
            wardId: "no",
            assignment_proposed: assignment.proposed,
            patient: {
              home_ward: patient.home_ward.toString(),
              isInHomeWard: scopeWards.includes(patient.home_ward),
              statusColor: "bg-red-400",
              avatarUrl: "",
              name: `${patient.firstname} ${patient.lastname}`,
              id: patient.id.toString(),
              gender: patient.gender,
              bed_place_isolation_needed: patient.bed_place_isolation_needed,
              isolation_needed: patient.isolation_needed,
              disturbs: patient.disturbs,
              private_insurance: patient.private_insurance,
            },
          });

        }
      }
    }
  }
  return ev;
}

function getNextBedName(assignmentEnd: any, patientId: any, assignments: any) {
  if (assignments.assignmentsOfRooms && assignments.assignmentsWithoutBed) {
    for (const room of assignments.assignmentsOfRooms) {
      for (const bed of room.assignments_of_room) {
        for (const bedAssignment of bed.assignments_of_bed) {
          if (
            bedAssignment.patient &&
            bedAssignment.patient.id === patientId &&
            dayjs(dayjs(bedAssignment.start).toDate()).diff(
              dayjs(assignmentEnd),
              "hour"
            ) < 2 &&
            dayjs(dayjs(bedAssignment.start).toDate()).diff(
              dayjs(assignmentEnd),
              "hour"
            ) > -1
          ) {
            return "Bett " + bed.bed.name;
          }
        }
      }
    }
  }
  return null;
}

export function getWards(assignments: any, selectedWards: any, allWards: any, scopeWards: Ward[]) {
  const wards = [];
  // console.log("selected wards:", selectedWards, "allWards", allWards);
  if (selectedWards) {
    const rooms = getRooms(assignments, selectedWards, scopeWards);
    for (const ward of selectedWards) {
      const roomsForWard = rooms.filter((room) => {
        return room.wardId === ward.toString();
      });
      if (roomsForWard.length > 0) {
        wards.push({
          id: "ward-" + ward.toString(),
          wardId: ward.toString(),
          label: allWards.filter((w: any) => {
            return w.id === ward;
          })[0].name,
          rooms: roomsForWard,
        });
      }
    }
  }
  return wards;
}


export function getNewestId(assignments: any) {
  let assignmentId = -1;
  if (assignments?.assignmentsOfRooms && assignments?.assignmentsWithoutBed) {
    for (const room of assignments.assignmentsOfRooms) {
      for (const bed of room.assignments_of_room) {
        for (const bedAssignment of bed.assignments_of_bed) {
          if (bedAssignment.id > assignmentId) {
            assignmentId = bedAssignment.id;
          }
        }
      }
    }
    for (const bedAssignment of assignments.assignmentsWithoutBed) {
      if (bedAssignment.id > assignmentId) {
        assignmentId = bedAssignment.id;
      }
    }
  }
  return assignmentId;
}
