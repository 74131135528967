import { ModalHeader } from "@chakra-ui/react"
import React from "react"

type DialogHeaderProps = {
  children: React.ReactNode
}

const DialogHeader = (props: DialogHeaderProps) => (
  <ModalHeader color="#0F4046" textAlign="center" fontSize="3xl" fontWeight="bold" mt="100">
    {props.children}
  </ModalHeader>
)

export default DialogHeader
