import dayjs from "dayjs";
import Weekday from "dayjs/plugin/weekday";
import WeekOfYear from "dayjs/plugin/weekOfYear";
import isLeapYear from "dayjs/plugin/isLeapYear";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import {
  addWeeks,
  subWeeks,
  differenceInDays,
  addDays,
  format,
  isAfter,
  isEqual,
  isBefore,
  startOfDay,
} from "date-fns";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Waypoint } from "react-waypoint";
import { canUseDOM } from "./can-use-dom";
import { DayEvents } from "./calendarComponents/tableCells/day-events";
import { Header } from "./calendarComponents/header";
import { Wards } from "./calendarComponents/leftSidebar/wards";
import { Room, Event, RoomsStatus, Mode, Ward } from "./calendar-types";
import { useIsomorphicLayoutEffect } from "./use-layout-effect";
import { de } from "date-fns/esm/locale";
import { FixNames } from "./calendarComponents/fixNamesSidebar/fixNames";
import * as types from "./calendar-types";
import Suggestion from "../../models/Suggestion";

dayjs.extend(WeekOfYear);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(Weekday);

interface Props {
  events: Event[];
  rooms: Room[];
  wards: Ward[];
  selectedDate: Date;
  scrollingLeft: boolean;
  scrollingRight: boolean;
  scrolling: boolean;
  fetchDate: Date;
  status: RoomsStatus;
  setStatus: React.Dispatch<React.SetStateAction<RoomsStatus>>;
  setFetchDate: React.Dispatch<React.SetStateAction<Date>>;
  onChange: (event: Event) => void;
  onChangeDates?: (date: Date, longRangeFrom: Date, longRangeTo: Date) => void;
  proposal: boolean;
  initialDates: [Date, Date];
  suggestion: Suggestion[];
  suggestionPatientId: number | null;
}

export type Dates = [Date, Date];

function Calendar({
  selectedDate,
  setFetchDate,
  events,
  rooms,
  wards,
  onChangeDates,
  onChange,
  fetchDate,
  scrollingLeft,
  scrollingRight,
  scrolling,
  status,
  setStatus,
  proposal,
  initialDates,
  suggestion,
  suggestionPatientId,
}: Props) {
  // console.log("events, rooms, wards", events, rooms, wards)



  const elRef = useRef<HTMLDivElement | null>(null);
  const scrollingPrevRef = useRef(false);
  const scrollingPrevScrollRef = useRef<number | undefined>();
  const [fixedNames, setFixedNames] = useState<types.Event[]>([]);
  const [prepared, setPrepared] = useState(false);
  const [date, setDate] = useState(selectedDate);
  const [dates, setDates] = useState<Dates>(initialDates);
  const el = elRef.current;
  const initialWardsStatus = toWardsStatus(wards);
  const [wardsStatus, setWardsStatus] =
    useState<RoomsStatus>(initialWardsStatus);
  const { onScroll } = useIsScrolling();
  const { mode, daysToShow, dayWidth } = useMode(elRef.current);
  const days = useMemo(() => getDays(dates[0], dates[1]), [dates]);
  const changeDayWhenScrolling = prepared;
  const [prevScrollPosition, setPrevScrollPosition] = useState(-1)

  const handleEnter = (a: Waypoint.CallbackArgs, i: number) => {
    const prev = () => {
      if (a.previousPosition && el && !proposal) {
        scrollingPrevRef.current = true;
        scrollingPrevScrollRef.current = el.scrollWidth - el.scrollLeft;
        setDates((dates) => [subWeeks(dates[0], 1), dates[1]]);
      }
    };
    const next = () => {
      if (a.currentPosition && a.previousPosition && !proposal) {
        setDates((dates) => [dates[0], addWeeks(dates[1], 1)]);
      }
    };

    if (i === days.length - 1) {
      next();
    } else if (i === 0) {
      prev();
    }
  };

  const handleChangeDate = (date: Date) => {
    if (changeDayWhenScrolling) {
      setDate(date);
      if (
        dayjs(date).diff(dayjs(fetchDate, "week")) > 2 ||
        dayjs(fetchDate).diff(dayjs(date, "week")) > 2
      ) {
        setFetchDate(date);
      }
    }
  };

  const handleChangeEvent = (event: Event) => {
    onChange(event);
  };

  useIsomorphicLayoutEffect(() => {
    if (
      scrollingPrevRef.current &&
      scrollingPrevScrollRef.current &&
      elRef.current
    ) {
      const el = elRef.current;
      elRef.current.scrollLeft =
        el.scrollWidth - scrollingPrevScrollRef.current;
      scrollingPrevRef.current = false;
    }
  }, [dates]);

  useIsomorphicLayoutEffect(() => {
    if (dayWidth && el && !prepared) {
      el.scrollLeft = getHorizontalScroll(dayWidth);
      setPrepared(true);
    }
  }, [dayWidth, el, prepared]);

  useEffect(() => {
    onChangeDates?.(date, dates[0], dates[1]);
  }, [date, dates, onChangeDates]);

  useEffect(() => {
    const el = document.getElementById(
      format(dayjs(selectedDate).subtract(3, "day").toDate(), "yyyy-MM-dd")
    );
    setTimeout(() => {
      el?.scrollIntoView({ inline: "start" });
      setDate(dayjs(selectedDate).weekday(0).toDate());
    }, 500);
  }, [scrolling]);

  useEffect(() => {
    if (elRef.current?.scrollLeft) {
      elRef.current.scrollLeft += 200;
    }
  }, [scrollingRight]);

  useEffect(() => {
    if (elRef.current?.scrollLeft) {
      elRef.current.scrollLeft -= 400;
      setTimeout(() => {
        if (elRef.current?.scrollLeft) {
          elRef.current.scrollLeft += 200;
        }
      }, 50);
    }
  }, [scrollingLeft]);

  useEffect(() => {
    setWardsStatus(toWardsStatus(wards));
  }, [wards]);

  function useIsScrolling() {
    const timeoutRef = useRef<number | undefined>();
    const [isScrolling, setIsScrolling] = useState(false);

    const handleScroll = () => {
      setIsScrolling(true);
      // Scroll to the left when the component is freshly instantiated and a proposal is shown
      if (proposal && el && prevScrollPosition == -1) {
        el.scrollTo({ left: 250, top: el.scrollTop })
      }
      // As the calendar contains an ugly empty div that needs to be there for internal reasons and becomes visible once we scroll 
      // to the left of 250 px, we force the scroll bar to always return to 250px if that happens
      if (proposal && el) {
        setPrevScrollPosition(el.scrollLeft)
      }
      if (proposal && el && el.scrollLeft < 250 && el.scrollLeft < prevScrollPosition) {
        el.scrollTo({ left: 250, top: el.scrollTop, behavior: "smooth" })
      }

      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    return { isScrolling, onScroll: handleScroll };
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <div className={proposal ? "h-[60vh] flex flex-col" : "h-[90vh] flex flex-col"}>

        <div
          className="flex border-t border-gray-200 relative overflow-auto h-full w-full"
          onScroll={onScroll}
          ref={elRef}
        >
          {/** WHITE OVERLAY TO HIDE FIRST DAY CONTAINING THE CURRENT MONTH & YEAR */}
          {/* <div className="2xl:-mt-2 flex fixed items-center w-[200px] z-30 h-12 bg-gray-200 border-r-2 border-white">
            {!!daysToShow && !!mode && (
              <Header daysToShow={daysToShow} mode={mode} date={date} />
            )}
          </div>

          {/** RENDERS CELLS AND PATIENTS */}
          <div
            className={`flex h-max w-max overflow-hidden absolute bottom-0 right-0 left-[450px] top-[48px]`}
          >
            {days.map((day, i) => (
              <DayEvents
                key={day.toString()}
                daysToShow={daysToShow}
                events={getEventsByDay(events, day)}
                mode={mode}
                rooms={rooms}
                wards={wards}
                wardsStatus={wardsStatus}
                date={day}
                dates={dates}
                width={dayWidth}
                status={status}
                onEnter={(args) => handleEnter(args, i)}
                onChangeDate={(date) => handleChangeDate(date)}
                onChangeEvent={handleChangeEvent}
                setFixedNames={setFixedNames}
                suggestion={suggestion}
                suggestionPatientId={suggestionPatientId}
              />
            ))}
          </div>
          <div className="flex sticky top-[48px] left-0 flex-col h-max min-w-[200px] overflow-hidden bg-slate-50">
            <Wards
              rooms={rooms}
              wards={wards}
              wardsStatus={wardsStatus}
              status={status}
              onChangeStatus={setStatus}
            />
          </div>
          <div className="flex sticky top-[48px] left-[200px] flex-col h-max min-w-[250px] overflow-hidden">
            <FixNames
              rooms={rooms}
              wards={wards}
              wardsStatus={wardsStatus}
              status={status}
              onChangeStatus={setStatus}
              fixedNames={fixedNames}
            />
          </div>
          <div className="flex sticky top-0 left-[450px] h-12 w-max bg-gray-200 border-b-[1px] border-white">
            {days.map((date) => (
              <div
                key={date.toISOString()}
                className="text-sm font-bold flex pl-3 py-4 items-center h-full border border-x-white min-w-[200px]"
              >
                {dayjs(date).format("DD MMM YY")}
              </div>
            ))}
          </div>
        </div>
      </div>
    </DndProvider >
  );
}

function getMode(width: number): Mode | undefined {
  if (canUseDOM()) {
    return width < 1000 ? "weekly" : "weekly";
  } else {
    return undefined;
  }
}

function getDaysToShow(mode: Mode, width: number) {
  const dailyDaysToShow = width <= 800 ? 1 : 3;
  return mode === "weekly" ? 7 : dailyDaysToShow;
}

function useMode(el: HTMLElement | null, cb?: () => void) {
  const [innerWidth, setInnerWidth] = useState<number>();
  const [mode, setMode] = useState<Mode>();
  const [daysToShow, setDaysToShow] = useState<number>();
  const dayWidth = 200;

  useEffect(() => {
    const handleSize = () => {
      const width = window.innerWidth;
      const mode = getMode(width);
      if (mode) {
        setMode(mode);
        setDaysToShow(getDaysToShow(mode, width));
      }
      setInnerWidth(width);
    };
    handleSize();
    window.addEventListener("resize", handleSize);
    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  return { mode, daysToShow, innerWidth, dayWidth };
}

function getEventsByDay(events: Event[], date: Date) {
  const d = startOfDay(date);
  return events.filter((event) => {
    const from = startOfDay(event.from);
    const to = startOfDay(event.to);
    return (
      (isAfter(d, from) || isEqual(d, from)) &&
      (isBefore(d, to) || isEqual(d, to))
    );
  });
}

function toWardsStatus(wards: Ward[]): RoomsStatus {
  return wards.reduce<RoomsStatus>((status, ward: Ward) => {
    return {
      ...status,
      [ward.id]: {
        expanded: true,
        beds: ward.rooms.reduce((status, room) => {
          return {
            ...status,
            [room.id]: { expanded: false },
          };
        }, {}),
      },
    };
  }, {});
}

function getHorizontalScroll(widthOfDay: number) {
  return 7 * widthOfDay;
}

function getDays(from: Date, to: Date) {
  const diff = Math.abs(differenceInDays(from, to));
  return Array.from({ length: diff }, (_, i) => addDays(from, i));
}

export { Calendar };
