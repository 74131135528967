import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Divider,
	Flex,
	HStack,
	Heading,
	Icon,
	IconButton,
	Spacer,
	Spinner,
	Stack,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import Weekday from "dayjs/plugin/weekday";
import { FetchWardsMessage } from "../../apis/Message";
import React, { useContext, useEffect, useState } from "react";
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";
import APIUtils from "../../apis/APIUtils";
import { getComment, getReasonFailed } from "../ApiHelpers";
import SwitchWardPanelComponent from "../SwitchWardPanelComponent";
import { subWeeks, addWeeks } from "date-fns";
import {
	getFutureHistoryFromCookie,
	getMovementHistoryFromCookie,
	handleRedoSuccess,
	handleUndoSuccess,
	redoKey,
	redoMove,
	resetFutureHistory,
	resetMovementHistory,
	setMovementHistory,
	undoKey,
	undoMove,
} from "../UndoRedo";
import { Calendar } from "../calendar/calendar";
import { Arrow } from "./Arrow";
import {
	getEvents,
	getNewestId,
	getRooms,
	getWards,
} from "./CalendarManagerHelpers";

import Cookies from "js-cookie";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import AuthContext from "../../context/AuthContext";
import { useWards } from "../../context/WardsContext";
import APICenter from "../../apis/APICenter";

dayjs.extend(Weekday);

const CalendarManager = ({
	startDay,
	fetchDate,
	setFetchDate,
	selectedDate,
	setSelectedDate,
	fetchNewAssignmentId,
	assignments,
	setFetchNewAssignmentId,
	setFetchCounter,
	fetchCounter,
	scrolling,
	setScrolling,
	username,
}) => {
	const [selectedWards, setSelectedWards] = useState([]);//just the ward ids
	const [reallyAllWards, setReallyAllWards] = useState([]);// all wards as object
	const [scopeWards, setScopeWards] = useState([]);// wards, the user has acces to
	// needed for showing transfer request
	const [suggestion, setSuggestion] = useState(null);
	const [suggestionPatientId, setSuggestionPatientId] = useState(null);

	const { wards: allWards, isLoading: wardsLoading } = useWards();

	const apiCenter = new APICenter();
	const { getData, getSuggestions } = APIUtils();


	useEffect(() => {
		const fetchTransferrequestData = async () => {
			const responseFetchSuggestions = getSuggestions();

			if (responseFetchSuggestions.isSuccessful) {
				setSuggestion(responseFetchSuggestions.suggestions ?? []);
				setSuggestionPatientId(responseFetchSuggestions.patientId ?? null);
			}
		};
		const fetchAllWards = async () => {
			const msg = await apiCenter.fetchAllWards();
			// const selected_msg = await getWards();
			const scope_msg = await getData("wards");
			// If the request succeeded with 200 status code
			if (msg.isSuccessful && scope_msg.isSuccessful) { // && selected_msg.isSuccessful) {
				// Set Ward
				// setWardState(msg.wards);
				// console.log("msg.wards", msg.wards)
				setSelectedWards(scope_msg.data.map((ward) => ward.id));
				setReallyAllWards(msg.wards);
				setScopeWards(scope_msg.data.map((ward) => ward.id));
				// console.log("allmywars:", msg.wards);
				// console.log("selected wards", selectedWards, "really all", reallyAllWards, "scopes", scopeWards)
			}
		}
		fetchAllWards();
		fetchTransferrequestData();

		// if (Cookies.get(`wardsSelected_${username}`)) {

		// 	console.log("cookie", JSON.parse(Cookies.get(`wardsSelected_${username}`)));
		// } else {
		// 	console.log("no cookie", allWards.map((ward) => ward.id));
		// }
	}, []);

	const handleLeftArrowClick = () => {
		setTimeout(() => {
			setScrollingLeft(!scrollingLeft);
		}, 1000);
	};

	const handleRightArrowClick = () => {
		setTimeout(() => {
			setScrollingRight(!scrollingRight);
		}, 1000);
	};

	// Fetch newest assignmentId for undo/redo for split assignments because BE doesn't provide it
	useEffect(() => {
		if (
			fetchNewAssignmentId &&
			assignments.assignmentsOfRooms &&
			assignments.assignmentsWithoutBed
		) {
			let movementHistory = getMovementHistoryFromCookie();

			if (movementHistory.length > 0) {
				if (
					movementHistory[movementHistory.length - 1].assignmentId !==
					getNewestId(assignments)
				) {
					setFetchNewAssignmentId(false);
				}
				movementHistory[movementHistory.length - 1].assignmentId =
					getNewestId(assignments);
			}

			setMovementHistory(movementHistory);
		}
	}, [fetchNewAssignmentId, assignments]);

	const [scrollingLeft, setScrollingLeft] = useState(true);
	const [scrollingRight, setScrollingRight] = useState(true);

	useEffect(() => {
		if (wardsLoading) return;
		setEvents(getEvents(assignments, scopeWards, scopeWards));
		setRooms(getRooms(assignments, scopeWards, scopeWards));
		setWards(getWards(assignments, reallyAllWards.map((ward) => ward.id), reallyAllWards, scopeWards));
	}, [assignments, wardsLoading]);

	useEffect(() => {
		if (wardsLoading) return;
		setEvents(getEvents(assignments, scopeWards, scopeWards));
		setRooms(getRooms(assignments, scopeWards, scopeWards));
		setWards(getWards(assignments, reallyAllWards.map((ward) => ward.id), reallyAllWards, scopeWards));
	}, [selectedWards, wardsLoading]);

	function keydownEvent(event) {
		if (
			(event.metaKey && event.key === "z" && event.shiftKey) ||
			(event.ctrlKey && event.key === "y")
		) {
			redo();
		} else if ((event.ctrlKey || event.metaKey) && event.key === "z") {
			undo();
		}
	}

	// Add event listener for undo and redo keyboard controls
	useEffect(() => {
		document.addEventListener("keydown", keydownEvent);
		return () => {
			document.removeEventListener("keydown", keydownEvent);
		};
	}, [keydownEvent]);

	const initEvents = getEvents();
	const initRooms = getRooms();
	const initWards = getWards();
	const [status, setStatus] = useState(Cookies.get("calendar_view_status") !== undefined ? JSON.parse(Cookies.get("calendar_view_status")) : { Zu_belegende_Patienten: { expanded: true }, Ausgehende_Anfragen: { expanded: true }, Eingehende_Anfragen: { expanded: true } });
	const [rooms, setRooms] = useState(initRooms);
	const [wards, setWards] = useState(initWards);
	const [events, setEvents] = useState(initEvents);

	const { assignPatientToBed, removePatientFromBed } = APIUtils();

	//if a patient is dragged to an unsuitable bed, the reason is stored in this variable
	const [errorMessage, setErrorMessage] = useState("");
	//get the functions and variables to control the opening and closing of the alert dialog (change bed aborted)
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = React.useRef();

	const handleDroppedPatientInfo = (e) => {
		const {
			detail: {
				bedId,
				from,
				assignmentId,
				dateDrag,
				fromBed,
				patientId,
				split,
			},
		} = e;
		console.log("drop info:", e)
		if (from !== dateDrag) {
			setErrorMessage(getReasonFailed("no_horizontal_move"));
			onOpen();
			setFetchCounter(!fetchCounter);
		} else {
			updatePatientAssignment(
				assignmentId,
				isNaN(parseInt(bedId)) ? "no" : bedId,
				dayjs(from)
			).then(() => {
				// save movement to cookie
				let movementHistory = getMovementHistoryFromCookie();

				resetFutureHistory();

				if (movementHistory.length === 5) {
					movementHistory.shift();
				}

				movementHistory.push({
					patientId: patientId,
					assignmentId: assignmentId,
					fromBed: fromBed,
					toBed: bedId,
					date: from,
				});

				setMovementHistory(movementHistory);

				if (split) {
					setFetchNewAssignmentId(true);
				}
				setFetchCounter(!fetchCounter);
			});
		}
	};

	// Add the event listener for drop event
	useEffect(() => {
		document.addEventListener(
			"droppedPatientInfo",
			handleDroppedPatientInfo
		);
		return () => {
			document.removeEventListener(
				"droppedPatientInfo",
				handleDroppedPatientInfo
			);
		};
	}, [handleDroppedPatientInfo]);

	function openAndFetch() {
		onOpen();
		setFetchCounter(!fetchCounter);
	}

	const updatePatientAssignment = async (
		assignmentId,
		bedId,
		from,
		undo,
		movementHistory,
		lastMove
	) => {
		// check if patient is dropped to the "not assigned" area
		if (bedId === "no") {
			await removePatientFromBed(assignmentId, from);
			if (undo) {
				handleUndoRedoSuccess(undo, movementHistory, lastMove);
				openAndFetch();
			}
		} else {
			// assign patient to bed
			const result = await assignPatientToBed(assignmentId, bedId, from);
			if (!result.isSuccessful && Object.hasOwn(result, "reason")) {
				// if the assignment failed, render the reason for the failure in the alert dialog
				handleFail(result.reason);
				if (undo === undoKey) {
					undoFail();
				} else if (undo === redoKey) {
					redoFail();
				}
				setFetchCounter(!fetchCounter);
			}
			if (result.isSuccessful && Object.hasOwn(result, "comment")) {
				// if the assignment failed, render the reason for the failure in the alert dialog
				setErrorMessage(getComment(result.comment));
				openAndFetch();
			} else if (result.isSuccessful && undo) {
				handleUndoRedoSuccess(undo, movementHistory, lastMove);
				openAndFetch();
			}
		}
	};

	function handleUndoRedoSuccess(undo, movementHistory, lastMove) {
		if (undo === undoKey) {
			handleUndoSuccess(movementHistory, lastMove);
			setErrorMessage(getComment(undoKey));
		} else if (undo === redoKey) {
			handleRedoSuccess(movementHistory, lastMove);
			setErrorMessage(getComment(redoKey));
		}
	}

	function handleFail(reason) {
		setErrorMessage(getReasonFailed(reason));
		onOpen();
	}

	function undoFail() {
		resetMovementHistory();
		handleFail(undoKey);
	}

	function redoFail() {
		resetFutureHistory();
		handleFail(redoKey);
	}

	function undo() {
		undoMove(undoFail, updatePatientAssignment);
	}

	function redo() {
		redoMove(redoFail, updatePatientAssignment);
	}

	useEffect(() => {
		setTimeout(() => {
			setSelectedDate(new Date());
			setScrolling(!scrolling);
		}, 10);
	}, [startDay]);

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.style.overflow = "auto";
		};
	});

	const handleSetStatus = (status) => {
		Cookies.set("calendar_view_status", JSON.stringify(status));
		setStatus(status);
	}

	const { user, logoutUser } = useContext(AuthContext);

	if (wardsLoading)
		return (
			<Stack align="center">
				<Spinner />
			</Stack>
		);
	return (
		<>
			<Flex justifyItems={"bottom"} alignItems={"flex-end"}>
				<Heading
					size="2xl"
					mt={10}
					color="#0F4046"
					fontWeight={"light"}
				>
					Patientenkalender
				</Heading>
				<Spacer />
				<Box display="flex" alignItems="center" mr="1">
					<Arrow
						icon={<TiChevronLeft />}
						onClick={handleLeftArrowClick}
						ariaLabel="Previous day"
						isDisabled={false}
					></Arrow>
					<Button
						colorScheme="brand"
						mx="1"
						size="sm"
						variant="solid"
						onClick={() => {
							setSelectedDate(new Date());
							setTimeout(() => {
								setScrolling(!scrolling);
							}, 10);
						}}
					>
						Heute
					</Button>
					<Arrow
						icon={<TiChevronRight />}
						onClick={handleRightArrowClick}
						ariaLabel="Next day"
						isDisabled={false}
					></Arrow>
					<Button
						colorScheme="brand"
						mx="1"
						size="sm"
						opacity={`${getMovementHistoryFromCookie().length > 0
							? "1"
							: "0.5"
							}`}
						variant="solid"
						onClick={undo}
					>
						Rückgängig machen
					</Button>
					<Button
						colorScheme="brand"
						mx="1"
						opacity={`${getFutureHistoryFromCookie().length > 0
							? "1"
							: "0.5"
							}`}
						variant="solid"
						onClick={redo}
						size="sm"
					>
						Wiederherstellen
					</Button>
				</Box>
				<SwitchWardPanelComponent
					wards={allWards}
					wardsSelected={selectedWards}
					setWardsSelected={setSelectedWards}
					username={username}
				/>
				<HStack spacing={2} ml={2}>
					<Icon as={FaIcons.FaUserAlt} />
					<Text color={"black"}>{user}</Text>
					<Divider orientation="vertical" />
					<IconButton
						size="sm"
						isRound="true"
						variant="ghost"
						aria-label="Ausloggen"
						icon={<MdIcons.MdLogout />}
						onClick={logoutUser}
					/>
				</HStack>
			</Flex>
			<Box pt="4">
				{rooms?.length > 0 &&
					events?.length > 0 &&
					wards?.length > 0 && (
						<>
							<Calendar
								setFetchDate={setFetchDate}
								fetchDate={fetchDate}
								selectedDate={selectedDate}
								scrollingLeft={scrollingLeft}
								scrollingRight={scrollingRight}
								scrolling={scrolling}
								events={events}
								rooms={rooms}
								wards={wards}
								status={status}
								setStatus={setStatus}
								proposal={false}
								initialDates={[
									subWeeks(selectedDate, 3),
									addWeeks(selectedDate, 4),
								]}
								onChange={(event) => {
									setEvents((events) => {
										return events.map((_event) => {
											return _event.id === event.id
												? event
												: _event;
										});
									});
								}}
								suggestion={suggestion}
								suggestionPatientId={suggestionPatientId}
							></Calendar>
							<AlertDialog
								isOpen={isOpen}
								leastDestructiveRef={cancelRef}
								onClose={onClose}
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader
											fontSize="lg"
											fontWeight="bold"
										>
											Achtung
										</AlertDialogHeader>

										<AlertDialogBody>
											{errorMessage}
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button
												ref={cancelRef}
												onClick={onClose}
											>
												Okay
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
						</>
					)}
			</Box>
		</>
	);
};

export default CalendarManager;
