import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import emitter from "../util/event";

//import Chakra UI components
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  HStack,
  Textarea,
} from "@chakra-ui/react";
import { FaCheckCircle, FaWindowClose } from "react-icons/fa";

import "../index.css";
import AuthContext from "../context/AuthContext";

import Patient from "../models/Patient";
import APICenter from "../apis/APICenter";
import APIUtils from "../apis/APIUtils";
import { AssignmentFailMessage, AssignmentSuccMessage } from "../apis/Message";

const AddPatientComponent = () => {
  const [showAddPatient, setShowAddPatient] = useState(false);
  const [showSuccAssign, setShowSuccAssign] = useState(false);
  const [showFailAssign, setShowFailAssign] = useState(false);
  const [genderToggleSucc, setGenderToggleSucc] = useState("");
  const [genderToggleFail, setGenderToggleFail] = useState("");
  const [reasonRendered, setReasonRendered] = useState("");
  const [renderedSuggestionHeading, setRenderedSuggestionHeading] = useState("");
  const [renderedSuggestions, setRenderedSuggestions] = useState([]);
  const [wards, setWards] = useState([]);
  const [patient, setPatient] = useState(new Patient());
  const [suggestions, setSuggestions] = useState([]);
  const [dischargeDate, setDischargeDate] = useState("");
  const [automaticAssignmentRequested, setAutomaticAssignmentRequested] = useState(false);
  const [arrivalDate, setArrivalDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { authCenter } = useContext(AuthContext);

  const { getData, getSuggestions } = APIUtils();

  const apiCenter = new APICenter();


  const setDummyPatient = () => {
    const empty_patient = new Patient();
    empty_patient.birth_date = '1900-01-01';
    empty_patient.gender = 'M';
    
    //set home ward depending if wards are currently selected
    if (wards.length > 0) {
      const home_ward = wards[0];
      empty_patient.home_ward = home_ward['id'];
    }
    
    //set start and end dates of assignment
    const start = new Date().toISOString().slice(0,16)
    const end = new Date(new Date().getTime()+(5*24*60*60*1000)).toISOString().slice(0,16);
    setArrivalDate(start);
    setDischargeDate(end);


    setPatient(empty_patient)
  }

  const getWards = async () => {
    const responseFetchWards = await getData("wards");
    if (responseFetchWards.isSuccessful) {
      setWards(responseFetchWards.data);
    }
  };

  useEffect(() => {
    getWards();

    const callback = (e) => {
      setShowAddPatient(e);
    };
    emitter.on("showAddPatient", callback);

    return () => {
      emitter.removeListener("showAddPatient", callback);
    };
  }, []);

  
  useEffect(() => {
    setDummyPatient();
  }, []);

  const handleChange = (event) => {
    setAutomaticAssignmentRequested(event.target.checked);
  };

  const inputChange = (e) => {
    const element = e.target;
    patient[element.name] = element.value;
    setPatient(Object.assign(new Patient(), patient));
  };

  const toggleBooleanChange = (e) => {
    const element = e.target;
    patient[element.name] = !Boolean(patient[element.name]);
    setPatient(Object.assign(new Patient(), patient));
  };

  let navigate = useNavigate();
  const navigateToStart = () => {
    emitter.emit(
      "menuIdx",
      parseInt(window.localStorage.getItem("lastMenuIdx"))
    );

    setShowAddPatient(false);
    setShowSuccAssign(false);
    setShowFailAssign(false);
    setPatient(new Patient());
    setDummyPatient();
    setAutomaticAssignmentRequested(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault(); //so it does not submit to a page

    if (!patient.lastname) {
      return;
    }
    if (dischargeDate < arrivalDate) {
      alert("Das Entlassdatum muss hinter dem Ankunftsdatum liegen");
      return;
    }

    //start the loading animation
    setIsLoading(true);
    const msg = await apiCenter.addPatient(
      patient,
      dischargeDate,
      automaticAssignmentRequested,
      arrivalDate
    );
    setIsLoading(false);

    if (msg) {
      //Check if assignment should be done immediately
      if (automaticAssignmentRequested === true) {
        if (msg instanceof AssignmentSuccMessage && msg.isSucc) {
          setPatient(Object.assign(new Patient(), msg.patient));
          setShowSuccAssign(true);
          if (msg.patient.gender === "M") {
            setGenderToggleSucc(
              "Der Patient " +
              msg.patient.lastname +
              " (Id: " +
              msg.patient.id +
              ") wurde Bett " +
              msg.bed.name +
              " (Id: " +
              msg.bed.id +
              ") in Raum " +
              msg.room.name +
              " (Id: " +
              msg.room.id +
              " ) zugewiesen."
            );
          } else if (msg.patient.gender === "F") {
            setGenderToggleSucc(
              "Die Patientin " +
              msg.patient.lastname +
              " (Id: " +
              msg.patient.id +
              ") wurde Bett " +
              msg.bed.name +
              " (Id: " +
              msg.bed.id +
              ") in Raum " +
              msg.room.name +
              " (Id: " +
              msg.room.id +
              " ) zugewiesen."
            );
          } else if (msg.patient.gender === "X") {
            setGenderToggleSucc(
              "Die zu behandelnde Person " +
              msg.patient.lastname +
              " (Id: " +
              msg.patient.id +
              ") wurde Bett " +
              msg.bed.name +
              " (Id: " +
              msg.bed.id +
              ") in Raum " +
              msg.room.name +
              " (Id: " +
              msg.room.id +
              " ) zugewiesen."
            );
          }
        } else if (msg instanceof AssignmentFailMessage && msg.isSucc) {
          setPatient(Object.assign(new Patient(), msg.patient));
          setShowFailAssign(true);
          if (msg.patient.gender === "M") {
            setGenderToggleFail(
              "Der Patient " +
              msg.patient.lastname +
              " (Id:" +
              msg.patient.id +
              ")" +
              " konnte keinem passenden Bett zugewiesen werden."
            );
          } else if (msg.patient.gender === "F") {
            setGenderToggleFail(
              "Die Patientin " +
              msg.patient.lastname +
              "(Id:" +
              msg.patient.id +
              ")" +
              " konnte keinem passenden Bett zugewiesen werden."
            );
          } else if (msg.patient.gender === "X") {
            setGenderToggleFail(
              "Die zu behandelnde Person " +
              msg.patient.lastname +
              "(Id:" +
              msg.patient.id +
              ")" +
              " konnte keinem passenden Bett zugewiesen werden."
            );
          }

          let reasonText = "";
          if (msg.reason === "full") {
            reasonText = "Grund: Alle Betten sind belegt.";
          } else if (msg.reason === "not full - something possible") {
            reasonText =
              "Grund: Es sind noch Betten frei, aber fixe und reservierte Patienten blockieren eine effiziente automatische Allokation.";
            setRenderedSuggestionHeading("Vorgeschlagene Verlegungen:");
          } else if (msg.reason === "not full - nothing possible") {
            reasonText =
              "Grund: Es sind noch Betten frei, aber die Anforderungen aller Patienten können nicht bedriedigt werden.";
          }
          setReasonRendered(reasonText);

          if (msg.suggestions) {
            const renderedSuggestion = [];
            //first show the patients whose bed changes
            for (let i in suggestions) {
              if (suggestions[i]["bed_old"] !== null) {
                renderedSuggestion.push(
                  <li key={suggestions[i]["patient_id"]}>
                    Patient {suggestions[i]["patient_name"]} von Bett{" "}
                    {suggestions[i]["bed_name_old"]} (Raum{" "}
                    {suggestions[i]["room_id_old"]}) nach Bett{" "}
                    {suggestions[i]["bed_name_new"]} (Raum{" "}
                    {suggestions[i]["room_id_new"]}) verschieben.
                  </li>
                );
              }
            }

            // //then show the patient did not have a bed before
            for (let i in suggestions) {
              if (suggestions[i]["bed_old"] === null) {
                renderedSuggestion.push(
                  <li key={suggestions[i]["patient_id"]}>
                    Patient {suggestions[i]["patient_name"]} auf Bett{" "}
                    {suggestions[i]["bed_name_new"]} (Raum{" "}
                    {suggestions[i]["room_id_new"]}) verschieben.
                  </li>
                );
              }
            }

            setRenderedSuggestions(renderedSuggestion);
            console.log(renderedSuggestion);
          }
        } else {
          navigate("/");
          emitter.emit("update_room_view")
        }
      }
    }

    //If Patient is not assigned automatically, navigate back and refresh to show the assignment
    else {
      navigateToStart();
      emitter.emit("update_room_view")
    }
    emitter.emit("menuIdx", 0);
    setShowAddPatient(false);
  };

  return (
    <>
      <Modal
        size={"3xl"}
        isCentered
        isOpen={showAddPatient}
        scrollBehavior={"inside"}
        onClose={() => {
          setShowAddPatient(!showAddPatient);
          navigateToStart();
        }}
        motionPreset="slideInLeft"
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(0deg)"
        />

        <ModalContent maxW={"900"} maxH={""} alignItems={"center"}>
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
            mt={"100"}
          >
            Einen neuen Patient anlegen
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addPatientBox">
            <Box my={"10"}>
              <form id="addPatient" onSubmit={onSubmit}>
                <HStack spacing={30} alignItems="top">
                  <Box w={350} alignContent={"top"}>
                    <FormControl isRequired>
                      <FormLabel>Vorname des Patienten</FormLabel>
                      <Input
                        name="firstname"
                        mb={4}
                        type="text"
                        placeholder="Vorname des Patienten"
                        value={patient.firstname}
                        isRequired={true}
                        onChange={inputChange}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Name des Patienten</FormLabel>
                      <Input
                        name="lastname"
                        mb={4}
                        type="text"
                        placeholder="Name des Patienten"
                        value={patient.lastname}
                        isRequired={true}
                        onChange={inputChange}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Geburtsdatum</FormLabel>
                      <Input
                        name="birth_date"
                        mb={4}
                        type="date"
                        value={patient.birth_date}
                        isRequired={true}
                        onChange={inputChange}
                        max="9999-12-31"
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Geschlecht des Patienten</FormLabel>
                      <Select
                        name="gender"
                        mb={4}
                        placeholder="Wählen Sie ein Geschlecht"
                        value={patient.gender}
                        isRequired={true}
                        onChange={inputChange}
                      >
                        <option value="M" key="M">
                          Männlich
                        </option>
                        <option value="F" key="F">
                          Weiblich
                        </option>
                        {/** Not allowed up to date */}
                        {/*<option value='X'>Divers</option>*/}
                      </Select>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Ankunftsdatum und Uhrzeit </FormLabel>
                      <Input
                        mb={4}
                        type="datetime-local"
                        placeholder=""
                        id="arrivaldate"
                        value={arrivalDate}
                        onChange={(event) => setArrivalDate(event.target.value)}
                      ></Input>
                    </FormControl>
                  </Box>

                  <Box w={350} alignContent={"top"}>
                    <FormControl>
                      <FormLabel>DRG Code</FormLabel>
                      <Input
                        name="drg-code"
                        mb={4}
                        type="text"
                        placeholder="DRG Code eingeben"
                        //value={}
                        //onChange={inputChange}
                        isDisabled
                      ></Input>
                    </FormControl>

                    <FormControl>
                      <Checkbox
                        name="assume-patient-length-of-stay"
                        mb={4}
                        type="checkbox"
                        //value={Boolean()}
                        //onChange={toggleBooleanChange}
                        isDisabled
                      >
                        Aufenthaltsdauer aus DRG übernehmen.
                      </Checkbox>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Entlassdatum und Uhrzeit </FormLabel>
                      <Input
                        mb={4}
                        type="datetime-local"
                        placeholder=""
                        id="dismissal-date"
                        value={dischargeDate}
                        onChange={(event) =>
                          setDischargeDate(event.target.value)
                        }
                      ></Input>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Vorgesehene Station</FormLabel>
                      <Select
                        name="home_ward"
                        mb={4}
                        placeholder="Wählen Sie eine Station"
                        value={patient.home_ward}
                        isRequired={true}
                        onChange={inputChange}
                      >
                        {wards.map((ward) => (
                          <option value={ward.id} key={ward.id}>
                            {ward.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl>
                      <Checkbox
                        name="isolation_needed"
                        mb={4}
                        type="checkbox"
                        value={Boolean(patient.isolation_needed)}
                        onChange={toggleBooleanChange}
                      >
                        Der Patient muss <b>isoliert</b> werden.
                      </Checkbox>
                    </FormControl>

                    <FormControl>
                      <Checkbox
                        name="bed_place_isolation_needed"
                        mb={4}
                        type="checkbox"
                        value={Boolean(patient.bed_place_isolation_needed)}
                        onChange={toggleBooleanChange}
                      >
                        Der Patient muss <b>bettplatz-isoliert</b> werden.
                      </Checkbox>
                    </FormControl>

                    <FormControl>
                      <Checkbox
                        name="private_insurance"
                        mb={4}
                        type="checkbox"
                        value={Boolean(patient.private_insurance)}
                        onChange={toggleBooleanChange}
                      >
                        Der Patient ist <b>privatversichert</b>.
                      </Checkbox>
                    </FormControl>

                    {/** Excludet due to current feedback of MRI - not necessary */}
                    {/** 
                    <FormControl>
                      <Checkbox
                        alignItems={"top"}
                        name="single_room_needed"
                        mb={4}
                        type="checkbox"
                        value={Boolean(patient.single_room_needed)}
                        onChange={toggleBooleanChange}
                      >
                        Der Patient hat einen Anspruch auf ein{" "}
                        <b>Privatzimmer</b>.
                      </Checkbox>
                    </FormControl>
                    */}

                    <FormControl>
                      <Checkbox
                        name="disturbs"
                        mb={4}
                        type="checkbox"
                        value={Boolean(patient.disturbs)}
                        onChange={toggleBooleanChange}
                      >
                        Der Patient <b>stört</b> andere Patienten.
                      </Checkbox>
                    </FormControl>
                  </Box>
                </HStack>

                <FormControl>
                  <FormLabel>Zusätzliche Informationen</FormLabel>
                  <Textarea
                    name="additional_patient_information"
                    resize='vertical'
                    mb={4}
                    type="text"
                    placeholder="Zusätzliche Informationen"
                    value={patient.additional_patient_information}
                    onChange={inputChange}
                    disabled
                  ></Textarea>
                </FormControl>

                <Text mt={5}> *Pflichtfelder </Text>

                <HStack mt={"10"}>
                  <FormControl>
                    <Checkbox
                      name="automated_assignment"
                      mb={4}
                      type="checkbox"
                      value={Boolean()}
                      onChange={handleChange}
                    >
                      PatientIn automatisch belegen
                    </Checkbox>
                  </FormControl>

                  {/** BUTTON FOR ADDING PATIENT */}
                  {isLoading ? (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={true}
                      isLoading
                      spinnerPlacement="end"
                      loadingText="PatientIn wird angelegt"
                    />
                  ) : (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={false}
                    >
                      Patienten anlegen
                    </Button>
                  )}
                </HStack>
              </form>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        isOpen={showSuccAssign}
        motionPreset="slideInBottom"
        blockScrollOnMount={false}
        onClose={() => {
          setShowSuccAssign(!showSuccAssign);
          navigateToStart();
          emitter.emit("update_room_view")
        }}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(0deg)"
        />
        <ModalContent
          bg="green.100"
          width="full"
          align="center"
          justifyContent="center"
          mt="5%"
        >
          {/* <ModalHeader color={'#00b5d8'}>Geschafft!</ModalHeader> */}
          <ModalBody
            p={8}
            maxWidth="600px"
            borderWidth={1}
            borderRadius={8}
            boxShadow="lg"
          >
            <Flex>
              <Box>
                <Center>
                  <Icon
                    as={FaCheckCircle}
                    color={"gray.600"}
                    boxSize="25px"
                    mb={5}
                  />
                </Center>
                <Box textAlign="center">
                  <Heading color={"gray.600"} mb={10}>
                    {patient.gender === "F"
                      ? "Die Patientin konnte belegt werden"
                      : "Der Patient konnte belegt werden"}
                  </Heading>
                  <Box my={4}>
                    <Text align="left"> {genderToggleSucc}</Text>
                    <Text align="left">
                      {" "}
                      Die Zuweisung ist vorläufig und Änderungen können über den
                      Belegungsplaner vorgenommen werden.
                    </Text>
                  </Box>
                </Box>
                <Button
                  width="full"
                  mt={4}
                  variant="outline"
                  bg="White"
                  colorScheme="red"
                  onClick={() => {
                    setShowSuccAssign(!showSuccAssign);
                    navigateToStart();
                    emitter.emit("update_room_view")
                  }}
                >
                  Zur Belegung
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        isOpen={showFailAssign}
        motionPreset="slideInBottom"
        blockScrollOnMount={false}
        onClose={() => {
          setShowFailAssign(!showFailAssign);
          navigateToStart();
          emitter.emit("update_room_view")
        }}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(0deg)"
        />
        <ModalContent
          bg="red.100"
          width="full"
          align="center"
          justifyContent="center"
          mt="5%"
        >
          {/* <ModalHeader color={'#00b5d8'}>Geschafft!</ModalHeader> */}
          <ModalBody
            p={8}
            maxWidth="600px"
            borderWidth={1}
            borderRadius={8}
            boxShadow="lg"
          >
            <Flex>
              <Box>
                <Center>
                  <Icon
                    as={FaWindowClose}
                    color={"gray.600"}
                    boxSize="25px"
                    mb={5}
                  />
                </Center>
                <Box textAlign="center">
                  <Heading color={"gray.600"} mb={10}>
                    {patient.gender === "F"
                      ? "Die Patientin konnte nicht belegt werden"
                      : "Der Patient konnte nicht belegt werden"}
                  </Heading>
                  <Box my={4}>
                    <Text align="left">{genderToggleFail}</Text>
                    <br />
                    <Text align="left"> {reasonRendered}</Text> <br />
                    <Text align="left"> {renderedSuggestionHeading} </Text>
                    <Text align="left"> {renderedSuggestions} </Text>
                  </Box>
                </Box>
                <Button
                  width="full"
                  mt={4}
                  variant="outline"
                  bg="White"
                  colorScheme="green"
                  onClick={() => {
                    setShowFailAssign(!showFailAssign);
                    navigateToStart();
                    emitter.emit("update_room_view")
                  }}
                >
                  Zur Belegung
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddPatientComponent;
