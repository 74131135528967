import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import BedComponent from "./BedComponent";
import Room from "../models/Room";
import { Assignment } from "../models/Assignment";
import Bed from "../models/Bed";
import Ward from "../models/Ward";


type RoomComponentProps = {
  assignments: Assignment[]
  room: Room
  beds: Bed[]
  isDragEnabled: boolean
  selectedDate: Date
  roomWard: Ward
  renderedWardId: number
}

const RoomComponent = ({
  assignments,
  room,
  beds,
  isDragEnabled,
  selectedDate,
  roomWard, //the ward where the room is located in
  renderedWardId //the current ward where the room is rendered
}: RoomComponentProps) => {

  const isExtern = roomWard.id !== renderedWardId

  function getAssignmentsForBed(bed, assignments){
    return assignments.filter(a => a.bed.id === bed.id);
  } 

  function checkIfDropDisabled(bed, assignments) {
    // Disable the bed as a droppable, if two or more patients are assigned to it
    // Filter assignments only bed.id = assignment.bed
    const filteredAssignments = getAssignmentsForBed(bed, assignments)

    if (filteredAssignments.length === 1 && filteredAssignments[0].assignment_status === 'B') {
      return true
    }

    // do not allow d&d to full or extern beds 
    return (filteredAssignments.length >= 2) || isExtern
  }

  const checkIfIsolationPatientInRoom = () => {
    //Goes through all assignments in this room
    //If one of the assigned patients must be isolated, return true

    //First, get all the assignments of this room
    const bedIds = beds.map(b => b.id);
    const assignmentsOfRoom = assignments.filter(function (element) {
      return bedIds.includes(element.bed.id);
    });

    //Then, check if one of the patients if the assignments must be isolated
    for (const assignment of assignmentsOfRoom) {
      let isolationPatientInRoom = false
      if (assignment.patient !== null) {
        isolationPatientInRoom = assignment.patient.isolation_needed;
      }
      if (isolationPatientInRoom) {
        return true;
      }
    }
    return false;
  };

  return (
    <Stack p="3" borderRadius="10" minW={"300px"} bg="white">
      <Text ml="2" fontSize="md" color={(isExtern ? "#FFC442" : "brand.500")} fontWeight={"bold"}>
        Raum {room.name + (isExtern ?  (" - " + roomWard.name) : "")}
      </Text>
      {beds.map(b => (
        <BedComponent
          selectedDate={selectedDate}
          key={b.id}
          bed={b}
          assignment={getAssignmentsForBed(b, assignments)}
          isDropDisabled={checkIfDropDisabled(b, assignments)}
          isDragEnabled={isDragEnabled && !isExtern}
          room={room}
          renderedWardId={renderedWardId}
          checkIfIsolationPatientInRoom={checkIfIsolationPatientInRoom}
        />
      ))}
    </Stack>
  );
};

export default RoomComponent;
