export default class Suggestion {
  id: number
  suggestion_patient_id: number
  patient: number
  start: number
  end: number
  bed_old: number
  bed_new: number
  patient_name: string
  bed_name_old: string
  room_id_old: number
  bed_name_new: string
  room_id_new: number

  constructor(
    id = -1,
    suggestion_patient_id = -1,
    patient = -1,
    patient_name = 'undefined',
    start = -1,
    end = -1,
    bed_old = -1,
    bed_new = -1,
    bed_name_old = 'undefined',
    bed_name_new = 'undefined',
    room_id_old = -1,
    room_id_new = -1
  ) {
    this.bed_name_new = bed_name_new
    this.bed_name_old = bed_name_old
    this.bed_new = bed_new
    this.bed_old = bed_old

    this.start = start
    this.end = end

    this.patient_name = patient_name
    this.patient = patient

    this.room_id_old = room_id_old
    this.room_id_new = room_id_new

    this.id = id
    this.suggestion_patient_id = suggestion_patient_id
  }
}
