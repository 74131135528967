import emitter from "../util/event";

//import Chakra UI components
import {
    Box,
    IconButton,
    HStack,
} from "@chakra-ui/react";
import EditBaseDataComponent from "./EditBaseDataComponent";
import {EditIcon} from "../theme/icons";



const AddBaseDataBedComponent = (props) => {
    const showBedEditForm = () => {
        emitter.emit(`showEditBed/${props.bed_id}`);
    }
    return (
        <>
                <HStack height={"32px"} width={"176px"} bg='#37B7C7' borderRadius={"6"}>
                    <Box as="span" flex='1' textAlign='left' fontWeight={"400"} fontSize={"15px"} color={"white"} paddingLeft={"12px"}>
                        Bett {props.bed_name}
                    </Box>
                    <IconButton 
                        icon={<EditIcon/>}
                        onClick={showBedEditForm}
                        size={"sm"}
                        bg='#37B7C7'
                    />
                </HStack>
                <EditBaseDataComponent
                    bedState={props.bed_id}/>
        </>
    );
}

export default AddBaseDataBedComponent;
