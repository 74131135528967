import React from "react";
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Checkbox,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import Ward from "../models/Ward";

type WardIds = number[]

type SwitchWardPanelComponentProps = {
  wards: Ward[];
  wardsSelected: WardIds;
  setWardsSelected: (wards: WardIds) => void;
  username: string;
};

const SwitchWardPanelComponent = ({
  wards,
  wardsSelected,
  setWardsSelected,
  username,
}: SwitchWardPanelComponentProps) => {
  /* if wardsSelected has more than 1 ward, show "Mehrere Bereiche"
      else if wardsSelected has 1 ward, show the name of the ward
      else show "Kein Bereich ausgewählt" */

  const captionSelectedWard = () =>
    wardsSelected.length > 1
      ? "Mehrere Abteilungen ausgewählt"
      : wardsSelected.length === 1
        ? wards.find((ward) => ward.id === wardsSelected[0])?.name ??
        "Nicht gefunden"
        : "Keine Abteilung ausgewählt";

  const handleOnChange = (ward: Ward, checked: boolean) => {
    // TODO stefc Müsste im Client dieser Komponente verarbeitet werden -> OnSelected Event
    // hier kein Sideeffekt zu Cookies etc.
    if (checked) {
      // check if ward is already in the list
      if (!wardsSelected.includes(ward.id)) {
        setWardsSelected([...wardsSelected, ward.id]);
        const _wardsSelected = JSON.stringify([...wardsSelected, ward.id]);
        Cookies.set(`wardsSelected_${username}`, _wardsSelected, {
          expires: 1,
        });
      }
    } else {
      // check if ward is in the list
      if (wardsSelected.includes(ward.id)) {
        setWardsSelected(wardsSelected.filter((id) => id !== ward.id));
        const _wardsSelected = JSON.stringify(
          wardsSelected.filter((id) => id !== ward.id)
        );
        Cookies.set(`wardsSelected_${username}`, _wardsSelected, {
          expires: 1,
        });
      }
    }
  };

  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} colorScheme="brand" width="72" size="sm">
        {captionSelectedWard()}
      </MenuButton>
      <MenuList>
        {wards.map((ward) => (
          <MenuItem key={ward.id}>
            <Checkbox
              size="lg"
              colorScheme="brand"
              isChecked={wardsSelected.includes(ward.id)}
              onChange={(e) => handleOnChange(ward, e.target.checked)}
            >
              {ward.name}
            </Checkbox>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default SwitchWardPanelComponent;
