import React from "react"
import "../index.css"
import {
  Box,
  Divider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react"
import emitter from "../util/event"
import EditBlockBedComponent from "./EditBlockBedComponent"
import { Assignment } from "../models/Assignment"

import BedLocked from "../assets/bed_locked.svg"
import LabeledText from "./common/LabeledText"

import {
  renderDateTime,
  renderDaysUntilEnd,  
} from "./RenderFunctions";

type BlockedBedCapsuleComponentProps = {
  assignment: Assignment
}
const BlockedBedCapsuleComponent = ({ assignment }: BlockedBedCapsuleComponentProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  // Get the blocks duration and depature information

  const openEditBlockBedForm = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    emitter.emit(`EditBlockBed/${assignment.id}`)
  }

  return (
    <>
      <EditBlockBedComponent
        bedId={assignment.bed.id}
        assignmentId={assignment.id}
        from={assignment.start}
        to={assignment.end}
      />
      <Popover placement="auto" autoFocus isLazy isOpen={isOpen}>
        <PopoverTrigger>
          <Box width="100%">
            <Box
              onMouseOver={onOpen}
              onMouseLeave={onClose}
              onClick={(e) => openEditBlockBedForm(e)}
              borderRadius="5px"
              height="40px"
              display="flex"
              alignItems="center"
              mb="10px"
              backgroundImage={BedLocked}
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              backgroundColor="bedStatus.300"
            ></Box>
          </Box>
        </PopoverTrigger>

        <PopoverContent borderRadius="6">
          <PopoverArrow />
          <PopoverHeader border="0" fontWeight="bold">
            Blockiertes Bett
            <Divider/>
          </PopoverHeader>

          <PopoverBody>
            {assignment.end && assignment.start && (
              <>
                <LabeledText label="Geplante Freigabe" text={renderDaysUntilEnd(assignment.end)} />
                <LabeledText label="Blockiert seit" text={renderDateTime(assignment.start)} />
                <LabeledText label="Freigabe" text={renderDateTime(assignment.end)} />
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default BlockedBedCapsuleComponent
