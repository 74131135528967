import CalendarView from './views/CalendarView'
import RoomView from './views/RoomView'
import UtilizationView from './views/UtilizationView'
import PatientView from './views/PatientView'
import NotFoundView from './views/NotFoundView'
import SettingsView from './views/SettingsView'

const routes = [
  {
    name: 'Room View Default',
    path: '',
    element: <RoomView />,
  },
  {
    name: 'Room View',
    path: 'room_view',
    element: <RoomView />,
  },
  {
    name: 'Calendar View',
    path: 'calendar_view',
    element: <CalendarView />,
  },
  {
    name: 'View Patient',
    path: 'patient/:id',
    element: <PatientView />,
  },
  {
    name: 'Utilization',
    path: 'utilization',
    element: <UtilizationView />,
  },
  {
    name: 'Settings',
    path: 'settings',
    element: <SettingsView />,
  },
  {
    name: '404 Page',
    path: '/*',
    element: <NotFoundView />,
  },
]

export default routes
