import Ward from "../models/Ward";

export default class Room {
  id: number
  name: string
  size: number // The number of beds in the room
  ward: Ward

  constructor(
    id = -1,
    name = 'undefined',
    size = -1,
    ward = new Ward(),
  ) {
    this.id = id
    this.name = name
    this.size = size
    this.ward = ward
  }
}
