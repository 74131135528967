import { Calendar } from "../calendar/calendar";
import {
    Box,
} from "@chakra-ui/react";
import {
    getEvents,
    getRooms,
    getWards,
} from "./CalendarManagerHelpers";
import { addDays } from "date-fns";
import { useState, useEffect } from "react";
import { useWards } from "../../context/WardsContext";
import APICenter from "../../apis/APICenter";
import { FetchWardsMessage } from "../../apis/Message";

const ProposalCalendarManager = ({
    fetchDate,
    setFetchDate,
    selectedDate,
    assignments,
    scrolling,
    initialDates
}) => {
    const [scrollingLeft, setScrollingLeft] = useState(true);
    const [scrollingRight, setScrollingRight] = useState(true);
    const [status, setStatus] = useState({});
    const [rooms, setRooms] = useState(getRooms());
    const [wards, setWards] = useState(getWards());
    const [events, setEvents] = useState(getEvents());

    const [selectedWards, setSelectedWards] = useState([]);

    const { wards: allWards, allWards: reallyAllWards, isLoading: wardsLoading } = useWards();
    const apiCenter = new APICenter();

    useEffect(() => {
        var initialStatus = {}
        for (const room of rooms) {
            if (assignments.assignmentsOfRooms?.some(a => a.room.id.toString() === room.id &&
                a.assignments_of_room.some(a2 => a2.assignments_of_bed.some(a3 => a3.proposed)))) {
                initialStatus[room.id] = { expanded: true }
            } else {
                initialStatus[room.id] = { expanded: false }
            }
        }

        if (assignments.assignmentsWithoutBed?.some(a => a.proposed)) {
            initialStatus["Zu_belegende_Patienten"] = { expanded: true }
            initialStatus["Ausgehende_Anfragen"] = { expanded: true }
            initialStatus["Eingehende_Anfragen"] = { expanded: true }
        } else {
            initialStatus["Zu_belegende_Patienten"] = { expanded: false }
            initialStatus["Ausgehende_Anfragen"] = { expanded: false }
            initialStatus["Eingehende_Anfragen"] = { expanded: false }
        }
        setStatus(initialStatus)
    }, [rooms])


    // useEffect(() => {
    //     // Define an async function to fetch wards independently
    //     const fetchWardsAsync = async () => {
    //         await fetchAllWards(); // Assuming fetchAllWards is an async function
    //         const wards_selected = reallyAllWards.map(ward => ward.id);
    //         setSelectedWards();
    //     };

    //     // Call the async function to fetch wards at the beginning
    //     fetchWardsAsync().then(() => {
    //         if (wardsLoading || assignments.length === 0) {
    //             return; // If conditions are not met, exit early
    //         }

    //         // Proceed with the rest of the logic
    //         setEvents(getEvents(assignments, selectedWards));
    //         setRooms(getRooms(assignments, selectedWards));
    //         setWards(getWards(assignments, selectedWards, allWards));
    //     });
    // }, []);

    useEffect(() => {
        if (wardsLoading || assignments.length === 0)
            return;

        const wards_selected = reallyAllWards.map(ward => ward.id);
        console.log("wards_selected", wards_selected)
        const allWards_ids = allWards.map(w => w.id)
        setSelectedWards(wards_selected);
        setEvents(getEvents(assignments, wards_selected, allWards_ids));
        setRooms(getRooms(assignments, wards_selected, allWards_ids));
        setWards(getWards(assignments, wards_selected, reallyAllWards, allWards_ids));
    }, [wardsLoading, assignments]);

    return (
        <Box pt="4">
            {rooms?.length > 0 &&
                events?.length > 0 &&
                wards?.length > 0 && (

                    <Calendar
                        setFetchDate={setFetchDate}
                        fetchDate={fetchDate}
                        selectedDate={selectedDate}
                        scrollingLeft={scrollingLeft}
                        scrollingRight={scrollingRight}
                        scrolling={scrolling}
                        events={events}
                        rooms={rooms}
                        wards={wards}
                        status={status}
                        setStatus={setStatus}
                        proposal={true}
                        // Calendar takes the last day to be exclusive, whereas the initialDates we get from the backend
                        // take the last day to be inclusive. So, we have to add 1 to the last day here.
                        initialDates={[initialDates[0], addDays(initialDates[1], 1)]}
                        onChange={(event) => {
                            setEvents((events) => {
                                return events.map((_event) => {
                                    return _event.id === event.id
                                        ? event
                                        : _event;
                                });
                            });
                        }}
                    ></Calendar>
                )}</Box>
    )
}
export default ProposalCalendarManager
