import Patient from './Patient'
import Bed from './Bed'
import TransferRequest from './TransferRequest'

export enum AssignmentType {
  F = 'F', // Fixed
  R = 'R', // Reserved
  P = 'P', // Preliminary
  W = 'W', // Waiting
  C = 'C', // Cleaning
  D = 'D', // Discharged
  B = 'B', // Blocked
  E = 'Error',
}

export class Assignment {
  assignment_status: AssignmentType
  bed: Bed
  start: string // start time
  end: string // end time
  id: number
  proposed: boolean
  patient: Patient
  request: TransferRequest | null

  constructor(
    assignment_status: AssignmentType = AssignmentType.E,
    bed: Bed = new Bed(),
    start: string = 'undefined',
    end: string = 'undefined',
    id: number = -1,
    proposed: boolean = false,
    patient: Patient = new Patient(),
    request: TransferRequest | null = null
  ) {
    this.assignment_status = assignment_status
    this.bed = bed
    this.start = start
    this.end = end
    this.id = id
    this.proposed = proposed
    this.patient = patient
    this.request = request
  }
}
