import { FormControl } from "@chakra-ui/react"
import { Input } from "@chakra-ui/react"
import { FormLabel } from "@chakra-ui/react"
import React from "react"

type DateTimeInputProps = {
    caption: string
    name: string
    value: string
    onChange: (v: string) => void
  }

  const DateTimeInput = ({ caption, name, value, onChange }: DateTimeInputProps) => {
    return (
      <FormControl isRequired>
        <FormLabel>{caption}</FormLabel>
        <Input
          name={name}
          mb="4"
          type="datetime-local"
          isRequired={true}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        ></Input>
      </FormControl>
    )
  }
  
  export default DateTimeInput