import APICenter from '../apis/APICenter';
import { useEffect, useState } from "react";
import emitter from "../util/event";
import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import WeekOfYear from "dayjs/plugin/weekOfYear";
import Weekday from "dayjs/plugin/weekday";
import ProposalCalendarManager from "./calendarManager/ProposalCalendarManager";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    HStack,
    Button,
    Center
} from '@chakra-ui/react'
import { Message } from '../apis/Message';
import ReactDOM from "react-dom";


dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(Weekday);
dayjs.extend(WeekOfYear);
const ProposalComponent = () => {
    const apiCenter = new APICenter();

    const [showProposal, setShowProposal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs().toDate());
    const [fetchDate, setFetchDate] = useState(
        dayjs(selectedDate).format("YYYY-MM-DD")
    );
    const [assignments, setAssignments] = useState([]);
    const [scrolling, setScrolling] = useState(true);
    const [initialDates, setInitialDates] = useState([]);
    const [proposalId, setProposalId] = useState()
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);
    const [patientsToAssign, setPatientsToAssign] = useState([])


    useEffect(() => {
        const callback = async (e) => {
            const response = await apiCenter.fetchProposalAssignments(e)
            if (!(response instanceof Message)) {
                // This is needed because by default, each state update in an async function causes a re-render.
                // See https://stackoverflow.com/questions/66471772/whats-the-best-way-to-batch-state-updates-from-async-calls-in-react
                ReactDOM.unstable_batchedUpdates(() => {
                    setShowProposal(true);
                    setProposalId(e)
                    setSelectedDate(Date.parse(response.start_date))
                    setInitialDates([Date.parse(response.start_date), Date.parse(response.end_date)])
                    setAssignments({
                        assignmentsOfRooms: response.assignments_of_rooms,
                        assignmentsWithoutBed: response.assignments_without_bed,
                    });
                    setPatientsToAssign(response.patients_to_assign)
                    console.log("response.patients_to_assign", response.patients_to_assign)
                });
                emitter.emit("show_proposal_successful")
            } else {
                emitter.emit("show_proposal_failed")
            }
        };
        emitter.on("showProposal", callback);

        return () => {
            emitter.removeListener("showProposal", callback);
        };
    }, []);

    const rejectProposal = async () => {
        setRejectLoading(true)
        await apiCenter.rejectProposedPlan(proposalId)
        emitter.emit("update_room_view")
        setShowProposal(false)
        setRejectLoading(false)
    }

    const acceptProposal = async () => {
        setAcceptLoading(true)
        await apiCenter.acceptProposedPlan(proposalId)
        emitter.emit("update_room_view")
        setShowProposal(false)
        setAcceptLoading(false)
    }

    return (
        <>
            <Modal
                isOpen={showProposal}
                onClose={rejectProposal}
                isCentered
                scrollBehavior={"inside"}
            >
                <ModalOverlay
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px) hue-rotate(0deg)"
                />
                <ModalContent
                    maxW="100rem"
                >
                    <ModalHeader
                        color={"#0F4046"}
                        textAlign={"center"}
                        fontSize={"26px"}
                        fontWeight={"bold"}
                    >
                        Belegungsvorschlag für
                        <div>
                            {patientsToAssign.join(', ')}
                        </div>
                    </ModalHeader>
                    <ModalBody overflowY={"auto"}>
                        <ProposalCalendarManager
                            selectedDate={selectedDate}
                            assignments={assignments}
                            setFetchDate={setFetchDate}
                            scrolling={scrolling}
                            initialDates={initialDates}
                        />
                    </ModalBody>

                    <ModalFooter alignSelf={"center"}>
                        <Center>
                            <HStack alignItems={"center"} width={"full"} spacing={"20px"}>
                                <Center>
                                    <Button
                                        width={280}
                                        mt={4}
                                        type="submit"
                                        colorScheme="brand"
                                        disabled={false}
                                        onClick={acceptProposal}
                                        isLoading={acceptLoading}
                                        spinnerPlacement='end'
                                    >
                                        Vorschlag übernehmen
                                    </Button>
                                </Center>

                                <Center>
                                    <Button
                                        width={280}
                                        mt={4}
                                        type="submit"
                                        colorScheme="brand"
                                        disabled={false}
                                        onClick={rejectProposal}
                                        isLoading={rejectLoading}
                                        spinnerPlacement='end'
                                    >
                                        Vorschlag verwerfen
                                    </Button>
                                </Center>
                            </HStack>
                        </Center>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ProposalComponent
