import {useEffect, useState} from "react";
import emitter from "../util/event";

//import Chakra UI components
import {
    Box,
    Flex,
    HStack,
    IconButton,
} from "@chakra-ui/react";

import "../index.css";
import APICenter from "../apis/APICenter";
import AddBaseDataRoomComponent from "./AddBaseDataRoomComponent";
import AddBaseDataComponent from "./AddBaseDataComponent";
import EditBaseDataComponent from "./EditBaseDataComponent";
import { EditIcon,AddIcon } from "../theme/icons";
import { FetchWardsMessage } from "../apis/Message";
import {toast} from "react-toastify";

const AddBaseDataRowComponent = (props) => {
    const [rooms, setRooms] = useState([]);
    const apiCenter = new APICenter(); 
    const ward_id = props.ward_id;
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);
    

    const fetchRoomsAndWard = async () => {
        //fetch ward's size information in each render to decide if button needs to be disabled after updating ward's size.
        // Call fetchWardById API in apiCenter
        const msgWard = await apiCenter.fetchWardById(ward_id);
        // If it is FetchPatientMessage Class, if not authorized then Message class
        if (msgWard && msgWard instanceof FetchWardsMessage) {
            // If the request succeeded with 200 status code
            if (msgWard.isSucc) {
                const msg = await apiCenter.fetchRoomsToWard({
                    ward_id: ward_id
                });
                setRooms(msg.rooms);
                if(msg.rooms.length >= msgWard.wards.size){
                    setAddButtonDisabled(true);
                }
                else{
                    setAddButtonDisabled(false);
                }
                console.log(msg);
            }
        } else {
            // Failed msg in Message
            toast("Unable to fetch ward data by id!");
        }
    };

    useEffect(() => {
        fetchRoomsAndWard();
        emitter.on("update_settings_view", fetchRoomsAndWard);

        return () => {
            emitter.removeListener("update_settings_view", fetchRoomsAndWard);
        };

    }, []);

    const showRoomAddForm = () => {
        //emitter.emit('showAddRoom', true)
        emitter.emit(`showAddRoom/${props.ward_id}`);
    }
    const showWardEditForm = () => {
        emitter.emit(`showEditWard/${props.ward_id}`);
    }

    return (
        <>
            <HStack spacing={"0px"} paddingTop={"23px"}>
                <Box as='h4' textAlign='left' size={24} color={"#0F4046"} fontWeight={400} lineHeight={"32px"}>
                    {props.ward_name} bearbeiten
                </Box>
                <IconButton 
                    icon={<EditIcon/>}
                    onClick={showWardEditForm}
                    bg=''
                />
            </HStack>
            <Flex flexWrap="wrap" gridGap={"18px"} justify="left" paddingTop={"6px"} align={'flex-start'} flexDirection={'row'} maxHeight={"750px"}>
                {Object.keys(rooms)
                    .map(function (roomitem) {
                        return rooms[roomitem];
                    }).flat()
                    .map(function (roomitem) {
                        const roomProp = {
                            room_id: roomitem.id,
                            room_name: roomitem.name,
                            ward_id: props.ward_id,
                        };
                        return (
                            <>
                                <AddBaseDataRoomComponent
                                    key={`${roomitem.id}`}
                                    {...roomProp}/>
                            </>
                            )
                        })}
                    <HStack>
                        <IconButton
                        isDisabled={addButtonDisabled}
                        icon={<AddIcon/>}
                        onClick={showRoomAddForm}
                        bg={"white"}
                        width={"192px"}
                        height={"54px"}
                        outline={"dotted"}
                        outlineColor={"#DFDFDF"}/>
                    </HStack>

                <AddBaseDataComponent
                    wardState={props.ward_id}/>
                <EditBaseDataComponent
                    wardState={props.ward_id}/>
            </Flex>
        </>

    );
}


export default AddBaseDataRowComponent;
