import { Button } from "@chakra-ui/react";
import React from "react";

type OpaqueButtonProps = {
  opacity: number;
  onClick: () => void;
  children: React.ReactNode;
};

const OpaqueButton = (props: OpaqueButtonProps) => {
  const { opacity, onClick } = props;
  return (
    <Button
      colorScheme="brand"
      opacity={opacity}
      mx={1}
      variant="solid"
      onClick={onClick}
      width="40"
      size="sm"
    >
      {props.children}
    </Button>
  );
};

export default OpaqueButton;
