export const getReasonFailed = (reasonFailed: string): string => {
  switch (reasonFailed) {
    case "gender":
      return "Der Patient kann diesem Raum aufgrund der Geschlechtertrennung nicht zugewiesen werden.";
    case "isolation_to_others":
      return "Der Patient muss isoliert werden und kann nicht zu anderen Patienten gelegt werden.";
    case "someone_in_isolation":
      return "In diesem Raum befindet sich ein Isolationspatient. Deshalb kann kein weiterer Patient diesem Raum zugewiesen werden.";
    case "single_room":
      return "Der Patient benötigt ein Einzelzimmer und kann deshalb diesem Raum nicht zugewiesen werden.";
    case "double_room":
      return "Der Patient benötigt ein Doppelzimmer und kann deshalb diesem Raum nicht zugewiesen werden.";
    case "quick_access":
      return "Das Bett ist nicht schnell zugänglich und daher für den Patienten nicht geeignet.";
    case "occupied":
      return "Dieses Bett ist bereits belegt. Der Patient wurde nicht verlegt.";
    case "blocked":
      return "Dieses Bett ist blockiert. Der Patient wurde nicht verlegt.";
    case "same_bed":
      return "Der Patient ist diesem Bett bereits zugewiesen.";
    case "no_horizontal_move":
      return "Patientenbelgungen dürfen nur innerhalb des selben Tags verschoben werden.";
    case "redo":
      return "Die Verschiebung der Belegung konnte nicht wiederhergestellt werden.";
    case "undo":
      return "Die letzte Verschiebung der Belegung konnte nicht rückgängig gemacht werden.";
    case "no_access":
      return "Keine Nutzerberechtigung für die Station des ausgewählten Betts.";
    default:
      return "Unbekannter Fehler";
  }
};

export const getComment = (comment: string): string => {
  switch (comment) {
    case "isolation_to_others":
      return "Die Belegung wurde durchgeführt. Hinweis: Der Patient muss isoliert werden, wurde aber soeben zu anderen Patienten gelegt.";
    case "occupied":
      return "Die Belegung wurde durchgeführt. Hinweis: Das Bett war in der Zukunft belegt. Die zukünftige Belegung wurde in den Aktionsbereich verschoben.";
    case "gender":
      return "Die Belegung wurde durchgeführt. Hinweis: In diesem Raum war(en) in der zukunft Patient(en) des anderen Geschlechts geplant. Diese Patient(en) wurden in den Aktionsbereich verschoben.";
    case "someone_in_isolation":
      return "Die Belegung wurde durchgeführt. Hinweis: In diesem Raum befindet sich ein Isolationspatient.";
    case "single_room":
      return "Die Belegung wurde durchgeführt. Hinweis: Der Patient hat Anspruch auf ein Einzelzimmer. Bei diesem Raum handelt es sich nicht um ein Einzelzimmer";
    case "double_room":
      return "Die Belegung wurde durchgeführt. Hinweis: Der Patient hat Anspruch auf ein Doppelzimmer. Bei diesem Raum handelt es sich nicht um ein Doppelzimmer";
    case "redo":
      return "Die Verschiebung der Belegung wurde wiederhergestellt.";
    case "undo":
      return "Die letzte Verschiebung der Belegung wurde rückgängig gemacht.";
    default:
      return "Die Belegung wurde durchgeführt.";
  }
};
