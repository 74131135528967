import { Navigate, Outlet } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from '../context/AuthContext'

const PrivateRoute = () => {
  let { authCenter } = useContext(AuthContext)
  return authCenter.isLoginIn ? <Outlet /> : <Navigate to='/login' />
}

export default PrivateRoute
