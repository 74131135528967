import { Box, Flex, Icon, Text, useColorModeValue, Spacer, Button, HStack } from '@chakra-ui/react';
import React from 'react';
import { BedIcon } from '../theme/icons'

export default function TimelineRow(props) {
	const { index, arrLength, editAssignment, assignment, getAssignmentStatusButton, getAssignmentStatusText } = props;

    const colorActive = 'gray.700'
    const colorInactive = 'gray.400'

    function isAssignmentCurrent(a) {
        return new Date() < new Date(assignment.end)
    }

	return (
		<HStack spacing='20px'>
			<Flex direction='column' h='100%'>
				<Icon
					as={assignment.bed != null ? BedIcon : ''}
					color={isAssignmentCurrent(assignment) ? colorActive : colorInactive}
					h={'30px'}
					w={'26px'}
					pe='6px'
					zIndex='1'
					position='relative'
					right={document.documentElement.dir === 'rtl' ? '-8px' : ''}
					left={document.documentElement.dir === 'rtl' ? '' : '-8px'}
				/>
				<Box w='1px' bg={isAssignmentCurrent(assignment) ? colorActive : colorInactive} h={index === arrLength - 1 ? '0px' : '60px'} />
			</Flex>
			<Flex direction='column' justifyContent='flex-start' h='100%' w='350px' pb='15px'>
				<Text fontSize='l' color={isAssignmentCurrent(assignment) ? colorActive : colorInactive} fontWeight='bold'>
                    {assignment.bed != null ? 'Bett ' + assignment.bed.name + ' (Raum ' + assignment.bed.room.name + ', Station ' + assignment.bed.room.ward.name + ')': 'Keinem Bett zugewiesen'}
				</Text>
                <Text fontSize='m' color={isAssignmentCurrent(assignment) ? colorActive : colorInactive} fontWeight='normal'>
					{new Date(assignment.start).toLocaleString()} bis {new Date(assignment.end).toLocaleString()}
				</Text>
                <Text fontSize='m' color={isAssignmentCurrent(assignment) ? colorActive : colorInactive} fontWeight='normal'>
					Status: {getAssignmentStatusText(assignment.assignment_status)}
				</Text>
			</Flex>
            <Button
                size='sm'
                colorScheme='teal'
                onClick={() => editAssignment(assignment)}
                >
                {' '}
                Belegung bearbeiten{' '}
            </Button>
            {getAssignmentStatusButton(assignment.assignment_status, assignment)}
		</HStack>
	);
}