import React from "react"
import { Droppable } from "react-beautiful-dnd"
import { Box } from "@chakra-ui/react"
import PatientCapsuleComponent from "./PatientCapsuleComponent"
import "../index.css"
import BlockedBedCapsuleComponent from "./BlockedBedCapsuleComponent"
import emitter from "../util/event"
import BlockBedComponent from "./BlockBedComponent"
import { Assignment } from "../models/Assignment"
import Bed from "../models/Bed"
import Room from "../models/Room"

import BedLocked from "../assets/bed_locked.svg"

// Make PatientCapsuleComponent as a droppable
type BedComponentProps = {
  assignment: Assignment[]
  bed: Bed
  isDropDisabled?: boolean | undefined
  isDragEnabled: boolean
  room: Room
  renderedWardId: number
  checkIfIsolationPatientInRoom: () => boolean
  selectedDate: Date
}
const BedComponent = ({
  assignment,
  bed,
  isDropDisabled,
  isDragEnabled,
  room,
  renderedWardId,
  checkIfIsolationPatientInRoom,
  selectedDate,
}: BedComponentProps) => {
  const openBlockBedForm = () => emitter.emit(`blockBed/${bed.id}`)
  const checkIfIsolationPatientOnBed = () => {
    //Goes through all assignments on this bed
    //If one of the assigned patients must be isolated, return true

    //Then, check if one of the patients if the assignments must be isolated
    for (const a of assignment) {
      let isolationPatientOnBed = false
      if (a.patient !== null) {
        isolationPatientOnBed = a.patient.isolation_needed;
      }
      if (isolationPatientOnBed) {
        return true;
      }
    }
    return false;
  };
  if (assignment.length === 2) {
    console.log(assignment)
    assignment.sort((a: Assignment, b: Assignment) => {
      return new Date(a.end).valueOf() - new Date(b.end).valueOf();
    })
  }

  return (
    <Droppable droppableId={bed.id.toString() + ";" + renderedWardId.toString()} isDropDisabled={isDropDisabled}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          onClick={openBlockBedForm}
          borderRadius="5px"
          margin="0px"
          height="40px"
          display="flex"
          width="280px"
          backgroundImage={checkIfIsolationPatientInRoom() && !checkIfIsolationPatientOnBed() ? BedLocked : undefined}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundColor={checkIfIsolationPatientInRoom() && !checkIfIsolationPatientOnBed() ? "bedStatus.200" : "bedStatus.100"}
        >
          <BlockBedComponent bed={bed} />
          {assignment.map((a, index) =>
            a.assignment_status === "B" ? (
              <BlockedBedCapsuleComponent key={a.id} assignment={a} />
            ) : (
              <PatientCapsuleComponent
                selectedDate={selectedDate}
                key={a.id}
                index={index}
                patient={a.patient}
                assignment={a}
                assignments={assignment}
                room={room}
                bedId={a.bed.id}
                isDragEnabled={isDragEnabled}
              />
            )
          )}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )
}

export default BedComponent
