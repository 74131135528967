import React from "react";
import { Box } from '@chakra-ui/react'

export const divider = (
  <Box
    width="2px"
    height="28px"
    ml="4px"
    mr="4px"
    backgroundColor="white"
    borderRadius="5px"
    opacity={0.25}
  />
);
