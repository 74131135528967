import React from "react";
import { Dates } from "../../calendar";
import { Event, Room, RoomsStatus, Ward } from "../../calendar-types";
import { DayRoom } from "./day-room";
import Suggestion from "../../../../models/Suggestion";

interface Props {
	date: Date;
	dates: Dates;
	rooms: Room[];
	status: RoomsStatus;
	events: Event[];
	onChangeEvent: (event: Event) => void;
	wards: Ward[];
	wardsStatus: RoomsStatus;
	suggestion: Suggestion[];
	suggestionPatientId: number | null;
}

function DayEventsContent({
	date,
	dates,
	rooms,
	events,
	status,
	wardsStatus,
	onChangeEvent,
	wards,
	suggestion,
	suggestionPatientId,
}: Props) {
	const wardRooms: Room[] = [];
	wards.forEach((ward) => {
		wardRooms.push({
			id: "ward" + ward.id,
			label: ward.label,
			beds: [],
			wardId: ward.id,
		});
		if (wardsStatus[ward.id]?.expanded) {
			wardRooms.push(...ward.rooms);
		}
	});

	if (rooms.length > 0) {
		wardRooms.unshift(
			...(rooms.filter((room) => {
				return (["Zu_belegende_Patienten", "Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id));
			}))
		);

	}

	return (
		<div className="w-full">
			{wardRooms.map((room) => {
				return (
					<DayRoom
						date={date}
						dates={dates}
						room={room}
						key={room.id}
						events={events.filter(
							(event) => event.roomId === room.id
						)}
						status={room.id in status ? status[room.id] : undefined}
						onChangeEvent={onChangeEvent}
						suggestion={suggestion}
						suggestionPatientId={suggestionPatientId}
					/>
				);
			})}
		</div>
	);
}

export { DayEventsContent };
