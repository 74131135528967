import React from "react";
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from "@heroicons/react/24/outline";
import { Room, RoomStatus } from "../../calendar-types";
import { FaUserAlt, FaBed } from "react-icons/fa";
import { Icon } from "@chakra-ui/react";

interface RoomItemProps {
  room: Room;
  onClick: () => void;
  status?: RoomStatus;
}

function RoomItem({ room, onClick, status }: RoomItemProps) {

  const parseLabel = (label: string) => {
    //Cuts off the string at 15 characters
    if (label.length > 15) {
      return label.slice(0, 15 - 1)
    } else return label
  };
  return (
    <div>
      <div
        className={
          `h-6 text-xs hover:opacity-70 flex items-center gap-2 cursor-pointer w-[200px] border-r
            ${(["Zu_belegende_Patienten", "Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id)) ? "bg-[#E6F6ED] text-black pl-2" : "bg-gray-200 pl-6"}
          `
        }
        onClick={onClick}
      >
        {status?.expanded ? (
          <ArrowDownCircleIcon width={16} height={16} />
        ) : (
          <ArrowUpCircleIcon width={16} height={16} />
        )}{" "}
        {room.label}
      </div>
      {status && status.expanded && (
        <div className="flex flex-col">
          {room.beds.map((bed) => (
            <div
              key={bed.id}
              className={
                "h-6 pl-10 flex text-sm items-center gap-4 text-slate-600 bg-slate-50 w-[200px] border-r"
              }
            >
              <span>
                <Icon as={(["Zu_belegende_Patienten", "Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id)) ? FaUserAlt : FaBed} width={18} height={18} />
              </span>{" "}
              {parseLabel(bed.label)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export { RoomItem };
