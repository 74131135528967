import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APICenter from "../apis/APICenter";

import PatientDetailComponent from "../components/PatientDetailComponent";

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import Bed from '../models/Bed'
import { FetchPatientMessage, SuggestionMessage } from '../apis/Message'
import Room from '../models/Room'
import Ward from '../models/Ward'
import Patient from '../models/Patient'
import emitter from '../util/event'
import { AssignmentType } from '../models/Assignment'
import EditPatientComponent from '../components/EditPatientComponent'
import EditAssignmentComponent from '../components/EditAssignmentComponent'
import { toast } from "react-toastify";
import { VStack } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import HeadingTitle from "../components/common/HeadingTitle";
import React from "react";



// Detail Information for Patient
const PatientView = () => {
  const navigate = useNavigate();

  const apiCenter = new APICenter();
  const params = useParams();
  const patientId = params.id;

  const [patient, setPatient] = useState(new Patient())
  const [room] = useState(new Room())
  const [bed] = useState(new Bed())
  const [homeWard, sethomeWard] = useState(new Ward())
  const [assignment, setAssignment] = useState([])
  
  const [suggestion, setSuggestion] = useState([]);
  const [reason, setReason] = useState();
  const [patientExists, setPatientExists] = useState(false);
  const [hasSuggestion, setHasSuggestion] = useState(false);
  const [hasReason, setHasReason] = useState(false);
  const [suggestionIsLoading, setSuggestionIsLoading] = useState(false);
  const [patientIsLoading, setPatientIsLoading] = useState(false);


  // fetch Patient information from backend
  const fetchPatient = async () => {
    setPatientIsLoading(true);

    // Call FetchPatient API in apiCenter
    const msg = await apiCenter.fetchPatient(patientId);
    console.log(msg);

    // If it is FetchPatientMessage Class, if not authorized then Message class
    if (msg && msg instanceof FetchPatientMessage) {
      // If the request succeeded with 200 status code
      if (msg.isSucc) {
        setPatientExists(true);
        // Set Patient
        setPatient(msg.patient);

        // Set HomeWard
        sethomeWard(msg.homeWard)

        // Set Assignment
        setAssignment(msg.assignment);

        //the fourth entry in the returned array indicates wheather a suggestion exists or not
        if (msg.isSuggestionAvailable) {
          // setShowSuggestionButton(false)
          setHasSuggestion(true);
          //the fifth entry in the returned array is the suggestion. None if no suggestion exists
          setSuggestion(msg.suggestion);
        }
      } else {
        setPatientExists(false);
      }
    } else {
      // Failed msg in Message
      toast("Unable to fetch patient data!");
    }
    setPatientIsLoading(false);
  };

  const navigateToStart = () => {
    navigate(-1);
  };

  const navigateToRoomView = () => {
    navigate("/room_view");
  };

  const navigateToCalendarView = () => {
    navigate("/calendar_view");
  };

  // Just tell EditPatientComponent it should be show
  const navigateToEdit = () => {
    emitter.emit("editPatient");
  };

  const navigateToEditAssignment = (assignment) => {
    console.log(assignment.id);
    emitter.emit(`editAssignment/${assignment.id}`);
  };

  // Change the patient status to fixed
  const setFix = async (assignment) => {
    const msg = await apiCenter.setPatientStatus(assignment, AssignmentType.F);
    if (msg && !msg.isSucc) {
      toast("Setting patient status failed!");
    }
    fetchPatient();
  };

  //set New Start
  const setNewStart = async (assignment) => {
    let newStart = new Date()
    console.log(assignment.start)
    if (new Date(assignment.start) > newStart) {
      newStart = newStart.toISOString()
    } else {
      newStart = assignment.start
    }
    const editedAssignment = {
      ...assignment,
      start: newStart,
    }
    try{
    const msg = await apiCenter.setAssignment(editedAssignment); 
    if (msg && !msg.isSucc) {
      toast("Setting patient Start failed!")
    };
    fetchPatient();
    } catch (error) {
    console.error(error)
    }
  }

  // Discharge the patient
  const discharge = async (assignment) => {
    const msg = await apiCenter.setPatientStatus(assignment, AssignmentType.D);
    if (msg && !msg.isSucc) {
      toast("Setting patient status failed!");
    }
    navigateToStart();
  };

  // Calculate Suggestion for the patient
  const calculateSuggestion = async (assignment) => {
    setSuggestionIsLoading(true);
    const msg = await apiCenter.calculateSuggestion(assignment);

    if (msg && msg instanceof SuggestionMessage) {
      console.log(msg);
      if (msg.isSucc) {
        // Has suggestion
        setSuggestion(msg.suggestion);
        //the patient now has a suggestion -> display it in the patient details
        setHasSuggestion(true);
      } else {
        // Has reason
        setReason(msg.reason);
        // the patient now has a reason -> display it in the patient details
        setHasReason(true);
      }
    } else {
      toast("Suggestion calculation failed!");
    }
    setSuggestionIsLoading(false);
  };

  //When loading the component, the patient is fetched from the backend
  useEffect(() => {
    fetchPatient()
    emitter.on("update_patient_view", fetchPatient);

    return () => {
      emitter.removeListener("update_patient_view", fetchPatient);
    };
  }, []);

  return patientIsLoading ? undefined : (
    <VStack spacing={3} ml={0} mr={0} mt={10}>
      <Flex width="100%" justifyItems="bottom" alignItems="flex-end">
        <HeadingTitle>{patient.firstname + " " + patient.lastname}</HeadingTitle>
      </Flex>

    {patientExists ? (
              <>
                <PatientDetailComponent
                  key={patient.id}
                  assignment={assignment}
                  patient={patient}
                  homeWard={homeWard}
                  suggestion={suggestion}
                  reason={reason}
                  has_suggestion={hasSuggestion}
                  has_reason={hasReason}
                  setFix={setFix}
                  setNewStart={setNewStart}
                  discharge={discharge}
                  calculateSuggestion={calculateSuggestion}
                  suggestionIsLoading={suggestionIsLoading}
                  navigateToEditAssignment={navigateToEditAssignment}
                />

                <EditPatientComponent
                  assignmentState={assignment}
                  patientState={patient}
                  homeWard={homeWard}
                  fetchPatient={fetchPatient}
                />

                {assignment.map(a => (
                  
                  <EditAssignmentComponent
                    key={a.id}
                    assignmentState={a}
                    patientState={patient}
                    fetchPatient={fetchPatient}
                  />
                  
                ))}

              </>
            ) : (
              <Alert ml={10} width={450} status="error">
                <AlertIcon />
                <AlertTitle>
                  `Es gibt keinen Patienten mit der ID ${patientId}.`
                </AlertTitle>
              </Alert>
            )}

            <Wrap spacing={10} mt={5}>
              <WrapItem>
                <Button onClick={navigateToEdit}> Bearbeiten </Button>
              </WrapItem>
            </Wrap>

            <Wrap spacing={10} mt={5}>
              <WrapItem>
                <Button onClick={navigateToRoomView}> Zurück zur Patientenbelegung </Button>
              </WrapItem>
            </Wrap>

            <Wrap spacing={10} mt={5}>
              <WrapItem>
                <Button onClick={navigateToCalendarView}> Zurück zur Kalenderansicht </Button>
              </WrapItem>
            </Wrap>            
  </VStack>)
};

export default PatientView;
