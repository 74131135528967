import React from "react"
import { Text } from "@chakra-ui/react"

type LabeledTextProps = {
  label: string
  text?: string
  children?: React.ReactNode
}

const LabeledText = ({ label, text, children }: LabeledTextProps) => (
  <Text>
    {label}:{text ? <BoldText text={text}/> : children}
  </Text>
)

type BoldTextProps = {
  text: string
}

export const BoldText = ({text}: BoldTextProps) => (
  <Text ml="1" mr="1" as="span" fontWeight="bold">{text}</Text>
) 

export default LabeledText
