import React from 'react'
import { IconButton } from '@chakra-ui/react'

const Arrow = ({ isDisabled, onClick, icon, ariaLabel }) => {
  return (
    <IconButton
      className='datepicker-arrow'
      variant='ghost'
      colorScheme='cyan'
      size='xs'
      height='42px'
      isDisabled={isDisabled}
      onClick={onClick}
      aria-label={ariaLabel}
      icon={icon}
      fontSize='18px'
      color='black'
    />
  )
}

export { Arrow }
