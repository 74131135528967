import { IconButton } from '@chakra-ui/react'

const Arrow = ({ isDisabled, onClick, icon, ariaLabel }) => {
  return (
    <IconButton
      variant='ghost'
      colorScheme='cyan'
      size='sm'
      isDisabled={isDisabled}
      onClick={onClick}
      aria-label={ariaLabel}
      icon={icon}
      fontSize='14px'
      color='black'
    />
  )
}

export { Arrow }
