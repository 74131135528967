import Cookies from 'js-cookie'
import ky from 'ky'

const defaultOptions = {
  prefixUrl: `${process.env.REACT_APP_API_BASE_URL}/api`,
}

// Add access token to headers of all requests except authentication
const addTokenToHeaders = (request) => {
  if (!request.url.includes('users/')) {
    const accessToken = Cookies.get('accessToken')
      ? decodeURI(Cookies.get('accessToken'))
      : null
    if (accessToken) {
      request.headers.set('Authorization', `Bearer ${accessToken}`)
    } else {
      console.error('addTokenToHeaders: Access token is not found')
    }
  }
}

const refreshAccessToken = async (request, options, response) => {
  if (!request.url.includes('users/')) {
    if (response.status === 401) {
      const refreshToken = Cookies.get('refreshToken')
        ? decodeURI(String(Cookies.get('refreshToken')))
        : null
      if (refreshToken) {
        try {
          // request for a new access token
          const response = await apiClient
            .post('users/token/refresh/', {
              json: {
                refresh: refreshToken,
              },
            })
            .json()
          // update the access token
          Cookies.set('accessToken', encodeURI(response.access))
          // retry the request with the new access token
          request.headers.set('Authorization', `Bearer ${response.access}`)
          return apiClient(request)
        } catch (e) {
          console.error('Failed to refresh access token')
          console.log(e)
        }
      } else {
        console.error('refreshAccessToken: Refresh token is not found')
      }
    }
  }
}

const apiClient = ky.create(defaultOptions).extend({
  hooks: {
    beforeRequest: [addTokenToHeaders],
    afterResponse: [refreshAccessToken],
  },
  timeout: 10000000,
})

export default apiClient
