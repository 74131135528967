import React from "react"
import { Draggable } from "react-beautiful-dnd"
import { useNavigate } from "react-router-dom"
import {
  DismissedTodayIcon,
  DismissedTomorrowIcon,
  DismissedInTwoDaysIcon,
  DismissedInThreeDaysIcon,
  DisturbsIcon,
  InsuranceIcon,
  GenderMale,
  GenderFemale,
  ExchangeIcon,
  PendingIcon,
  AcceptedIcon,
  RejectedIcon
} from "../theme/icons"
import "../index.css"
import {
  Box,
  Divider,
  Heading,
  ListItem,
  ListItemProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react"
import { Assignment } from "../models/Assignment"
import Patient from "../models/Patient"
import Room from "../models/Room"
import Suggestion from "../models/Suggestion"
import LabeledText, { BoldText } from "./common/LabeledText"


import {
  renderDateTime,
  renderDaysUntilEnd,
  renderPatientGender,
  renderPatientStatus,
  renderPatientBirthDate
} from "./RenderFunctions";


type PatientCapsuleComponentProps = {
  index: number
  assignment: Assignment
  assignments: Assignment[]
  patient: Patient
  bedId: number
  room: Room
  suggestion?: Suggestion
  suggestionPatientId?: number
  isDragEnabled: boolean
  selectedDate: Date
  onClick: () => void
  activeSelection: boolean
  selectedPatients: Patient[]
  setSelectedPatients: (selectedPatients: Patient[]) => void
}
const PatientCapsuleComponent = ({
  index,
  assignment,
  assignments,
  patient,
  bedId,
  room,
  suggestion,
  suggestionPatientId,
  isDragEnabled,
  selectedDate,
  onClick,
  activeSelection,
  selectedPatients,
  setSelectedPatients
}: PatientCapsuleComponentProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  // ward id is id of ward where this patient capsule is rendered in. -1 if there is no ward (NotAssignedArea)
  const wardId = room === null || room === undefined
    ? assignment.request?.state === 'A'
      ? assignment.request.request_receiver_ward : -1
    : typeof room.ward === 'number' ? room.ward : room.ward.id
  const isInHomeWard = wardId === -1 || wardId === assignment.patient.home_ward

  const onPatientClick = () => {
    if (activeSelection) {
      if (selectedPatients.includes(patient)) {
        setSelectedPatients(
          selectedPatients.filter(p => p != patient)
        )
      } else {
        setSelectedPatients([...selectedPatients, patient])
      }
    } else {
      navigateToViewPatient()
    }
  }

  //render the suggestion to be displayed in the Popover
  const renderSuggestion = () => {
    const renderedSuggestion = []
    //first show the patients whose bed changes
    for (const i in suggestion) {
      if (suggestion[i]["bed_old"] !== null) {
        renderedSuggestion.push(
          <ListItem key={suggestion[i]["patient"]}>
            Patient {suggestion[i]["patient_name"]} von Bett {suggestion[i]["bed_name_old"]} (Raum{" "}
            {suggestion[i]["room_id_old"]}) nach Bett {suggestion[i]["bed_name_new"]} (Raum{" "}
            {suggestion[i]["room_id_new"]}) verschieben.
          </ListItem>
        )
      }
    }

    //then show the patient did not have a bed before
    for (const i in suggestion) {
      if (suggestion[i]["bed_old"] === null) {
        renderedSuggestion.push(
          <ListItem key={suggestion[i]["patient"]}>
            Patient {suggestion[i]["patient_name"]} auf Bett {suggestion[i]["bed_name_new"]} (Raum{" "}
            {suggestion[i]["room_id_new"]}) verschieben.
          </ListItem>
        )
      }
    }
    return renderedSuggestion
  }

  //navigate to the view patient page
  const navigate = useNavigate()
  const navigateToViewPatient = () => {
    const path = `/patient/${patient.id}`
    navigate(path)
  }

  const renderTransferRequestIcon = () => {
    if (assignment.request === null || assignment.request === undefined)
      return ""

    switch (assignment.request.state) {
      case 'P':
        return <PendingIcon
          mr="4px"
          color={"grey"}
        />
      case 'A':
        return <AcceptedIcon
          mr="4px"
          color={"green"}
        />
      case 'R':
        return <RejectedIcon
          mr="4px"
          color={"red"}
        />
      default:
        return ""
    }
  }

  const renderDismissedIcon = (date) => {
    //Naming conventions questionable
    const baseDate = selectedDate
    const baseDateEnd = new Date(baseDate.setHours(0, 0, 0, 0))
    const baseDatePlusOneEnd = new Date(baseDateEnd.getTime() + 24 * 60 * 60 * 1000)
    baseDatePlusOneEnd.setHours(0, 0, 0, 0)
    const baseDatePlusTwoEnd = new Date(baseDateEnd.getTime() + 2 * 24 * 60 * 60 * 1000)
    baseDatePlusTwoEnd.setHours(0, 0, 0, 0)
    const baseDatePlusThreeEnd = new Date(baseDateEnd.getTime() + 3 * 24 * 60 * 60 * 1000)
    baseDatePlusThreeEnd.setHours(0, 0, 0, 0)

    const dateToCompare = new Date(date)
    dateToCompare.setHours(0, 0, 0, 0)

    if (dateToCompare.getTime() === baseDateEnd.getTime()) {
      return <DismissedTodayIcon />
    } else if (dateToCompare.getTime() === baseDatePlusOneEnd.getTime()) {
      return <DismissedTomorrowIcon />
    } else if (dateToCompare.getTime() === baseDatePlusTwoEnd.getTime()) {
      return <DismissedInTwoDaysIcon />
    } else if (dateToCompare.getTime() === baseDatePlusThreeEnd.getTime()) {
      return <DismissedInThreeDaysIcon />
    } else {
      return <DismissedTodayIcon opacity={0.15} />
    }
  }

  const getProvidedProps = (provided) => {
    if (provided.draggableProps.style) {
      const { transform, ...otherStyles } = provided.draggableProps.style;
      // Disable the transform of the destination droppable, not that reliant
      if (transform && provided.draggableProps.style.transition === null) {
        const newStyle = { ...otherStyles, transform: "translate(0px,0px)" };
        provided.draggableProps.style = newStyle;
      }
    }
    return provided.draggableProps;
  }

  const divider = <Divider mr="5px" height="30px" orientation="vertical" variant="thick" />

  let patient_display_name = ""
  let exchange_button: JSX.Element | null = null;
  if (assignments && assignments.length === 2) {
    // 2 patients on 1 bed

    // Check current assignment index of assignments
    if (assignment.id == assignments[0].id) {
      patient_display_name = `${assignment.patient.firstname} ${assignment.patient.lastname}`

      // TODO: Add exchange function (Feature not implemented yet)
      exchange_button = (
        <Box>
          <ExchangeIcon fontSize="20" my="2.5" mx="0.5" color="brand.900" />
        </Box>
      );

      if (patient_display_name.length > 8) {
        patient_display_name = `${assignment.patient.firstname[0]}. ${assignment.patient.lastname[0]}.`
      }
    } else {
      patient_display_name = `${assignment.patient.firstname[0]}. ${assignment.patient.lastname[0]}.`
    }
  } else {
    // 1 patient on 1 bed
    patient_display_name = `${assignment.patient.firstname} ${assignment.patient.lastname}`
    if (patient_display_name.length > 15) {
      patient_display_name = `${assignment.patient.firstname[0]}. ${assignment.patient.lastname.slice(0, 11)}`
    }
  }

  return (
    <>
      <Popover placement="auto" autoFocus isLazy isOpen={isOpen}>
        <PopoverTrigger>
          <Box width={"100%"}>
            {
              ((isDragEnabled === undefined) || (isDragEnabled === true))
                ?
                (<Draggable
                  draggableId={assignment.id.toString() + ";" + assignment.patient.id.toString() + ";" + wardId.toString()}
                  index={index}
                  isDragDisabled={!isDragEnabled}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...getProvidedProps(provided)}
                      {...provided.dragHandleProps}
                      onClick={onClick === undefined ? onPatientClick : onClick}
                      onMouseOver={onOpen}
                      onMouseLeave={onClose}
                      borderRadius="5px"
                      height="40px"
                      display="flex"
                      alignItems="center"
                      cursor="grab"
                      mb="10px"
                      bg={
                        isInHomeWard
                          ? (patient.gender === "M"
                            ? (assignment.assignment_status === "F"
                              ? "patientStatus.200"
                              : assignment.assignment_status === "D"
                                ? "patientStatus.500"
                                : "patientStatus.100")
                            : (assignment.assignment_status === "F"
                              ? "patientStatus.400"
                              : assignment.assignment_status === "D"
                                ? "patientStatus.600"
                                : "patientStatus.300"))
                          : "#FFC442"}
                      opacity={activeSelection && !selectedPatients.includes(patient) ? 0.5 : 1}
                    >
                      {assignments && assignments.length === 2 ? (
                        <>
                          {patient.bed_place_isolation_needed ? (
                            <Box ml="5px" width="10px" height="30px" backgroundColor="sub.400" borderRadius="5px" />
                          ) : (
                            ""
                          )}

                          {patient.isolation_needed ? (
                            <Box ml="5px" width="10px" height="30px" backgroundColor="sub.500" borderRadius="5px" />
                          ) : (
                            ""
                          )}

                          <Text className="patient-heading">{patient_display_name}</Text>
                          {divider}
                          <Box color="brand.900" ml="4px" mt="-4px">
                            {patient.gender == "M" ? <GenderMale /> : <GenderFemale />}
                          </Box>
                        </>
                      ) : (
                        <>
                          {patient.bed_place_isolation_needed ? (
                            <Box ml="5px" width="10px" height="30px" backgroundColor="sub.400" borderRadius="5px" />
                          ) : (
                            ""
                          )}

                          {patient.isolation_needed ? (
                            <Box ml="5px" width="10px" height="30px" backgroundColor="sub.500" borderRadius="5px" />
                          ) : (
                            ""
                          )}

                          <Text className="patient-heading">{patient_display_name}</Text>
                          {divider}
                          <Box color="brand.900" mt="-4px">
                            {patient.gender == "M" ? <GenderMale /> : <GenderFemale />}
                            <DisturbsIcon ml="4px" opacity={patient.disturbs ? 1 : 0.15} />
                            <InsuranceIcon ml="4px" mr="4px" opacity={patient.private_insurance ? 1 : 0.15} />
                          </Box>
                          {divider}
                          {
                            <Box color="brand.900" ml="2px" mt="-4px">
                              {renderTransferRequestIcon()}
                              {renderDismissedIcon(new Date(assignment.end))}
                            </Box>
                          }
                        </>
                      )}
                    </Box>
                  )}
                </Draggable>)
                :
                (
                  <Box
                    onClick={onClick === undefined ? onPatientClick : onClick}
                    onMouseOver={onOpen}
                    onMouseLeave={onClose}
                    borderRadius="5px"
                    height="40px"
                    display="flex"
                    alignItems="center"
                    cursor="grab"
                    mb="10px"
                    bg={
                      isInHomeWard
                        ? (patient.gender === "M"
                          ? (assignment.assignment_status === "F"
                            ? "patientStatus.200"
                            : assignment.assignment_status === "D"
                              ? "patientStatus.500"
                              : "patientStatus.100")
                          : (assignment.assignment_status === "F"
                            ? "patientStatus.400"
                            : assignment.assignment_status === "D"
                              ? "patientStatus.600"
                              : "patientStatus.300"))
                        : "#FFC442"}
                    opacity={activeSelection && !selectedPatients.includes(patient) ? 0.5 : 1}
                  >
                    {assignments && assignments.length === 2 ? (
                      <>
                        {patient.bed_place_isolation_needed ? (
                          <Box ml="5px" width="10px" height="30px" backgroundColor="sub.400" borderRadius="5px" />
                        ) : (
                          ""
                        )}

                        {patient.isolation_needed ? (
                          <Box ml="5px" width="10px" height="30px" backgroundColor="sub.500" borderRadius="5px" />
                        ) : (
                          ""
                        )}

                        <Text className="patient-heading">{patient_display_name}</Text>
                        {divider}
                        <Box color="brand.900" ml="4px" mt="-4px">
                          {patient.gender == "M" ? <GenderMale /> : <GenderFemale />}
                        </Box>
                      </>
                    ) : (
                      <>
                        {patient.bed_place_isolation_needed ? (
                          <Box ml="5px" width="10px" height="30px" backgroundColor="sub.400" borderRadius="5px" />
                        ) : (
                          ""
                        )}

                        {patient.isolation_needed ? (
                          <Box ml="5px" width="10px" height="30px" backgroundColor="sub.500" borderRadius="5px" />
                        ) : (
                          ""
                        )}

                        <Text className="patient-heading">{patient_display_name}</Text>
                        {divider}
                        <Box color="brand.900" mt="-4px">
                          {patient.gender == "M" ? <GenderMale /> : <GenderFemale />}
                          <DisturbsIcon ml="4px" opacity={patient.disturbs ? 1 : 0.15} />
                          <InsuranceIcon ml="4px" mr="4px" opacity={patient.private_insurance ? 1 : 0.15} />
                        </Box>
                        {divider}
                        {
                          <Box color="brand.900" ml="2px" mt="-4px">
                            {renderTransferRequestIcon()}
                            {renderDismissedIcon(new Date(assignment.end))}
                          </Box>
                        }
                      </>
                    )}
                  </Box >

                )


            }
          </Box >
        </PopoverTrigger >

        <PopoverContent borderRadius={6}>
          <PopoverArrow />
          <PopoverHeader border="0" fontWeight="bold">
            {patient.firstname + " " + patient.lastname}
            <Divider />
          </PopoverHeader>

          <PopoverBody>
            <LabeledText label="Geburtsdatum" text={renderPatientBirthDate(patient.birth_date)} />
            <LabeledText label="Geschlecht" text={renderPatientGender(patient.gender)} />
            <LabeledText label="Belegungsstatus" text={renderPatientStatus(assignment.assignment_status)} />

            {assignment.assignment_status !== "W" && (
              <LabeledText label="Belegung">
                <BoldText text={`Bett ${bedId}`} />
                in
                <BoldText text={`Raum ${room.name}`} />
              </LabeledText>
            )}

            {assignment.end && assignment.start && (
              <>
                <LabeledText label="Geplante Entlassung" text={renderDaysUntilEnd(assignment.end)} />
                <LabeledText label="Aufnahme" text={renderDateTime(assignment.start)} />
                <LabeledText label="Entlassung" text={renderDateTime(assignment.end)} />
              </>
            )}

            {suggestionPatientId === patient.id && (
              <Box my={2} mt={2} bg="brand.500" width="full" height="full" borderRadius={6}>
                <Box m={2} display="inline-block">
                  <Heading size="sm" color="white">
                    {" "}
                    Belegungsvorschlag{" "}
                  </Heading>
                  <Box color="white">
                    {" "}
                    <UnorderedList> {renderSuggestion()} </UnorderedList>{" "}
                  </Box>
                </Box>
              </Box>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover >
      {exchange_button}
    </>
  )
}

export default PatientCapsuleComponent
