export default class WebSocketService {
	private socket: WebSocket;
	private prefixUrl: string = `${process.env.REACT_APP_WS_BASE_URL}/ws`;

	// creating new socket connection to the end point
	constructor(postfixUrl: string) {
		console.log(`${this.prefixUrl}/${postfixUrl}`);
		this.socket = new WebSocket(`${this.prefixUrl}/${postfixUrl}/`);
	}

	// to send data to the server
	public send(data: string) {
		this.socket.send(data);
	}

	public close() {
		this.socket.close();
	}

	public onError(callback: (event: Event) => void) {
		this.socket.onerror = callback;
	}

	public onMessage(callback: (data: MessageEvent) => void) {
		this.socket.onmessage = callback;
	}

	public onClose(callback: (event: CloseEvent) => void) {
		this.socket.onclose = callback;
	}

	public onOpen(callback: (event: Event) => void){     
        this.socket.onopen=callback;
    }
}
