import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isSameDay } from "date-fns";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { useNavigate } from "react-router-dom";
import * as types from "../../calendar-types";
import { Dates } from "../../calendar";
import {
  calculateElementStyle,
  getAssignmentStatusStyle,
} from "./eventItemHelpers";
import { PatientInfo } from "../patientInfo";
import APICenter from '../../../../apis/APICenter';
import emitter from "../../../../util/event";
import EditBlockBedComponent from '../../../EditBlockBedComponent';
import Suggestion from "../../../../models/Suggestion";

function EventItem({
  date,
  dates,
  event,
  len,
  index,
  suggestion,
  suggestionPatientId,
}: {
  index: number;
  date: Date;
  dates: Dates;
  event: types.Event;
  len: number;
  suggestion: Suggestion[];
  suggestionPatientId: number | null;
}) {

  // console.log("received suggestion", suggestion, "pat ID", suggestionPatientId, "received assigment", event.assignment)
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "DAY",
    item: { ev: event, date: date },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const [ready, setReady] = useState(false);
  const [previousDate, setPreviousDate] = useState(false);
  const rectRef = useRef<any>(null);
  const checkin = isSameDay(date, event.from);
  const checkout = isSameDay(date, event.to);
  const navigate = useNavigate();
  const [bgColor, setBgColor] = useState("");
  const patient = event.patient;
  const [currentDate, setCurrentDate] = useState(false);
  const [isInHomeward, setIsInHomeward] = useState(false);





  useEffect(() => {
    setReady(false);
    let el = rectRef.current;
    if (el) {
      el = calculateElementStyle(el, checkin, checkout, event);
      if (
        !(
          ((checkin && !checkout) ||
            (checkout && !checkin) ||
            (checkout && checkin)) &&
          el.style.width >= 190
        ) ||
        (!checkout && !checkin)
      ) {
        if (patient.bed_place_isolation_needed) {
          setBgColor("bg-[#000000]");
        }
        if (patient.isolation_needed) {
          setBgColor("bg-[#ffd16b]");
        }
        // setIsInHomeward(event.wardId !== "no" && event.wardId !== event.patient.home_ward);
        setIsInHomeward((event.wardId === "no" && !patient.isInHomeWard && event.roomId !== "Eingehende_Anfragen") // without a bed
          ||
          (event.wardId !== "no" && event.wardId !== event.patient.home_ward));// with a bed

        setReady(true);
      }
    }
  }, [rectRef.current?.parentElement, rectRef.current, event]);

  useEffect(() => {
    setPreviousDate(false);
    const cDate = dayjs(dates[0])
      .add(
        (rectRef.current?.parentElement?.parentElement?.parentElement
          ?.parentElement?.parentElement?.parentElement?.parentElement
          ?.scrollLeft -
          130) /
        200,
        "day"
      )
      .toDate();
    if (dayjs(cDate).isSame(date, "day")) {
      if (!currentDate) {
        setCurrentDate(true);
      }
    } else {
      if (currentDate) {
        setCurrentDate(false);
      }
    }
    if (dayjs(cDate).subtract(2, "day").isSame(event.from, "day")) {
      setPreviousDate(true);
    }
  }, [
    dates,
    rectRef.current?.parentElement?.parentElement?.parentElement?.parentElement
      ?.parentElement?.parentElement?.parentElement?.scrollLeft,
  ]);

  // dynamic classnames
  const cursorGrabbing =
    isDragging && !event.isDisabled ? "cursor-grabbing" : "";
  const cursorGrab = !isDragging && !event.isDisabled ? "cursor-grab" : "";

  const checkinRounded = checkin ? "rounded-tl-md rounded-bl-md" : "";
  const checkoutRounded = checkout ? "rounded-tr-md rounded-br-md" : "";
  const checkoutMargin = !!checkout && index === len - 1 ? "mr-1" : "";
  const checkinMargin = !!checkin && index === 0 ? "ml-1" : "";

  const checkoutHiddenOverflow = isSameDay(date, event.to)
    ? "overflow-hidden"
    : "";

  const checkinPadding = checkin ? "px-2" : "";

  const patientStyles = getAssignmentStatusStyle(event, patient);

  const apiCenter = new APICenter()
  const unblock = async () => {
    const msg = await apiCenter.deleteAssignment(event.assignmentId)
    emitter.emit("update_room_view")
  };

  const openEditBlockBedForm = async () => {
    console.log(`EditBlockBed/${event.assignmentId}`);
    emitter.emit(`EditBlockBed/${event.assignmentId}`);
  };

  const handleClick = (e: any) => {
    e.stopPropagation()

    if (patient.id != "-1") {
      if (event.roomId == "Ausgehende_Anfragen" || event.roomId === "Eingehende_Anfragen") {


        if (event.assignment === null) {
          console.log("could not read asssigment")
        }
        else {
          if (event.roomId == "Ausgehende_Anfragen") {
            // open edit transfer request in Ausgehende Anfragen

            switch (event.assignment.request.state) {
              case 'P':
                emitter.emit('showTransferRequest', 'edit', event.assignment, suggestion, suggestionPatientId, date);
                break;
              case 'A':
                emitter.emit('showTransferRequest', 'accepted', event.assignment, suggestion, suggestionPatientId, date);
                break;
              case 'R':
                emitter.emit('showTransferRequest', 'rejected', event.assignment, suggestion, suggestionPatientId, date);
                break;
              default:
                break;
            }
          }


          else if (event.roomId === "Eingehende_Anfragen") {
            // open answer transfer request in eingehende Anfragen
            emitter.emit('showTransferRequest', 'receiver', event.assignment, suggestion, suggestionPatientId, date);

          }
        }
      }
      else {
        navigate(`/patient/${event.patient.id}`)
      }
    } else {
      openEditBlockBedForm()
    }
  };
  return (

    < div
      ref={rectRef}
      key={event.id}
      onClick={(e) => {
        handleClick(e);
      }
      }
      className={`flex items-center absolute h-[90%] 
      ${ready ? (isInHomeward && event.assignment_status !== "B" ? "bg-[#FFC442]" : patientStyles) : ""} 
      ${cursorGrabbing} ${checkinRounded} ${checkoutRounded} ${checkoutMargin}
      ${checkinMargin} ${cursorGrab}`}
    >
      {ready && (
        <div
          className="h-full w-full relative"
          ref={(!event.isDisabled && !(["Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(event.roomId))) ? drag : undefined}
        >
          <div
            className={`h-full w-full absolute ${checkoutHiddenOverflow}`}
          >
            <div
              className={`rounded-md h-full
               flex ml-1 gap-1 items-center absolute whitespace-nowrap rounded-tr-md rounded-br-md
                    ${checkinPadding} ${cursorGrabbing}`}
            >
              {checkin && !previousDate && (
                <div
                  className={`rounded-md px-1 flex w-full h-full flex-col justify-center`}
                >
                  <>
                    {patient.id !== "-1" 
                      ?
                      <PatientInfo
                        event={event}
                        patient={patient}
                        bgColor={bgColor}
                      />
                      :
                      <>
                        Bett gesperrt
                        <EditBlockBedComponent
                          bedId={event.bedId}
                          assignmentId={event.assignmentId}
                          from={event.from.toISOString()}
                          to={event.to.toISOString()}
                        />
                      </>
                    }
                  </>
                </div>
              )}
            </div>
            {!checkin &&
              currentDate &&
              isSameDay(date, event.to) &&
              !isSameDay(
                dayjs(date).subtract(1, "day").toDate(),
                event.from
              ) && (
                <div
                  className={
                    "rounded-md ml-2 flex h-full flex-col justify-center w-full overflow-hidden"
                  }
                >
                  <PatientInfo
                    event={event}
                    patient={patient}
                    bgColor={bgColor}
                  />
                </div>
              )}
            {checkout &&
              !checkin &&
              !currentDate &&
              dayjs(event.to).diff(event.from, "day") > 1 && (
                <div
                  className={
                    "rounded-md px-2 flex min-w-max h-full text-xs flex-col justify-center text-right text-[#0F4046]"
                  }
                >
                  {event.nextBedName && (
                    <>

                      <div>
                        {event.nextBedName}
                        <FontAwesomeIcon
                          className="text-[#0F4046] ml-2"
                          icon={faArrowRight}
                        />


                      </div>


                    </>
                  )}
                </div>
              )}
          </div>
        </div>
      )}
    </div >
  );
}

export { EventItem };
