import { Heading } from "@chakra-ui/react";
import React from "react";

type HeadingTitleProps = {
  children: React.ReactNode;
};

const HeadingTitle = (props: HeadingTitleProps) => (
  <Heading size="2xl" color="brand.900" fontWeight="light">
    {props.children}
  </Heading>
);

export default HeadingTitle