export default class TransferRequest {
    id: number
    request_sender_ward: number
    request_receiver_ward: number
    assignment: number
    comment_sender_ward: string
    comment_receiver_ward: string
    state: string

    constructor(
        id: number = -1,
        request_sender_ward: number = -1,
        request_receiver_ward: number = -1,
        assignment: number = -1,
        comment_sender_ward: string = '',
        comment_receiver_ward: string = '',
        state: string = 'P'
    ) {
        this.request_sender_ward = request_sender_ward
        this.request_receiver_ward = request_receiver_ward
        this.assignment = assignment
        this.comment_sender_ward = comment_sender_ward
        this.comment_receiver_ward = comment_receiver_ward
        this.state = state
        this.id = id
    }
}
