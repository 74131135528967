import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//import Chakra UI components
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  HStack
} from '@chakra-ui/react'
import AuthContext from '../context/AuthContext'
import Patient from '../models/Patient'
import { Assignment } from '../models/Assignment'
import APICenter from '../apis/APICenter'
import emitter from '../util/event'

import APIUtils from '../apis/APIUtils'


// EditAssignment Modal popup in PatientView
const EditAssignmentComponent = (state) => {
  const assignmentState = state.assignmentState
  const patientState = state.patientState
  const fetchPatientState = state.fetchPatient

  const navigate = useNavigate()
  const { authCenter } = useContext(AuthContext)
  const apiCenter = new APICenter()

  const [showEditAssignment, setShowEditAssignment] = useState(false)
  const [showDeleteAssignment, setShowDeleteAssignment] = useState(false)
  const [patient, setPatient] = useState(patientState)
  const [assignment, setAssignment] = useState(assignmentState)
  const [isLoading, setIsLoading] = useState(false)


  // Listen editAssignment from PatientView
  useEffect(() => {
    const callback = () => {
      setShowEditAssignment(true)
    }
    emitter.on(`editAssignment/${assignment.id}`, callback)

    return () => {
      emitter.removeListener(`editAssignment/${assignment.id}`, callback)
    }
  }, [])

  const assignmentChange = (e) => {
    const element = e.target

    assignment[element.name] = element.value

    setAssignment(Object.assign(new Assignment(), assignment))
  }

  const safeDateProcess = (dateString) => {
    if (dateString.length > 16) {
      return dateString.slice(0, 16)
    } else {
      return dateString
    }
  }

  const navigateToPatient = () => {
    setShowEditAssignment(false)
    setShowDeleteAssignment(false)
    navigate(`/patient/${patient.id}`)
  }

  const deleteAssignment = async () => {
    const msg = await apiCenter.deleteAssignment(assignment.id)
    console.log(msg)
    if (msg && msg.isSucc) {
      fetchPatientState()
      navigateToPatient()
    } else {
      authCenter.logout()
      navigate('/')
    }
  }

  const onSubmit = async (e) => {
    //start the loading animation
    setIsLoading(true)

    e.preventDefault() //so it does not submit to a page

    const msg = await apiCenter.setAssignment(assignment)

    setIsLoading(false)
    if (msg) {
      if (msg.isSucc) {
        fetchPatientState()
        navigateToPatient()
      } else if (msg.infoText === 'Unauthorized') {
        authCenter.logout()
        navigate('/')
      } else {
        alert('Änderung nicht möglich')
      }
    }
  }

  return (
    <>
      <Modal
        size={"3xl"}
        isCentered
        isOpen={showEditAssignment}
        scrollBehavior={'inside'}
        onClose={() => {
          navigateToPatient()
        }}
        motionPreset='slideInLeft'>

        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px) hue-rotate(0deg)'
        />

        <ModalContent
          maxW={"900"}
          maxH={""}
          alignItems={"center"}
        >
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
            mt={"50"}
            mb={"50"}>
            {assignment.patient.lastname} - Belegung {assignment.id} bearbeiten
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addPatientBox">

            <form onSubmit={onSubmit}>

              <FormControl>
                <FormLabel> Start </FormLabel>
                <Input
                  name='start'
                  mb={4}
                  type='datetime-local'
                  placeholder=""
                  value={safeDateProcess(assignment.start)}
                  isRequired={true}
                  onChange={assignmentChange}
                >
                </Input>
              </FormControl>

              <FormControl>
                <FormLabel> Ende </FormLabel>
                <Input
                  name='end'
                  mb={4}
                  type='datetime-local'
                  placeholder=""
                  value={safeDateProcess(assignment.end)}
                  isRequired={true}
                  onChange={assignmentChange}
                >
                </Input>
              </FormControl>

              <FormControl>
                <FormLabel> Status </FormLabel>
                <Select
                  name='assignment_status'
                  mb={4}
                  type='datetime-local'
                  placeholder=""
                  value={safeDateProcess(assignment.assignment_status)}
                  isRequired={true}
                  onChange={assignmentChange}
                >
                  <option value='F'>Fix</option>
                  <option value='R'>Reserviert</option>
                  <option value='P'>Vorläufig</option>
                  <option value='D'>Entlassen</option>
                  <option value='W'>Nicht belegt</option>
                </Select>
              </FormControl>

              <HStack mt={"10"}>

                {isLoading ? (
                  <Button
                    width='full'
                    type='submit'
                    colorScheme='brand'
                    disabled={true}
                    isLoading
                    spinnerPlacement='end'
                    loadingText='Speichern'
                  />
                ) : (
                  <Button
                    width='full'
                    type='submit'
                    colorScheme='brand'
                    disabled={false}
                  >
                    Änderungen Speichern
                  </Button>
                )}

                <Button
                  width='full'
                  onClick={() => {
                    setShowDeleteAssignment(true)
                  }}
                  colorScheme='brand'
                >
                  Belegung Löschen
                </Button>

              </HStack>

            </form>

          </ModalBody>
          <ModalFooter />
        </ModalContent>

      </Modal>


      <Modal
        size={"3xl"}
        isCentered
        isOpen={showDeleteAssignment}
        scrollBehavior={'inside'}
        onClose={() => {
          setShowDeleteAssignment(false)
        }}
        motionPreset='slideInLeft'>

        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px) hue-rotate(0deg)'
        />

        <ModalContent
          maxW={"900"}
          maxH={""}
          alignItems={"center"}
        >
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
          >
            Belegung ({assignment.patient.lastname}{(assignment.bed !== null) ? ' - Bett ' + assignment.bed.name : " - nicht zugewiesen"})  wirklich löschen?
          </ModalHeader>

          <ModalCloseButton />

          Die Aktion kann nicht Rückgängig gemacht werden.

          <ModalBody className="addPatientBox">
            <HStack mt={"10"}>
              <Button
                colorScheme='brand'
                disabled={false}
                onClick={() => {
                  setShowDeleteAssignment(false)
                }}
              >
                Zurück
              </Button>

              <Button
                colorScheme='brand'
                disabled={false}
                onClick={deleteAssignment}
              >
                Löschen
              </Button>
            </HStack>
            <ModalFooter />
          </ModalBody>

        </ModalContent>

      </Modal>
    </>
  )
}

export default EditAssignmentComponent
