import { Box, ChakraProvider, HStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddBaseDataComponent from "../components/AddBaseDataComponent";
import AddPatientComponent from "../components/AddPatientComponent";
import TransferRequestComponent from "../components/TransferRequestComponent";
import SidebarComponent from "../components/SidebarComponent";
import { WardsProvider } from "../context/WardsContext";
import routes from "../routes";
import theme from "../theme/theme.js";
import { RealTimeUpdate } from "../util/RealTimeUpdate";

// The root application App
const DashboardView = () => {
	const routesElement = [];

	const [isCollapsed, setIsCollapsed] = useState(false);

	const [isMobile, setIsMobile] = useState(false);

	// call the socket connection after login
	RealTimeUpdate();

	// Tracking MediaQueryChanges
	useEffect(() => {
		const toggleMobile = (onOff) => {
			// console.log(`isMobile: ${isMobile} isCollapsed: ${isCollapsed} onOff: ${onOff}`)
			if (!isMobile && onOff && !isCollapsed) {
				setIsCollapsed(true);
			} else if (isMobile && !onOff && isCollapsed) {
				setIsCollapsed(false);
			}
			setIsMobile(onOff);
		};

		const mq = window.matchMedia("(max-width: 1280px)");
		toggleMobile(mq.matches);

		const handleMediaQueryChange = (event) => {
			toggleMobile(event.matches);
		};

		mq.addEventListener("change", handleMediaQueryChange);

		return () => {
			mq.removeEventListener("change", handleMediaQueryChange);
		};
	}, [isMobile, isCollapsed]);

	// Iteration over routes array
	routes.forEach((route) => {
		routesElement.push(
			<Route
				path={route.path}
				key={route.name}
				element={
					<Box
						bg={theme.colors.contentBackground}
						pl={10}
						minHeight={"100vh"}
						width={
							isCollapsed
								? theme.widthSidebarCollapsed
								: theme.widthSidebarExpanded
						}
						height="100%"
						pr={10}
					>
						{route.element}
					</Box>
				}
			/>
		);
	});

	return (
		<ChakraProvider theme={theme}>
			<WardsProvider>
				<AddBaseDataComponent />
				<AddPatientComponent />
				<TransferRequestComponent />
				<HStack spacing={0} bg={theme.colors.background}>
					<SidebarComponent
						collapsable={!isMobile}
						isCollapsed={isCollapsed}
						onCollapse={() => setIsCollapsed(!isCollapsed)}
					/>
					<Routes>{routesElement}</Routes>
					<ToastContainer />
				</HStack>
			</WardsProvider>
		</ChakraProvider>
	);
};

export default DashboardView;
