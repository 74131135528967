import React, { useEffect, useState } from "react";

import {
	Box,
	Flex,
	HStack,
	Image,
	Link,
	Stat,
	StatLabel,
	StatNumber,
	Wrap,
} from "@chakra-ui/react";

import CurrentOccupationRate from "../assets/CurrentOccupationRate.svg";
import FreeBeds from "../assets/FreeBeds.svg";
import OccupiedBeds from "../assets/OccupiedBeds.svg";
import PlannedOccupationRate from "../assets/PlannedOccupationRate.svg";
import QuickSettings from "../assets/QuickSettings.svg";
import WaitingPatients from "../assets/WaitingPatients.svg";

import Stats from "../models/Stats";

const FastFactsEdit = () => (
	<HStack>
		<Image src={QuickSettings} boxSize="4" />
		<Link fontSize="xs" color="brand.500" href="#">
			Schnelle Fakten anpassen
		</Link>
	</HStack>
);

const responsiveWidth = (isMobile:boolean) => {
	if (isMobile) return "calc((100vw - 210px) / 3)"; // 80px + 2x40px + 2x25px
	return {
		md: "calc((100vw - 210px) / 3)", // 80px + 2x40px + 2x25px
		lg: "calc((100vw - 415px) / 4)", // 260px + 2*40px + 3*25px
		xl: "calc((100vw - 425px) / 5)", // 260px + 2*40px + 4*22px
		"2xl": "calc((100vw - 440px) / 6)", // 260px + 2*40px + 5*22px
	};
};

type KeyFigureProps = {
  caption: string
  value: number
  image: string
  isPercent?: boolean
  isMobile: boolean
}

const KeyFigure = ({ caption, value, image, isPercent, isMobile }: KeyFigureProps) => (
  <Box h="20" w={responsiveWidth(isMobile)} bg="white" borderRadius="xl" display="inline-flex" p="4">
    <HStack flexDirection="row" align="center" justify="center" spacing="4">
      <Flex boxSize="12" alignItems="center" justifyContent="center" bg="brand.400" borderRadius="lg">
        <Image src={image} boxSize="6" alt={caption} />
      </Flex>
      <Stat>
        <StatLabel>{caption}</StatLabel>
        <StatNumber>
          {value}
          {isPercent && " % "}
        </StatNumber>
      </Stat>
    </HStack>
  </Box>
);

const keyFigures = (stats: Stats, isMobile: boolean) => [
  <KeyFigure key="1" caption="Aktuelle Auslastung" value={stats.current_occupancy_percent} image={CurrentOccupationRate} isPercent isMobile={isMobile} />,
  <KeyFigure key="2" caption="Geplante Auslastung" value={stats.planned_occupancy_percent} image={PlannedOccupationRate} isPercent isMobile={isMobile} />,
  <KeyFigure key="3" caption="Aktuelle Patienten" value={stats.current_occupancy_total} image={FreeBeds} isMobile={isMobile} />,
  <KeyFigure key="4" caption="Geplante Patienten" value={stats.planned_occupancy_total} image={FreeBeds} isMobile={isMobile}/>,
  <KeyFigure key="5" caption="Freie Betten" value={stats.free_beds} image={OccupiedBeds} isMobile={isMobile}/>,
  <KeyFigure key="6" caption="Belegte Betten" value={stats.occupied_beds} image={OccupiedBeds} isMobile={isMobile}/>,
  <KeyFigure key="7" caption="Zu belegende Patienten" value={stats.num_waiting_patients} image={WaitingPatients} isMobile={isMobile}/>,
];


type StatisticsSummaryComponentProps = {
  stats: Stats
}

const StatisticsSummaryComponent = ({stats}: StatisticsSummaryComponentProps) => {
  const [isMobile, setIsMobile] = useState(false);

	// Tracking MediaQueryChanges
	useEffect(() => {
		const mq = window.matchMedia("(max-width: 1280px)");
		setIsMobile(mq.matches);

    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      setIsMobile(event.matches);
    };
    
    mq.addEventListener("change", handleMediaQueryChange);

		return () => {
			mq.removeEventListener("change", handleMediaQueryChange);
		};
	}, [isMobile]);

  return (
    <Box w="100%">
      <FastFactsEdit />
      <Wrap spacing={5} pt={2} pb={2}>
        {isMobile ? keyFigures(stats, isMobile).slice(0, 3) : keyFigures(stats, isMobile)}
      </Wrap>
    </Box>
  );
};

export default StatisticsSummaryComponent;
