class Stats {
	start_date: Date;
	end_date: Date;
	current_occupancy_total: number;
	planned_occupancy_total: number;
	current_occupancy_percent: number;
	planned_occupancy_percent: number;
	free_beds: number;
	occupied_beds: number;
	num_waiting_patients: number;

	constructor(
		current_occupancy_total: number,
		current_occupancy_percent: number,
		planned_occupancy_total: number,
		planned_occupancy_percent: number,
		free_beds: number = 0,
		occupied_beds: number = 0,
		num_waiting_patients: number = 0,
		start_date: Date = new Date(),
		end_date: Date = new Date()
	) {
		this.current_occupancy_total = current_occupancy_total;
		this.planned_occupancy_total = planned_occupancy_total;
		this.current_occupancy_percent = current_occupancy_percent;
		this.planned_occupancy_percent = planned_occupancy_percent;
		this.free_beds = free_beds;
		this.occupied_beds = occupied_beds;
		this.num_waiting_patients = num_waiting_patients;
		this.start_date = start_date;
		this.end_date = end_date;
	}
}

export default Stats;
