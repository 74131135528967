class StatsRange {
	start_date: Date;
	end_date: Date;
	wards: string[];
	current_occupancy_total: Record<string, number>;
	current_occupancy_percent: Record<string, number>;
	planned_occupancy_total: Record<string, number>;
	planned_occupancy_percent: Record<string, number>;
	reserved_occupancy_total: Record<string, number>;
	free_beds: Record<string, number>;
	occupied_beds: Record<string, number>;
	num_waiting_patients: Record<string, number>;

	constructor(
        current_occupancy_total: Record<string, number> = {},
        current_occupancy_percent: Record<string, number> = {},
        planned_occupancy_total: Record<string, number> = {},
        planned_occupancy_percent: Record<string, number> = {},
        reserved_occupancy_total: Record<string, number> = {},
		free_beds: Record<string, number> = {},
		occupied_beds: Record<string, number> = {},
		num_waiting_patients: Record<string, number> = {},
		wards: string[] = [],
		start_date: Date = new Date(),
		end_date: Date = new Date()
	) {
        this.current_occupancy_total = current_occupancy_total;
        this.current_occupancy_percent = current_occupancy_percent;
        this.planned_occupancy_total = planned_occupancy_total;
        this.planned_occupancy_percent = planned_occupancy_percent;
        this.reserved_occupancy_total = reserved_occupancy_total;
		this.free_beds = free_beds;
		this.occupied_beds = occupied_beds;
		this.num_waiting_patients = num_waiting_patients;
		this.wards = wards;
		this.start_date = start_date;
		this.end_date = end_date;
	}
}

export default StatsRange;
