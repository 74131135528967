import React, {ChangeEvent, useEffect, useState, useContext} from "react";
import {useNavigate} from "react-router-dom";

import "../index.css";
import {
    Box,
    Heading,
    Flex,
    Spacer,
    Button,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Stack,
    VStack,
    FormHelperText,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Tooltip, Image,
    Checkbox, CheckboxGroup,
    Tabs, TabList, TabPanels, Tab, TabPanel, Divider,
} from "@chakra-ui/react";

import APICenter from "../apis/APICenter";
import {FetchSBFSSettingsMessage} from "../apis/Message";
import SBFSSettings from "../models/SBFSSettings";
import AuthContext from "../context/AuthContext";
import {toast} from "react-toastify";
import emitter from "../util/event";
import AddBaseDataTableComponent from "../components/AddBaseDataTableComponent";

const SettingsView = () => {
    const [sbfssettings, setSbfssettings] = useState(new SBFSSettings());

    const apiCenter = new APICenter();
    const {authCenter} = useContext(AuthContext);
    const navigate = useNavigate();

    const fetchSettings = async () => {
        const msg = await apiCenter.fetchSBFSSettings(1);
        console.log(msg);
        if (msg && msg instanceof FetchSBFSSettingsMessage) {
            // If the request succeeded with 200 status code
            if (msg.isSucc) {
                setSbfssettings(msg.sbfssettings);
                //setSliderValueRoom(msg.sbfssettings.weight_minimize_rooms);
            } else {
                toast("Unable to fetch SBF Settings!");
            }
        }
    };

    const updateSettings = async () => {
        const msg = await apiCenter.updateSBFSSettings(sbfssettings);
        console.log(msg);
        if (msg) {
            // If the request succeeded with 200 status code
            if (msg.isSucc) {
                navigate("/settings");
            } else {
                toast("Updating SBF Settings failed!");
            }
        }
    };

    //When loading the view, the settings are fetched from the backend
    useEffect(() => {
        fetchSettings();
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault(); //so it does not submit to a page
        updateSettings();
    };

    const settingsChange = (value, name) => {
        //const element = e.target;
        //console.log(value);
        //sbfssettings[element.name] = element.value;
        sbfssettings[name] = value;
        setSbfssettings(Object.assign(new SBFSSettings(), sbfssettings));
    };


    const SettingsSliders = (props) => {
        const [value, setValue] = React.useState(props.settingsvalue);
        const [name, setName] = React.useState(props.settingsname);
        const maxValue = props.maxValue;
        return (
            //If maxValue is not null, set slider range to 1-100 and step to 1
            maxValue ? (
                    <Slider
                        id="slider"
                        name={name}
                        //defaultValue={`${value}`}
                        defaultValue={value}
                        min={0}
                        max={100}
                        step={1}
                        colorScheme="teal"
                        onChange={(v, n) => settingsChange(v, name)}
                        //onChangeEnd={(v) => setSliderValueRoom(v)}
                        //onChangeEnd={settingsChange}
                    >
                        <SliderMark value={0} mt={'1'} ml={'-2.5'} fontSize={'sm'}>
                            0
                        </SliderMark>
                        <SliderMark value={100} mt={'1'} ml={'-2.5'} fontSize={'sm'}>
                            100
                        </SliderMark>
                        <SliderTrack>
                            <SliderFilledTrack/>
                        </SliderTrack>
                        <Tooltip
                            hasArrow
                            bg='teal.500'
                            color='white'
                            placement='top'
                            //isOpen={showTooltip}
                            //label={`${value}`}
                            label={value}
                        >
                            <SliderThumb/>
                        </Tooltip>
                    </Slider>)
                :
                (<Slider
                    id="slider"
                    name={name}
                    //defaultValue={`${value}`}
                    defaultValue={value}
                    min={0}
                    max={1}
                    step={0.01}
                    colorScheme="teal"
                    onChange={(v, n) => settingsChange(v, name)}
                    //onChangeEnd={(v) => setSliderValueRoom(v)}
                    //onChangeEnd={settingsChange}
                >
                    <SliderMark value={0} mt={'1'} ml={'-2.5'} fontSize={'sm'}>
                        {value}
                    </SliderMark>
                    <SliderMark value={1} mt={'1'} ml={'-2.5'} fontSize={'sm'}>
                        1
                    </SliderMark>
                    <SliderTrack>
                        <SliderFilledTrack/>
                    </SliderTrack>
                    <Tooltip
                        hasArrow
                        bg='teal.500'
                        color='white'
                        placement='top'
                        //isOpen={showTooltip}
                        //label={`${value}`}
                        label={value}
                    >
                        <SliderThumb/>
                    </Tooltip>
                </Slider>)
        )
    };

    const [showTooltip, setShowTooltip] = React.useState(true);


    return (
        <>
            <Flex justifyItems={"bottom"} alignItems={"flex-end"}>
                <Heading size="2xl" mt={10} color="#0F4046" fontWeight={"light"}>
                    Einstellungen
                </Heading>
            </Flex>
            <Tabs variant={'soft-rounded'} colorScheme={'gray'} paddingTop={"40px"} spacing={12} direction="column">
                <TabList border={"10px"}>
                    <Tab  borderRadius={6}>Stationen und Räume bearbeiten</Tab>
                    <Divider orientation='vertical'/>
                    <Tab  borderRadius={6} ml={'20px'}>Algorithmus Einstellungen</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <AddBaseDataTableComponent/>
                    </TabPanel>
                    <TabPanel>
                        <Box my={"10"}>
                            <form onSubmit={onSubmit}>
                                <HStack spacing={30} alignItems="top">
                                    <Box alignContent={"top"}>
                                        <FormControl isRequired>
                                            <FormLabel>Gewicht: Räume minimieren</FormLabel>
                                            <FormHelperText>
                                                [weight_minimize_rooms] Die Gewichtung für das Ziel
                                                möglichst wenig Räume aufzumachen. Bei großen Werten ist es
                                                möglich, dass keine Belegung gefunden wird. Kommazahl.
                                                Default: 0,33.
                                            </FormHelperText>
                                            <br/>
                                            <SettingsSliders
                                                settingsname="weight_minimize_rooms"
                                                settingsvalue={sbfssettings.weight_minimize_rooms}
                                            />
                                        </FormControl>

                                        <br/>

                                        <FormControl isRequired>
                                            <FormLabel>Gewicht: Störer nicht mischen</FormLabel>
                                            <FormHelperText>
                                                [weight_no_disturbing] Die Gewichtung für das Ziel stärene
                                                Patienten möglichst nicht mit anderen Patienten in einen
                                                Raum zu legen. Bei großen Werten ist es möglich, dass keine
                                                Belegung gefunden wird. Kommazahl. Default: 0,33.
                                            </FormHelperText>
                                            <br/>
                                            <SettingsSliders
                                                settingsname="weight_no_disturbing"
                                                settingsvalue={sbfssettings.weight_no_disturbing}
                                            />
                                        </FormControl>

                                        <br/>

                                        <FormControl isRequired>
                                            <FormLabel>Gewicht: Altersgruppen</FormLabel>
                                            <FormHelperText>
                                                [weight_group_age] Die Gewichtung für das Ziel Patienten
                                                ähnlicher Altersgruppen zusammen in einen Raum zu legen. Bei
                                                großen Werten ist es möglich, dass keine Belegung gefunden
                                                wird. Kommazahl. Default: 0,44.
                                            </FormHelperText>
                                            <br/>
                                            <SettingsSliders
                                                settingsname="weight_group_age"
                                                settingsvalue={sbfssettings.weight_group_age}
                                            />
                                        </FormControl>

                                        <br/>
                                        <FormControl isRequired>
                                            <FormLabel>
                                                Konfiguration: Wichtigkeit von Zukunftsplänen
                                            </FormLabel>
                                            <FormHelperText>
                                                [config_plan_ahead] Belegungspläne in der Zukunft sind
                                                normalerweise weniger wichtig als gegenwärtige
                                                Belegungspläne. Je kleiner diese Zahl, desto geringer die
                                                Wichtigkeit von zukünftigern Plänen, bzw. desto größer der
                                                Fokus auf die Belegungen näher an der Gegenwart. Sollte
                                                kleiner 1 sein. Kommazahl. Default: 0,8.
                                            </FormHelperText>
                                            <br/>
                                            <SettingsSliders
                                                settingsname="config_plan_ahead"
                                                settingsvalue={sbfssettings.config_plan_ahead}
                                            />
                                        </FormControl>

                                        <br/>

                                        <FormControl isRequired>
                                            <FormLabel>
                                                Konfiguration: Schwellwert für mögliche Belegungen
                                            </FormLabel>
                                            <FormHelperText>
                                                [config_match_threshold] Schwellwert für den Algorithmus
                                                internen Score, ab dem eine potentielle Belegung der Liste
                                                möglicher Belegungen hinzugefügt wird. Sollte zwischen 0 und
                                                1 liegen. Kommazahl. Default: 0,8.
                                            </FormHelperText>
                                            <br/>
                                            <SettingsSliders
                                                settingsname="config_match_threshold"
                                                settingsvalue={sbfssettings.config_match_threshold}
                                            />
                                        </FormControl>

                                        <br/>

                                        <FormControl isRequired>
                                            <FormLabel>
                                                Konfiguration: Maximale Anzahl neuer Pläne pro Tag{" "}
                                            </FormLabel>
                                            <FormHelperText>
                                                [config_max_num_new_plans] Die Anzahl der verschiedenen
                                                möglichen Belegungen, die der SBFS Algorithmus für jeden Tag
                                                berechnet. Je kleiner, desto kürzer die Laufzeit. Bei sehr
                                                kleinen Zahlen ist es möglich, dass keine Lösung gefunden
                                                wird. Ganzzahlig. Default: 25.
                                            </FormHelperText>
                                            <br/>
                                            <SettingsSliders
                                                settingsname="config_max_num_new_plans"
                                                settingsvalue={sbfssettings.config_max_num_new_plans}
                                                maxValue={100}
                                            />
                                        </FormControl>
                                    </Box>
                                </HStack>
                                <br/><br/>

                                <Button type="submit" colorScheme="brand" disabled={false}>
                                    Änderungen Speichern
                                </Button>
                            </form>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default SettingsView;
