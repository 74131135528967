export default class Patient {
  lastname: string
  firstname: string
  isolation_needed: boolean
  bed_place_isolation_needed: boolean
  private_insurance: boolean
  single_room_needed: boolean
  birth_date: string
  gender: string
  disturbs: boolean
  monitor_bed_needed: boolean
  quick_access_needed: boolean
  double_room_needed: boolean
  id: number
  home_ward: number
  additional_patient_information: string

  constructor(
    lastname = '',
    firstname = '',
    isolation_needed = false,
    bed_place_isolation_needed = false,
    private_insurance = false,
    single_room_needed = false,
    birth_date = '',
    gender = '',
    disturbs = false,
    monitor_bed_needed = false,
    quick_access_needed = false,
    double_room_needed = false,
    id = -1,
    home_ward = -1,
    additional_patient_information = ''

  ) {
    this.lastname = lastname
    this.firstname = firstname
    this.birth_date = birth_date
    this.isolation_needed = isolation_needed
    this.bed_place_isolation_needed = bed_place_isolation_needed
    this.private_insurance = private_insurance
    this.single_room_needed = single_room_needed
    this.gender = gender
    this.disturbs = disturbs
    this.double_room_needed = double_room_needed
    this.monitor_bed_needed = monitor_bed_needed
    this.quick_access_needed = quick_access_needed
    this.id = id
    this.home_ward = home_ward
    this.additional_patient_information = additional_patient_information
  }
}
