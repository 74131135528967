export default class Ward {
    id: number
    name: string
    size: number // The number of rooms in the ward
  
    constructor(
      id = -1,
      name = 'undefined',
      size = -1,
    ) {
      this.id = id
      this.name = name
      this.size = size
    }
  }
  