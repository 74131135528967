import { useEffect, useState } from "react"

//import Chakra UI components
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  HStack,
} from "@chakra-ui/react"
import APICenter from "../apis/APICenter"
import emitter from "../util/event"
import React from "react"
import DateTimeInput from "./common/DateTimeInput"
import DialogHeader from "./common/DialogHeader"

type EditBlockBedComponentProps = {
  bedId: number
  assignmentId: number
  from: string
  to: string
}

// EditBlockBed Modal popup in RoomView and CalendarView
const EditBlockBedComponent = ({ bedId, assignmentId, from, to }: EditBlockBedComponentProps) => {
  const [showEditBlockBed, setShowEditBlockBed] = useState(false)
  const [start, setStart] = useState("")
  const [end, setEnd] = useState("")

  const apiCenter = new APICenter()

  const dateToString = (date) => {
    const dateString = date.toString()
    if (dateString.length > 16) {
      return dateString.slice(0, 16)
    } else {
      return dateString
    }
  }

  useEffect(() => {
    const callback = () => {
      setShowEditBlockBed(true)
    }

    setStart(dateToString(from))
    setEnd(dateToString(to))

    emitter.on(`EditBlockBed/${assignmentId}`, callback)
    return () => {
      emitter.removeListener(`EditBlockBed/${assignmentId}`, callback)
    }
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault() //so it does not submit to a page

    if (end < start) {
      alert("Start muss vor Ende liegen")
      return
    }

    const msg = await apiCenter.editBlockAssignment(assignmentId, start, end)

    if (msg.isSucc) {
      emitter.emit("update_room_view")
      setShowEditBlockBed(false)
    } else {
      alert("Die Blockierung ist leider nicht möglich.")
    }
  }

  const unblock = async () => {
    await apiCenter.deleteAssignment(assignmentId)
    emitter.emit("update_room_view")
    setShowEditBlockBed(false)
  }

  return (
    <Modal
      size="3xl"
      isCentered
      isOpen={showEditBlockBed}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      onClose={() => setShowEditBlockBed(false)}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(0deg)" />
      <ModalContent maxW="900" maxH="" alignItems="center">
        <DialogHeader>Bett {bedId} Block bearbeiten</DialogHeader>
        <ModalCloseButton />
        <ModalBody className="addPatientBox">
          <Box as="form" my="10" onSubmit={onSubmit}>
            <HStack spacing="30" alignItems="center" justifyContent="center">
              <Box w="350" alignContent="top" display="flex" flexDirection="column">
                <DateTimeInput caption="Start" name="start" value={start} onChange={(v) => setStart(v)} />
                <DateTimeInput caption="Ende" name="end" value={end} onChange={(v) => setEnd(v)} />
              </Box>
            </HStack>
            <HStack spacing="30">
              <Button width="full" type="submit" colorScheme="brand">
                Änderungen Speichern
              </Button>
              <Button width="full" colorScheme="brand" onClick={unblock}>
                Sperrung aufheben
              </Button>
            </HStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EditBlockBedComponent
