import Room from './Room'

export default class Bed {
  active: boolean
  id: number
  name: string
  quick_access_possible: boolean
  room: Room
  type: string

  constructor(
    active = false,
    id = -1,
    name = 'undefined',
    quick_access_possible = false,
    room = new Room(),
    type = 'undefined'
  ) {
    this.active = active
    this.id = id
    this.name = name
    this.quick_access_possible = quick_access_possible
    this.room = room
    this.type = type
  }
}
