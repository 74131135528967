import React, {
	FC,
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useState,
	useMemo
} from "react";
import APIUtils from "../apis/APIUtils";
import Ward from "../models/Ward";

export type WardsContextType = {
	wards: Ward[];
	allWards: Ward[];
	wardsByID: Map<number, Ward>;
	isLoading: boolean;
};

const WardsContext = createContext<WardsContextType | null>(null);

export const useWards = () => {
	const ctx = useContext(WardsContext);
	if (ctx === null)
		throw new Error("Wards Context has not been initialized yet.");

	return ctx;
};

export const WardsProvider: FC<PropsWithChildren> = ({ children }) => {
	const { getData, getAllWards } = APIUtils();
	const [wards, setWards] = useState<Ward[]>([]);
	const [allWards, setAllWards] = useState<Ward[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const wardsByID = useMemo(() => {
		return wards.reduce((acc, ward) => {
			return acc.set(ward.id, ward);
		}, new Map<number, Ward>())
	}, [wards]);

	useEffect(() => {
		const getWards = async () => {
			const response = await getData("wards");
			if (response && response.isSuccessful) {
				setWards(response.data);
				//setIsLoading(false);
			}
			const response2 = await getAllWards();
			if (response2 && response2.isSuccessful) {
				setAllWards(response2.wards);
				setIsLoading(false);
			}
		};

		getWards();
	}, []);

	const value: WardsContextType = {
		wards,
		allWards,
		wardsByID,
		isLoading
	};

	return (
		<WardsContext.Provider value={value}>{children}</WardsContext.Provider>
	);
};
