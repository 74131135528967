import { format } from "date-fns";
import { Event, Patient } from "../../calendar-types";

export function getAssignmentStatusStyle(
  event: Event,
  patient: Patient
): string {
  const assignmentStatus = event.assignment_status;
  const gender = patient.gender;
  if (assignmentStatus === "B") {
    return "bg-[#FF5143]";
  }
  if (event.assignment_proposed) {
    const bgCircles = "bg-repeat-rounded bg-circles-size bg-circles-background bg-opacity-30"
    if (gender === "M") {
      return "bg-circles-m " + bgCircles
    } else if (gender === "F") {
      return "bg-circles-f " + bgCircles
    }
  }
  if (
    assignmentStatus === "R" ||
    assignmentStatus === "P" ||
    ["Zu_belegende_Patienten", "Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(event.roomId)
  ) {
    if (gender === "M") {
      return "bg-[#A6F2FC]";
    } else if (gender === "F") {
      return "bg-[#FFD3E5]";
    }
  } else if (
    assignmentStatus === "F"
  ) {
    if (gender === "M") {
      return "bg-[#37b7c7]";
    } else if (gender === "F") {
      return "bg-[#FF84B6]";
    }
  } else if (
    assignmentStatus === "D"
  ) {
    if (gender === "M") {
      return "bg-hatched-m"
    } else if (gender === "F") {
      return "bg-hatched-f"
    }
  }
  return "";
}

export function calculateElementStyle(
  el: HTMLDivElement,
  checkin: boolean,
  checkout: boolean,
  event: Event
) {
  if (checkout && !checkin) {
    el.style.width = `${((parseInt(format(event.to, "HH")) +
      parseInt(format(event.to, "MM")) / 60) /
      24) *
      200
      }px`;
  } else if (checkin && !checkout) {
    el.style.width = `${((24 -
      parseInt(format(event.from, "HH")) -
      parseInt(format(event.from, "MM")) / 60) /
      24) *
      200
      }px`;
    el.style.right = `${0}px`;
  } else if (checkin && checkout) {
    el.style.width = `${((parseInt(format(event.to, "HH")) +
      parseInt(format(event.to, "MM")) / 60 -
      parseInt(format(event.from, "HH")) -
      parseInt(format(event.from, "MM")) / 60) /
      24) *
      200
      }px`;
    el.style.left = `${200 -
      ((24 -
        parseInt(format(event.from, "HH")) +
        parseInt(format(event.from, "MM")) / 60) /
        24) *
      200
      }px`;
    el.style.overflow = "hidden";
  } else {
    el.style.width = "200px";
  }
  return el;
}
