import {useEffect, useState} from "react";
import emitter from "../util/event";

//import Chakra UI components
import {
    IconButton,
    Heading,
    HStack, Stack,
    Card, CardHeader, CardBody, CardFooter,
} from "@chakra-ui/react";

import "../index.css";
import APICenter from "../apis/APICenter";
import AddBaseDataBedComponent from "./AddBaseDataBedComponent";
import EditBaseDataComponent from "./EditBaseDataComponent";
import AddBaseDataComponent from "./AddBaseDataComponent";
import {AddIcon, EditIcon} from "../theme/icons";
import {FetchRoomsMessage} from "../apis/Message";
import {toast} from "react-toastify";


const AddBaseDataRoomComponent = (props) => {
    const [beds, setBeds] = useState([]);
    const apiCenter = new APICenter();
    const room_id = props.room_id;
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);


    const fetchBedsAndRoom = async () => {
        //fetch room's size information in each render to decide if button needs to be disabled after updating size.
        const msgRoom = await apiCenter.fetchRoomById(room_id);
        if (msgRoom && msgRoom instanceof FetchRoomsMessage) {
            // If the request not succeeded with 200 status code
            if (msgRoom.isSucc) {
                const msg = await apiCenter.fetchBedsToRoom({
                    room_id: room_id
                });
                setBeds(msg.beds);
                console.log(msg);
                if (msg.beds.length >= msgRoom.rooms.size) {
                    setAddButtonDisabled(true);
                } else {
                    setAddButtonDisabled(false);
                }
            } else {
                // Failed msg in Message
                toast("Unable to fetch room data by id!");
            }
        }
    };

    useEffect(() => {
        fetchBedsAndRoom();
        emitter.on("update_settings_view", fetchBedsAndRoom);

        return () => {
            emitter.removeListener("update_settings_view", fetchBedsAndRoom);
        };
    }, []);

    const showBedAddForm = () => {
        emitter.emit(`showAddBed/${props.room_id}`)
    }
    const showRoomEditForm = () => {
        emitter.emit(`showEditRoom/${props.room_id}`)
    }
    return (
        <>
            <Card bg={'white'} width={"192px"} align={"center"}>
                <CardHeader paddingLeft={"5px"} paddingTop={"0px"} wordBreak={"break-word"}>
                    <HStack spacing={"0px"}>
                        <Heading size={16} color={'#37B7C7'} fontWeight={700}>
                            Raum {props.room_name}
                        </Heading>
                        <IconButton
                            icon={<EditIcon/>}
                            onClick={showRoomEditForm}
                            bg={"white"}
                            size={"sm"}
                        />
                    </HStack>
                </CardHeader>
                <CardBody paddingTop={"0px"} alignItems={"center"} paddingBottom={"10px"}>
                    <Stack direction='column' spacing={"8px"} alignItems={"center"} width={"176px"}>
                        {Object.keys(beds)
                            .map(function (beditem) {
                                return beds[beditem];
                            }).flat()
                            .map(function (beditem) {
                                const bedProp = {
                                    bed_id: beditem.id,
                                    bed_name: beditem.name,
                                    bed_type: beditem.type,
                                    bed_active: beditem.active,
                                    bed_quick_access_possible: beditem.quick_access_possible,
                                    room_id: props.room_id,
                                    room_name: props.room_name,
                                    room_size: props.room_size,
                                };
                                return (
                                    <AddBaseDataBedComponent
                                        key={`${beditem.id}`}
                                        {...bedProp}/>
                                )
                            })}
                    </Stack>
                </CardBody>
                <CardFooter paddingTop={"0px"}>
                    <HStack alignItems={"center"} width={"176px"} paddingTop={"0px"}>
                        <IconButton
                            isDisabled={addButtonDisabled}
                            icon={<AddIcon/>}
                            onClick={showBedAddForm}
                            bg={"white"}
                            width={"176px"}
                            height={"30px"}
                            outline={"dotted"}
                            outlineColor={"#DFDFDF"}/>
                    </HStack>
                </CardFooter>
                <AddBaseDataComponent
                    roomState={props.room_id}/>
                <EditBaseDataComponent
                    roomState={props.room_id}/>
            </Card>
        </>
    );
}

export default AddBaseDataRoomComponent;
