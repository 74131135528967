import '../index.css'
import React from 'react'
import InfoCardComponent from './CardComponent'
import * as FaIcons from 'react-icons/fa'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  SlideFade,
  Text,
  VStack,
  Wrap,
  Flex,
  Textarea,
} from '@chakra-ui/react'
import { AssignmentType } from '../models/Assignment'
import TimelineRow from "./TimelineRow"


// The patient detail component in PatientView
const PatientDetailComponent = ({
  assignment,
  patient,
  homeWard,
  suggestion,
  reason,
  has_suggestion,
  has_reason,
  setFix,
  setNewStart,
  discharge,
  calculateSuggestion,
  suggestionIsLoading,
  navigateToEditAssignment,
}) => {

  const getGenderText = (gender) => {
    let _gender = 'Keine Daten'
    if (gender) {
      if (gender === 'M') {
        _gender = 'Männlich'
      }
      if (gender === 'F') {
        _gender = 'Weiblich'
      }
      if (gender === 'X') {
        _gender = 'Divers'
      }
    }

    return <Text> {_gender} </Text>
  }

  const handleFixAndNewStart = async (assignment) => {
    await setNewStart(assignment)
    await setFix(assignment)
  }

  const getBirthText = (birth) => {
    let _birth = 'Keine Daten'
    if (birth) {
      _birth = new Date(patient.birth_date).toLocaleDateString()
    }

    return <Text> {_birth} </Text>
  }

  const getInsuranceTypeText = (insuranceType) => {
    let _insuranceType = 'Keine Daten'

    if (insuranceType !== undefined) {
      if (insuranceType) {
        _insuranceType = 'Privatversichert'
      } else {
        _insuranceType = 'Gesetzlich versichert'
      }
    }

    return <Text> {_insuranceType} </Text>
  }

  const getArrivalDateText = (start) => {
    let _arrivalDate = 'Keine Daten'

    if (start) {
      _arrivalDate = new Date(assignment.start).toLocaleDateString()
    }

    return <Text> {_arrivalDate} </Text>
  }

  const getDepartureDateText = (end) => {
    let _departureDate = 'Keine Daten'

    if (end) {
      const date = new Date(end).toLocaleDateString()
      const dateDescriptor = Math.ceil(
        (new Date(end).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)) /
          (1000 * 60 * 60 * 24)
      )

      if (dateDescriptor === 0) {
        _departureDate = `${date} (Heute)`
      } else if (dateDescriptor >= 1) {
        _departureDate = `${date} (In ${dateDescriptor} Tag${
          dateDescriptor === 1 ? '' : 'en'
        })`
      } else if (dateDescriptor <= -1) {
        _departureDate = `${date} (Vor ${-dateDescriptor} Tag${
          dateDescriptor === -1 ? '' : 'en'
        })`
      }
    }

    return <Text> {_departureDate} </Text>
  }

  const getDurationText = (start, end) => {
    let _duration = 'Keine Daten'

    if (start && end) {
      const duration = Math.round(
        (new Date(end).setHours(0, 0, 0, 0) -
          new Date(start).setHours(0, 0, 0, 0)) /
          (1000 * 60 * 60 * 24)
      )

      if (duration === 1) {
        _duration = '1 Tag'
      } else if (duration < 1) {
        _duration = 'unter einem Tag'
      } else {
        _duration = `${duration} Tage`
      }
    }

    return <Text> {_duration} </Text>
  }

  const getAssignmentStatusText = (assignment_status) => {
    let _assignmentStatus = 'Nicht Belegt'

    if (assignment_status) {
      if (assignment_status === AssignmentType.F) {
        _assignmentStatus = 'Fix'
      } else if (assignment_status === AssignmentType.R) {
        _assignmentStatus = 'Reserviert'
      } else if (assignment_status === AssignmentType.P) {
        _assignmentStatus = 'Vorläufig'
      } else if (assignment_status === AssignmentType.D) {
        _assignmentStatus = 'Entlassen'
      }
    }

    return <> {_assignmentStatus} </>
  }

  const getAssignmentStatusButton = (assignment_status, assignment) => {
    let _assignmentStatusButton = ''

    if (new Date() < new Date(assignment.end)) {

      if (assignment_status) {
        //only show the release button, if the patient is "fix/F"
        if (assignment_status === AssignmentType.F) {
          _assignmentStatusButton = (
            <Button
              size='sm'
              colorScheme='teal'
              rightIcon={<FaIcons.FaRegArrowAltCircleUp />}
              onClick={() => discharge(assignment)}
            >
              {' '}
              Patient entlassen{' '}
            </Button>
          )

          //if the patient is assigned to a bed but not "fix/F" already: show the button to set them to fix
          //if the patient is not assigned ("new/N"): do not show the button
          // -> only show the button if the patient is "reserved/R" or "preliminary/P"
        } else if (
          assignment_status === AssignmentType.R ||
          assignment_status === AssignmentType.P
        ) {
          _assignmentStatusButton = (
            <Button
              size='sm'
              colorScheme='teal'
              rightIcon={<FaIcons.FaRegArrowAltCircleDown />}
              onClick={() => handleFixAndNewStart(assignment)}
            >
              {' '}
              Patient ist angekommen{' '}
            </Button>
          )

          //only show the suggestion button, if the patient is "not assigned/new/N"
          //temporarily disabled
        } else if (assignment_status === AssignmentType.W && false) {
          if (suggestionIsLoading) {
            _assignmentStatusButton = (
              <Button
                size='sm'
                width='200px'
                colorScheme='teal'
                disabled={true}
                isLoading
                spinnerPlacement='end'
                loadingText='Berechnen'
              />
            )
          } else {
            // if a suggestion is found, the button to calculate a suggestion is hidden
            // if no suggestion is found, the button to calculate a suggestion is hidden - until the page is refreshed
            if (!has_suggestion && !has_reason) {
              _assignmentStatusButton = (
                <Button
                  size='sm'
                  width='200px'
                  colorScheme='teal'
                  rightIcon={<FaIcons.FaBrain />}
                  onClick={() => calculateSuggestion(assignment)}
                  disabled={false}
                >
                  {' '}
                  Belegungsvorschlag{' '}
                </Button>
              )
            }
          }
        }
      }
    }

    return _assignmentStatusButton
  }

  const editAssignment = (assignment) => {
    navigateToEditAssignment(assignment)
  }

  const getBedText = (bed) => {
    let _bed = 'Kein Bett zugewiesen'

    if (bed.id !== -1) {
      _bed = `Bett ${bed.name} (Typ: ${bed.type})`
    }

    return <Text> {_bed} </Text>
  }

  const getRoomText = (room) => {
    let _room = 'Kein Raum zugewiesen'

    if (room.id !== -1) {
      _room = `Raum ${room.name} (Größe: ${room.size} Betten)`
    }

    return <Text> {_room} </Text>
  }

  const getWardText = (ward) => {
    let _ward = 'Kein Station vorgesehen'

    if (ward.id !== -1) {
      _ward = `${ward.name}`
    }

    return <Text> {_ward} </Text>
  }

  const getRoomSpecialRequestText = (request) => {
    let _request = 'Keine Daten'
    if (request !== undefined) {
      if (request) {
        _request = 'Wird benötigt'
      } else {
        _request = 'Nicht benötigt'
      }
    }

    return <Text> {_request} </Text>
  }

  const getJaNeinText = (text) => {
    let _text
    if (text) {
      _text = 'Ja'
    } else {
      _text = 'Nein'
    }

    return <Text> {_text} </Text>
  }

  const getIsolationIcon = (isolation) => {
    if (isolation) {
      return <FaIcons.FaVirus size='35' color='#FFD16B' />
    }
    return ''
  }

  const getQuickAccessIcon = (quickAccess) => {
    if (quickAccess) {
      return <FaIcons.FaRunning size='35' color='skyblue' />
    }
    return ''
  }

  //preparing the suggestion to be rendered
  //if there is no suggestion, the variables stay empty
  let renderedSuggestion = []
  let renderedSuggestionHeading = ''

  //first show the patients whose bed changes
  for (let i in suggestion) {
    if (suggestion[i]['bed_old'] !== null) {
      renderedSuggestion.push(
        <li key={suggestion[i]['patient']}>
          Patient {suggestion[i]['patient_name']} von Bett{' '}
          {suggestion[i]['bed_name_old']} (Raum {suggestion[i]['room_id_old']})
          nach Bett {suggestion[i]['bed_name_new']} (Raum{' '}
          {suggestion[i]['room_id_new']}) verschieben.
        </li>
      )
    }
  }

  //then show the patient did not have a bed before
  for (let i in suggestion) {
    if (suggestion[i]['bed_old'] === null) {
      renderedSuggestion.push(
        <li key={suggestion[i]['patient']}>
          Patient {suggestion[i]['patient_name']} auf Bett{' '}
          {suggestion[i]['bed_name_new']} (Raum {suggestion[i]['room_id_new']})
          verschieben.
        </li>
      )
    }
    renderedSuggestionHeading = 'Belegungsvorschlag:'
  }

  //preparing the reason for no suggestion to be rendered
  //if there is no reason, the variable stays empty
  let renderedReason = ''

  if (reason === 'full') {
    renderedReason =
      'Es ist kein Belegungsvorschlag möglich, da alle Betten belegt sind.'
  }
  if (reason === 'not full - nothing possible') {
    renderedReason =
      'Es ist kein Belegungsvorschlag möglich, da die Anforderungen aller Patienten nicht berücksichtigt werden können.'
  }
  if (reason === 'not full - something possible') {
    renderedReason =
      'Interner Fehler. Egentlich sollte ein Belegungsvorschlag möglich sein. Deshalb sollte diese Nachricht nie erscheinen.'
  }

  return (
    <>
      <VStack align='stretch' spacing={4}>
        {/* Patienteninformationen */}
        <Box mt={5} display='inline-block'>
          <Text fontSize='lg' color='gray.300'>
            {' '}
            Patient{' '}
          </Text>
          <Wrap spacing='20px' p={5}>
            {/* GESCHLECHT */}
            <InfoCardComponent
              title={'Geschlecht'}
              content={getGenderText(patient.gender)}
            />

            {/* GEBURTSDATUM */}
            <InfoCardComponent
              title={'Geburtsdatum'}
              content={getBirthText(patient.birth_date)}
            />

            {/* PRIVATVERSICHERUNG */}
            <InfoCardComponent
              title={'Versicherungstyp'}
              content={getInsuranceTypeText(patient.private_insurance)}
            />

            {/* PRIVATVERSICHERUNG */}
            <InfoCardComponent
              title={'Vorgesehene Station'}
              content={getWardText(homeWard)}
            />

             {/* DOPPELZIMMER */}
             <InfoCardComponent
              title={'Doppelzimmer Anspruch'}
              content={getRoomSpecialRequestText(patient.double_room_needed)}
            />

            {/* EINZELZIMMER */}
            <InfoCardComponent
              title={'Einzelzimmer Anspruch'}
              content={getRoomSpecialRequestText(patient.single_room_needed)}
            />

            {/* STÖRT */}
            <InfoCardComponent
              title={'Stört andere'}
              content={getJaNeinText(patient.disturbs)}
            />

            {/* ISOLATION */}
            <InfoCardComponent
              title={'Isolation benötigt'}
              content={getJaNeinText(patient.isolation_needed)}
              icon={getIsolationIcon(patient.isolation_needed)}
            />

            {/* BED PLACE ISOLATION */}
            <InfoCardComponent
              title={'Bettplatzisolation benötigt'}
              content={getJaNeinText(patient.bed_place_isolation_needed)}
              icon={getIsolationIcon(patient.bed_place_isolation_needed)}
            />

            {/* MONITORBETT */}
            <InfoCardComponent
              title={'Monitorbett benötigt'}
              content={getRoomSpecialRequestText(patient.monitor_bed_needed)}
            />

            {/* SOLLTE SCHNELL ERREICHBAR EIN */}
            <InfoCardComponent
              title={'Muss schnell erreichbar sein'}
              content={getJaNeinText(patient.quick_access_needed)}
              icon={getQuickAccessIcon(patient.quick_access_needed)}
            />

          </Wrap>
          <Divider />
        </Box>

        {/* Zusätzliche Informationen*/}
        <Box mt={5} display='inline-block'>
          <Text fontSize='lg' color='gray.300'>
            Zusätzliche Inforamtionen
          </Text>   
          <Textarea width={'800px'} mb={'20px'}  mt={'20px'} disabled>
            {patient.additional_patient_information}
          </Textarea>
          <Divider />
        </Box>       

        {/* Belegung*/}
        <Box mt={5} display='inline-block'>
          <Text fontSize='lg' color='gray.300'>
            {' '}
            Belegung{' '}
          </Text>          

          <Wrap spacing='20px' p={5}>
            <Flex direction="column">
              {assignment.map((assignment, index, arr) => {
                return (
                  <TimelineRow
                    index={index}
                    arrLength={arr.length}
                    editAssignment={editAssignment}
                    assignment={assignment}
                    getAssignmentStatusButton={getAssignmentStatusButton}
                    getAssignmentStatusText={getAssignmentStatusText}
                  />
                )
              })}
            </Flex>
          </Wrap>

          <Divider />
        </Box>



        {has_suggestion || has_reason ? (
          <Box mt={4} w='full'>
            <Text fontSize='lg' color='gray.300'>
              {' '}
              Belegungsvorschlag{' '}
            </Text>
            <SlideFade in={has_suggestion} offsetY='20px'>
              <Alert width='95%' status='info' borderRadius={6} mt={4}>
                <AlertIcon />
                <VStack align='left' ml={10}>
                  <Text fontWeight='bold'>{renderedSuggestionHeading}</Text>
                  <Text>{renderedSuggestion}</Text>
                </VStack>
              </Alert>
            </SlideFade>
            <SlideFade in={has_reason} offsetY='20px'>
              <Alert width='95%' status='error' borderRadius={6} mt={4}>
                <AlertIcon />
                <VStack align='left' ml={10}>
                  <Text>
                    {patient.gender === 'F' ? 'Die Patientin ' : 'Der Patient '}
                    konnte nicht belegt werden. Grund:
                    {' ' + renderedReason}
                  </Text>
                </VStack>
              </Alert>
            </SlideFade>
          </Box>
        ) : (
          ''
        )}
      </VStack>
    </>
  )
}

export default PatientDetailComponent
