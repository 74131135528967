import { useEffect, useState } from "react";
import emitter from "../util/event";
import Cookies from "js-cookie";

//import Chakra UI components
import {
    Box,
    Text,
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    VStack,
    Textarea,
    HStack,
} from "@chakra-ui/react";

import "../index.css";
import PatientCapsuleComponent from './PatientCapsuleComponent'

import { Assignment } from "../models/Assignment";
import TransferRequest from "../models/TransferRequest";
import APICenter from "../apis/APICenter";
import APIUtils from "../apis/APIUtils";

const TransferRequestComponent = () => {

    var title_request = "Verlegungsanfrage";
    var width = "500";
    // bool for rendering the transfer request for the sender/receiver ward
    const [showTransRequ_sender, setShowTransRequ_sender] = useState(false);
    const [showTransRequ_sender_edit, setShowTransRequ_sender_edit] = useState(false);
    const [showTransRequ_receiver, setShowTransRequ_receiver] = useState(false);
    // bool for rendering the transfer results at the sender ward
    const [showTransResult_accepted, setShowTransResult_accepted] = useState(false);
    const [showTransResult_rejected, setShowTransResult_rejected] = useState(false);

    const [wards, setWards] = useState([]);
    // wardsSelected is the ward that sends a transfer request
    const [wardsSelected, setWardsSelected] = useState(-1);
    const [assignment, setAssignment] = useState(new Assignment());
    // flag to set whether the request should be delted or not
    const [delteRequest, setDeleteRequest] = useState(false);

    // needed for rendering the bed... :/
    const [suggestion, setSuggestion] = useState(null);
    const [suggestionPatientId, setSuggestionPatientId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);


    const [isLoading, setIsLoading] = useState(false);// for button animaiton,

    const { getAllWards } = APIUtils();

    // reusable code for dropbox
    let dropDownMenu_title = "An Station";
    let dropDownMenu_name = "request_receiver_ward";//
    let dropDownMenu_placeholder = "Wähle eine Station";

    // var dropDownMenu_value = "test";// TODO fetch/give to backend
    //-----------------------------
    // reusable code for textbox
    let textBox_title = "Kommentar";
    let textBox_name = "comment_sender_ward";
    let textBox_placeholder = "(optional)";

    // reused from AddPatientComponent
    const navigateToStart = () => {

        emitter.emit(
            "menuIdx",
            parseInt(window.localStorage.getItem("lastMenuIdx"))
        );
        setShowTransRequ_sender(false);
        setShowTransRequ_receiver(false);
        setShowTransResult_accepted(false);
        setShowTransRequ_sender_edit(false);
        setShowTransResult_rejected(false);
        setDeleteRequest(false);
        setSelectedDate(null);
        emitter.emit("refresh-assignments");
        // setAssignment(null);
        // setWardsSelected(null);
        // setSuggestion(null);
        // setWards(null);
        // setSuggestionPatientId(null);

    }
    const apiCenter = new APICenter();

    const inputChange = (e) => {
        // element.name is the name field of the for example dropdown menu
        const element = e.target;
        assignment.request[element.name] = element.value;

        setAssignment(Object.assign(new Assignment(), assignment));
        // console.log("new assigment:");
        // console.log(assignment);
    };

    const fetchSelectedWards = () => {
        const username = Cookies.get("username");//  easy hackable...
        const wardsSelectedCookie = Cookies.get(
            `wardsSelected_${username}`
        );
        if (wardsSelectedCookie) {
            const wardsSelected = JSON.parse(wardsSelectedCookie);
            // console.log("🏥wardsSelected with cookie", wardsSelected);
            setWardsSelected(wardsSelected);
        }
        // else if (wards.length > 0) {
        //     console.log("🏥wardsSelected (selected first)", wards[0]);
        //     setWardsSelected(wards[0]);
        //     return;
        // }
        // else {
        //     const hardCoded_ward_id = 4;
        //     console.log("🏥wardsSelected hard coded", hardCoded_ward_id);

        //     // setWardsSelected(responseFetchWards.data[hardCoded_wardSelection].id);
        //     setWardsSelected(hardCoded_ward_id);
        // }
    };

    const getWards = async () => {
        const responseFetchWards = await getAllWards();
        if (responseFetchWards.isSuccessful) {

            // set current ward, reused from RoomView.jsx
            // If wardsSelected is in Cookie, set wardsSelected to wardsSelected in Cookie
            // else set wardsSelected to first ward in list...
            fetchSelectedWards();
            setWards(responseFetchWards.wards);
        }
    };

    useEffect(() => {

        getWards();
        // fetch backenddata?

        // console.log(responseFetchWards)

        const callback = (str, assignment, suggestion, suggestionPatientId, selectedDate) => {
            // select with string with view should be displayed
            if (str === "sender") {
                // shitty code, better solution??? 
                setShowTransRequ_sender(true);
            }
            else if (str === "edit") {
                setShowTransRequ_sender_edit(true);
            }
            else if (str === "receiver") {
                setShowTransRequ_receiver(true);
            }
            else if (str === "accepted") {
                setShowTransResult_accepted(true);
            }
            else if (str == "rejected") {
                setShowTransResult_rejected(true);
            }


            if (assignment?.request === null) {
                assignment.request = new TransferRequest()
            }
            setAssignment(assignment);
            // only needed for rendering the bed
            // console.log("set assignment");
            // console.log(assignment);
            setSuggestionPatientId(suggestionPatientId);
            setSuggestion(suggestion);
            setSelectedDate(selectedDate);
        };


        emitter.on("updateWardSelection", fetchSelectedWards);
        emitter.on("showTransferRequest", callback);

        return () => {
            emitter.removeListener("showTransferRequest", callback);
            emitter.removeListener("updateWardSelection", fetchSelectedWards);

        };
    }, []);


    const onSubmit = async (e) => {
        e.preventDefault(); //so it does not submit to a page


        //start the loading animation
        setIsLoading(true);
        // // interact with backend

        let msg;
        if (showTransRequ_sender) {
            // fill up additonal transfer-request data
            assignment.request.request_receiver_ward = Number(assignment.request.request_receiver_ward)
            // console.log("assignment.patient.home_ward", assignment.patient.home_ward)
            assignment.request.request_sender_ward = assignment.patient.home_ward
            assignment.request.assignment = assignment.id
            // console.log("sender ward: " +async createTransferRequest(

            msg = await apiCenter.createTransferRequest(
                assignment.request.request_sender_ward,// sender_ward_id
                assignment.request.request_receiver_ward,//receiver_ward_id
                assignment.request.comment_sender_ward,
                assignment
            );
        }
        else if (showTransRequ_receiver || (showTransRequ_sender_edit && !delteRequest)) {
            // console.log("updated State: ", assignment.request.state);
            msg = await apiCenter.updateTransferRequest(
                assignment.request.id,
                assignment
            );
        }
        else if (showTransResult_rejected || delteRequest) {
            // TODO create API for delting transferrequests
            msg = await apiCenter.deleteTransferRequest(
                assignment.request.id
            );
        }
        setIsLoading(false);

        if (msg || showTransResult_accepted) {// accepted view does nothing...
            // backend creation was successfull
            console.log("✅successfull transfer request!");

        }
        else {
            console.log("❌fail during  transfer request!");
            navigateToStart();
        }

        emitter.emit("refresh-assignments");
        emitter.emit("menuIdx", 0);
        setDeleteRequest(false);
        setShowTransRequ_sender(false);
        setShowTransRequ_sender_edit(false);
        setShowTransRequ_receiver(false);
        setShowTransResult_accepted(false);
        setShowTransResult_rejected(false);
        setSelectedDate(null);
        // emitter.emit("menuIdx", 0);
        // setShowAddPatient(false);
    };



    return (
        <>
            {/* 
            Transfer request from sender 
            and 
            Edit Transfer request form sender
        */}
            <Modal
                size={"3xl"}
                isCentered
                isOpen={showTransRequ_sender || showTransRequ_sender_edit}
                scrollBehavior={"inside"}
                onClose={() => {
                    // setShowTransRequ_sender(!showTransRequ_sender);
                    setShowTransRequ_sender(false);
                    setShowTransRequ_sender_edit(false);
                    navigateToStart();
                }}
                motionPreset="slideInLeft"
            >
                <ModalOverlay
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px) hue-rotate(0deg)"
                />

                <ModalContent maxW={width} maxH={""} alignItems={"center"}>
                    <ModalHeader
                        color={"#0F4046"}
                        textAlign={"center"}
                        fontSize={"32px"}
                        fontWeight={"bold"}
                        mt={"100"}
                    >
                        {(showTransRequ_sender) ? ("Verlegungsanfrage") : ("Verlegungsanfrage bearbeiten")}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody className="addPatientBox">
                        <Box my={"10"}>
                            <form id="addPatient" onSubmit={onSubmit}>
                                <VStack spacing={30} alignItems="center">
                                    <Box w={300} alignContent={"center"}>

                                        <PatientCapsuleComponent
                                            key={assignment.id}
                                            index={0}
                                            patient={assignment.patient}
                                            assignment={assignment}
                                            suggestion={suggestion}
                                            suggestionPatientId={suggestionPatientId}
                                            isDragEnabled={false}
                                            selectedDate={selectedDate}
                                            onClick={() => { }}
                                        />
                                    </Box>
                                    <Box w={350} alignContent={"center"}>

                                        <FormControl isRequired>
                                            <FormLabel>{dropDownMenu_title}</FormLabel>
                                            <Select
                                                name={dropDownMenu_name}
                                                mb={4}
                                                placeholder={dropDownMenu_placeholder}
                                                // value={assignment.request.request_receiver_ward} //TODO fix error
                                                isRequired={true}
                                                onChange={inputChange}
                                                value={assignment.request?.request_receiver_ward}
                                            >
                                                {wards.filter(ward => ward.id !== assignment.patient.home_ward)
                                                    .map(ward => (
                                                        <option value={ward.id} key={ward.id}>
                                                            {ward.name}
                                                        </option>
                                                    ))}

                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>{textBox_title}</FormLabel>
                                            <Textarea
                                                name={textBox_name}
                                                resize='vertical'
                                                mb={4}
                                                type="text"
                                                placeholder={textBox_placeholder}
                                                value={assignment.request?.comment_sender_ward} //TODO fix error
                                                onChange={inputChange}
                                            ></Textarea>
                                        </FormControl>

                                        {(showTransRequ_sender_edit) ? (
                                            <HStack alignItems="center">
                                                {/** show buttons for saving and delting request */}

                                                {(isLoading) ? (
                                                    <>
                                                        <Button
                                                            flex={1}
                                                            type="submit"
                                                            colorScheme="brand"
                                                            background="brand.400"
                                                            disabled={true}
                                                            isLoading
                                                            spinnerPlacement="end"
                                                            loadingText="Speichern"
                                                        />
                                                        <Button
                                                            flex={1}
                                                            type="submit"
                                                            colorScheme="brand"
                                                            disabled={true}
                                                            isLoading
                                                            spinnerPlacement="end"
                                                            loadingText="Speichern"
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button
                                                            flex={1}
                                                            type="submit"
                                                            colorScheme="brand"
                                                            disabled={false}
                                                            background="brand.400"
                                                            onClick={() => { setDeleteRequest(true) }}
                                                        >
                                                            Anfrage löschen

                                                        </Button>
                                                        <Button
                                                            flex={1}
                                                            type="submit"
                                                            colorScheme="brand"
                                                            disabled={false}
                                                        >
                                                            {(showTransRequ_sender) ? ("Anfrage abschicken") : ("Speichern")}

                                                        </Button>
                                                    </>

                                                )}
                                            </HStack>
                                        ) : (
                                            <>
                                                {/** show only BUTTON FOR SENDING REQUEST */}
                                                {isLoading ? (
                                                    <Button
                                                        flex={1}
                                                        type="submit"
                                                        colorScheme="brand"
                                                        disabled={true}
                                                        isLoading
                                                        spinnerPlacement="end"
                                                        loadingText="Anfrage senden"
                                                    />
                                                ) : (
                                                    <Button
                                                        flex={1}
                                                        type="submit"
                                                        colorScheme="brand"
                                                        disabled={false}
                                                    >
                                                        {(showTransRequ_sender) ? ("Anfrage abschicken") : ("Bearbeitung speichern")}

                                                    </Button>
                                                )
                                                }
                                            </>
                                        )
                                        }
                                    </Box>
                                </VStack>

                            </form>
                        </Box>
                    </ModalBody>
                    <ModalFooter />
                </ModalContent>
            </Modal>

            {/* 
            Transfer request on Receiver side
        */}
            <Modal
                size={"3xl"}
                isCentered
                isOpen={showTransRequ_receiver}
                scrollBehavior={"inside"}
                onClose={() => {
                    // setShowTransRequ_sender(!showTransRequ_sender);
                    setShowTransRequ_receiver(false);
                    navigateToStart();
                }}
                motionPreset="slideInLeft"
            >
                <ModalOverlay
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px) hue-rotate(0deg)"
                />

                <ModalContent maxW={width} maxH={""} alignItems={"center"}>
                    <ModalHeader
                        color={"#0F4046"}
                        textAlign={"center"}
                        fontSize={"32px"}
                        fontWeight={"bold"}
                        mt={"100"}
                    >
                        Verlegungsanfrage
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody className="addPatientBox">
                        <Box my={"10"}>
                            <form id="addPatient" onSubmit={onSubmit}>
                                <VStack spacing={30} alignItems="center">
                                    <Box w={300} alignContent={"top"}>

                                        <PatientCapsuleComponent
                                            key={assignment.id}
                                            index={0}
                                            patient={assignment.patient}
                                            assignment={assignment}
                                            suggestion={suggestion}
                                            suggestionPatientId={suggestionPatientId}
                                            isDragEnabled={false}
                                            selectedDate={selectedDate}
                                            onClick={() => { }}
                                        />
                                    </Box>
                                    <Box w={350} alignContent={"top"}>
                                        <FormControl>
                                            <FormLabel>Zuständige Station</FormLabel>
                                            <Text>
                                                {wards.find((ward) => {
                                                    return ward.id == assignment.request?.request_sender_ward
                                                })?.name}
                                            </Text>
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>Anfragekommentar</FormLabel>
                                            {(assignment.request?.comment_sender_ward === "") ?
                                                <Text color='#B5B5B5'> {"---kein Kommentar---"}</Text>
                                                :
                                                <Text> {assignment.request?.comment_sender_ward}</Text>
                                            }
                                        </FormControl>


                                        <FormControl>
                                            <FormLabel>Kommentar</FormLabel>
                                            <Textarea
                                                name="comment_receiver_ward"
                                                resize='vertical'
                                                mb={4}
                                                type="text"
                                                placeholder={textBox_placeholder}
                                                value={assignment.request?.comment_receiver_ward}
                                                onChange={inputChange}
                                            ></Textarea>
                                        </FormControl>

                                        {/** BUTTON FOR ANSWERING REQUEST */}
                                        <HStack alignItems="center">
                                            {isLoading ? (
                                                <>

                                                    <Button
                                                        flex={1}
                                                        type="submit"
                                                        colorScheme="brand"
                                                        disabled={true}
                                                        isLoading
                                                        spinnerPlacement="end"
                                                        loadingText="Sendet..."
                                                    />
                                                    <Button
                                                        flex={1}
                                                        type="submit"
                                                        colorScheme="brand"
                                                        disabled={true}
                                                        isLoading
                                                        spinnerPlacement="end"
                                                        loadingText="Sendet..."
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        flex={1}
                                                        type="submit"
                                                        colorScheme="brand"
                                                        background="brand.400"
                                                        disabled={false}
                                                        onClick={() => {
                                                            assignment.request.state = "R";
                                                        }}
                                                    >
                                                        Ablehnen
                                                    </Button>
                                                    <Button
                                                        flex={1}
                                                        type="submit"
                                                        colorScheme="brand"
                                                        disabled={false}
                                                        onClick={() => {
                                                            assignment.request.state = "A";
                                                        }}
                                                    >
                                                        Annehmen
                                                    </Button>
                                                </>
                                            )}
                                        </HStack>
                                    </Box>

                                </VStack>

                            </form>
                        </Box>
                    </ModalBody>
                    <ModalFooter />
                </ModalContent>
            </Modal>
            {/* 
            Transfer request accepted on sender-side
            and 
            Transfer request rejected on sender-side
        */}
            <Modal
                size={"3xl"}
                isCentered
                isOpen={showTransResult_accepted || showTransResult_rejected}
                scrollBehavior={"inside"}
                onClose={() => {
                    // setShowTransRequ_sender(!showTransRequ_sender);
                    setShowTransResult_accepted(false);
                    setShowTransResult_rejected(false);
                    navigateToStart();
                }}
                motionPreset="slideInLeft"
            >
                <ModalOverlay
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px) hue-rotate(0deg)"
                />

                <ModalContent maxW={width} maxH={""} alignItems={"center"}>
                    <ModalHeader
                        color={"#0F4046"}
                        textAlign={"center"}
                        fontSize={"32px"}
                        fontWeight={"bold"}
                        mt={"100"}
                    >
                        {(showTransResult_accepted) ? ("Verlegungsanfrage wurde angenommen") : ("Verlegungsanfrage wurde abgelehnt")}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody className="addPatientBox">
                        <Box my={"10"}>
                            <form id="addPatient" onSubmit={onSubmit}>
                                <VStack spacing={30} alignItems="center">
                                    <Box w={300} alignContent={"top"}>

                                        <PatientCapsuleComponent
                                            key={assignment.id}
                                            index={0}
                                            patient={assignment.patient}
                                            assignment={assignment}
                                            suggestion={suggestion}
                                            suggestionPatientId={suggestionPatientId}
                                            isDragEnabled={false}
                                            selectedDate={selectedDate}
                                            onClick={() => { }}
                                        />
                                    </Box>
                                    <Box w={350} alignContent={"top"}>

                                        <FormControl>
                                            <FormLabel>Angefragte Station</FormLabel>
                                            <Text>
                                                {wards.find((ward) => {
                                                    return ward.id == assignment.request?.request_sender_ward
                                                })?.name}
                                            </Text>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Kommentar der angefragten Station</FormLabel>
                                            {(assignment.request?.comment_receiver_ward === "") ?
                                                <Text color='#B5B5B5'> {"---kein Kommentar---"}</Text>
                                                :
                                                <Text> {assignment.request?.comment_receiver_ward}</Text>
                                            }
                                        </FormControl>



                                        {/** BUTTON FOR SENDING REQUEST */}
                                        {isLoading ? (
                                            <Button
                                                width="full"
                                                mt={4}
                                                type="submit"
                                                colorScheme="brand"
                                                disabled={true}
                                                isLoading
                                                spinnerPlacement="end"
                                                loadingText="Aktion speichern"
                                            />
                                        ) : (
                                            <Button
                                                width="full"
                                                mt={4}
                                                type="submit"
                                                colorScheme="brand"
                                                disabled={false}
                                            >
                                                {(showTransResult_accepted) ? ("Zur Kenntnis genommen") : ("Patient in den Aktionsbereich legen")}

                                            </Button>
                                        )}
                                    </Box>
                                </VStack>

                            </form>
                        </Box>
                    </ModalBody>
                    <ModalFooter />
                </ModalContent>
            </Modal>
        </>
    );
};

export default TransferRequestComponent;


