import React, { useEffect, useState } from 'react'
//Import Router
import { Link, useNavigate } from 'react-router-dom'

// Import Event Emitter
import emitter from '../util/event'
import {
  Box,
  Button,
  Icon,
  Image,
  VStack,
  Tooltip
} from '@chakra-ui/react'

import * as MdIcons from 'react-icons/md'
import * as FaIcons from 'react-icons/fa'
import * as RiIcons from 'react-icons/ri'

//Import Assets
import { IconContext } from 'react-icons/lib'
import Logo from '../assets/Medigital_Logo.png'
import LogoSmall from '../assets/Medigital_Logo.svg'


//Import custom styling
import '../index.css'
import theme from '../theme/theme.js'

// The Menubar data
const menubarData = [
  {
    name: 'Patientenbelegung',
    path: '',
    icon: <MdIcons.MdDashboard />,
  },
  {
    name: 'Kalenderansicht',
    path: 'calendar_view',
    icon: <FaIcons.FaRegCalendarAlt />,
  },
  {
    name: 'Auslastung',
    path: 'utilization',
    icon: <MdIcons.MdAutoGraph />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    name: 'Patienten anlegen',
    path: 'new_patient',
    icon: <FaIcons.FaRegUserCircle />,
  },
  {
    name: 'Einstellungen',
    path: 'settings',
    icon: <MdIcons.MdSettingsInputComposite />,
  },
]

// Single Menu Button Component
const MenubarComponent = ({
  idx,
  oldIdx,
  icon,
  name,
  isSelected,
  setIndex,
  path,
  isCollapsed
}) => {
  const navigate = useNavigate()

  // adjusting button style for different visual effect
  let buttonStyle = 'ghost'
  if (isSelected) {
    buttonStyle = 'solid'
    // TODO: adjust icon for selected and unselected status
  }

  let colorScheme = 'white'
  if (isSelected) {
    colorScheme = 'selectedMenuItem'
  }

  const setIndexAndNavigate = (idx, path) => {
    setIndex(idx)
    // save the last clicked menu idx for navigating back
    window.localStorage.setItem('lastMenuIdx', oldIdx)
    // sending the new clicking idx to parent component => SidbarComponent
    emitter.emit('menuIdx', idx)
    if (path === 'new_patient') {
      // if new_patient is selected, tell DashboardView the modal popup should be show
      emitter.emit('showAddPatient', true)
    } else {
      // otherwise directly navigate to the url route
      navigate(path)
    }
  }

  return (
    <Tooltip isDisabled={!isCollapsed} label={name}>
      <Button
        onClick={() => setIndexAndNavigate(idx, path)}
        color={'white'}
        variant={buttonStyle}
        leftIcon={icon}
        colorScheme={colorScheme}
        fontSize={isCollapsed ? '3xl' : 'md'}
        width='95%'
        justifyContent='flex-start'
        height={isCollapsed ? '44px': '40px'}>
        {isCollapsed ? undefined : name}
      </Button>
    </Tooltip>
  )
}

const LeftRightIcon = (props) => 
  <Icon boxSize='24px' viewBox='0 0 24 24' fillOpacity="0.0" stroke="white" strokeWidth="1.5" strokeLinejoin="round" strokeLinecap="round"  {...props}>
    <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" />
    {props.children}
  </Icon>

const RightIcon = (props) => 
  <LeftRightIcon {...props}><path d="M13.26 15.5302L9.73999 12.0002L13.26 8.47021"/></LeftRightIcon>

const LeftIcon = (props) => 
  <LeftRightIcon {...props}><path d="M10.74 15.5302L14.26 12.0002L10.74 8.47021"/></LeftRightIcon>

//Function to set up the sidebar structuring the layout
const SidebarComponent = ({ showAddPatient, collapsable, isCollapsed, onCollapse }) => {
  // Initialize MenuIdx by current URL href
  const setMenuIdxByURL = () => {
    const href = window.location.href
    const routeURL = href.substring(href.lastIndexOf('/') + 1, href.length)

    if (routeURL === 'utilization') {
      setCurrentMenuIndex(2)
    } else if (routeURL === 'calendar_view') {
      setCurrentMenuIndex(1)
    } else if (routeURL === 'settings') {
      setCurrentMenuIndex(4)
    } else {
      // room_view /*
      setCurrentMenuIndex(0)
    }
  }

  
  const [currentMenuIndex, setCurrentMenuIndex] = useState(0)

  // Listen on menuIdx from MenubarCompomnent, maintain a global currentMenuIdx
  useEffect(() => {
    setMenuIdxByURL()
    emitter.on('menuIdx', (e) => {
      setCurrentMenuIndex(e)
    })
  }, [])

  const menubarElements = []

  // Iteration over menubardata for creating menulist
  menubarData.forEach((menubar, idx) => {
    menubarElements.push(
      <MenubarComponent
        setIndex={setCurrentMenuIndex}
        showAddPatient={showAddPatient}
        isSelected={currentMenuIndex === idx}
        idx={idx}
        oldIdx={currentMenuIndex}
        key={idx}
        path={menubar.path}
        icon={menubar.icon}
        name={menubar.name}
        isCollapsed={isCollapsed}
      />
    )
  })
  
  return (
      <IconContext.Provider value={{ color: 'white' }}>
        <Box className='sidebar-nav' width={isCollapsed ? theme.widthSidebarCollapsedNoCalc : theme.widthSidebarExpandedNoCalc}>
            <Link className='nav-logo' to='/'>  
              {isCollapsed ?  (<Image src={LogoSmall} height={'32px'} width={'32px'} alt='Logo'/>) : (<Image src={Logo} height={'60px'} alt='Logo' />)}
            </Link>

            <VStack spacing={3} ml={2} mr={2}>
              {menubarElements}
            </VStack>

            {collapsable && (
              <VStack alignItems="end">
                  {isCollapsed ? (<LeftIcon onClick={onCollapse} />):(<RightIcon onClick={onCollapse}/>)}
              </VStack>
              )
            }
          </Box>
      </IconContext.Provider>
  )
}
export default SidebarComponent
