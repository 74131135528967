import React from "react";
import * as types from "../../calendar-types";
import { RoomsStatus, RoomStatus, Ward } from "../../calendar-types";
import { RoomItem } from "./roomItem";

interface WardItemProps {
  ward: Ward;
  wardStatus?: RoomStatus;
  status: RoomsStatus;
  onChangeStatus: (status: RoomsStatus) => void;
  fixedNames: types.Event[];
}

function WardItem({
  status,
  wardStatus,
  onChangeStatus,
  ward,
  fixedNames,
}: WardItemProps) {
  return (
    <div>
      <div className={"h-6 pl-6 text-xs flex items-center"}></div>
      {!!wardStatus && wardStatus.expanded && (
        <div className="flex flex-col">
          {ward.rooms.map((room) => (
            <RoomItem
              key={room.id}
              status={status[room.id]}
              room={room}
              fixedNames={fixedNames}
              onClick={() => {
                const statusPerRoom = status[room.id];
                const expanded = statusPerRoom?.expanded ?? false;
                onChangeStatus({
                  ...status,
                  [room.id]: { ...statusPerRoom, expanded: !expanded },
                });
              }}
            />
          ))}
        </div>

      )}

    </div>
  );
}

export { WardItem };
