import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import WeekOfYear from "dayjs/plugin/weekOfYear";
import Weekday from "dayjs/plugin/weekday";
import Cookies from "js-cookie";
import React, {useEffect, useState} from "react";
import APICenter from "../apis/APICenter";
import CalendarManager from "../components/calendarManager/CalendarManager";
import {WardsProvider} from "../context/WardsContext";
import emitter from "../util/event";

dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(Weekday);
dayjs.extend(WeekOfYear);

function CalendarView() {
    const [selectedDate, setSelectedDate] = useState(dayjs().toDate());
    const [fetchNewAssignmentId, setFetchNewAssignmentId] = useState(false);
    const [fetchDate, setFetchDate] = useState(
        dayjs(selectedDate).format("YYYY-MM-DD")
    );
    const [startDay] = useState();
    const [assignments, setAssignments] = useState({});
    const [fetchCounter, setFetchCounter] = useState(false);
    const [scrolling, setScrolling] = useState(true);
    const apiCenter = new APICenter();
    const username = decodeURI(Cookies.get("username"));


    const getAssignments = async () => {
        const response = await apiCenter.fetchAssignmentsInAllRooms({
            start: dayjs(fetchDate)
                .subtract(4, "week")
                .format("YYYY-MM-DD"),
            end: dayjs(fetchDate)
                .add(2, "month")
                .endOf("month")
                .format("YYYY-MM-DD"),
        });
        setAssignments({
            assignmentsOfRooms: response.assignments_of_rooms,
            assignmentsWithoutBed: response.assignments_without_bed,
        });
    };


    useEffect(() => {
        getAssignments();
    }, [selectedDate, fetchDate, fetchCounter]);

    //used for purpose of adding event for realtime update
    useEffect(() => {

        emitter.on("update_calender_view", getAssignments);

        return () => {
            emitter.removeListener("update_calender_view", getAssignments);
        };
    }, [])

    return (
        <CalendarManager
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            assignments={assignments}
            setFetchDate={setFetchDate}
            fetchDate={fetchDate}
            fetchCounter={fetchCounter}
            setFetchCounter={setFetchCounter}
            startDay={startDay}
            fetchNewAssignmentId={fetchNewAssignmentId}
            setFetchNewAssignmentId={setFetchNewAssignmentId}
            scrolling={scrolling}
            setScrolling={setScrolling}
            username={username}
        />
    );
}

export default CalendarView;
