import React from "react";
import * as types from "../../calendar-types";
import { Room, RoomStatus } from "../../calendar-types";
import { FixedName } from "./fixedName";

interface RoomItemProps {
  room: Room;
  onClick: () => void;
  status?: RoomStatus;
  fixedNames: types.Event[];
}

function RoomItem({ room, onClick, status, fixedNames }: RoomItemProps) {
  return (
    <div>
      <div
        className={
          `h-6 pl-6 text-xs hover:opacity-70 flex items-center gap-2 cursor-pointer w-[250px]`
        }
        onClick={onClick}
      ></div>
      {status && status.expanded && (
        <div className="flex flex-col">
          {room.beds.map((bed) => (
            <FixedName bed={bed} fixedNames={fixedNames} key={bed.id} />
          ))}
        </div>
      )}
    </div>
  );
}

export { RoomItem };
