import {
	ArrowDownCircleIcon,
	ArrowUpCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { RoomsStatus, RoomStatus, Ward } from "../../calendar-types";
import { RoomItem } from "./roomItem";

interface WardItemProps {
	ward: Ward;
	wardStatus?: RoomStatus;
	status: RoomsStatus;
	onChangeStatus: (status: RoomsStatus) => void;
}

function WardItem({ status, wardStatus, onChangeStatus, ward }: WardItemProps) {
	const initState = ward.rooms.length > 0 && ward.rooms.every(room => status[room.id]?.expanded ?? false);
	const [isOpen, setIsOpen] = useState(initState);

	const handleClick = () => {
		const newStatus = {
			...status,
		};

		for (const room of ward.rooms) {
			const statusPerRoom = newStatus[room.id];
			newStatus[room.id] = { ...statusPerRoom, expanded: !isOpen };
		}
		onChangeStatus(newStatus);
		setIsOpen((oldOpen) => !oldOpen);
	};

	return (
		<div>
			<div
				className={
					"bg-gray-200 h-6 pl-2 text-xs font-bold flex items-center hover:opacity-70 cursor-pointer"
				}
				onClick={handleClick}
			>
				{isOpen ? (
					<ArrowDownCircleIcon width={16} height={16} />
				) : (
					<ArrowUpCircleIcon width={16} height={16} />
				)}{" "}
				{ward.label}
			</div>
			{!!wardStatus && wardStatus.expanded && (
				<div className="flex flex-col">
					{ward.rooms.map((room) => (
						<RoomItem
							key={room.id}
							status={status[room.id]}
							room={room}
							onClick={() => {
								const statusPerRoom = status[room.id];
								const expanded =
									statusPerRoom?.expanded ?? false;
								onChangeStatus({
									...status,
									[room.id]: {
										...statusPerRoom,
										expanded: !expanded,
									},
								});
							}}
						/>
					))}
				</div>
			)}
		</div>
	);
}

export { WardItem };
