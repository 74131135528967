import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import emitter from "../util/event";

//import Chakra UI components
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  HStack,
  Textarea, useDisclosure,
} from "@chakra-ui/react";

import "../index.css";
import Bed from "../models/Bed";
import Room from "../models/Room";
import Ward from "../models/Ward";
import APICenter from "../apis/APICenter";
import APIUtils from "../apis/APIUtils";
import {AssignmentFailMessage, AssignmentSuccMessage, FetchRoomsMessage, FetchWardsMessage, FetchBedsMessage} from "../apis/Message";
import AuthContext from "../context/AuthContext";
import {toast} from "react-toastify";



const EditBaseDataComponent = (state) => {
  const { authCenter } = useContext(AuthContext);
  const navigate = useNavigate();
  const apiCenter = new APICenter();

  const [showEditRoom, setShowEditRoom] = useState(false);
  const [showEditWard, setShowEditWard] = useState(false);
  const [showEditBed, setShowEditBed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [room, setRoom] = useState(new Room());
  const [rooms, setRooms] = useState([]);
  const [wards, setWards] = useState([]);
  const [ward, setWard] = useState(new Ward());
  const [bed, setBed] = useState(new Bed());
  const roomStateId = state.roomState;
  const wardStateId = state.wardState;
  const bedStateId = state.bedState;
  const { getData, getSuggestions } = APIUtils();

        // fetch room by room id
  const fetchRoomById = async () => {
        // Call fetchRoomById API in apiCenter
        const msg = await apiCenter.fetchRoomById(roomStateId);
        console.log("fetch room by id")
        console.log(msg);
        // If it is FetchPatientMessage Class, if not authorized then Message class
        if (msg && msg instanceof FetchRoomsMessage) {
            // If the request succeeded with 200 status code
            if (msg.isSucc) {
                // Set Room
                setRoom(msg.rooms);
            }
        } else {
            // Failed msg in Message
            toast("Unable to fetch room data by id!");
        }
    };

  //fetch ward by ward id
  const fetchWardById = async () => {
        // Call fetchWardById API in apiCenter
        const msg = await apiCenter.fetchWardById(wardStateId);
        console.log("fetch ward by id")
        console.log(msg);
        // If it is FetchPatientMessage Class, if not authorized then Message class
        if (msg && msg instanceof FetchWardsMessage) {
            // If the request succeeded with 200 status code
            if (msg.isSucc) {
                // Set Ward
                setWard(msg.wards);
            }
        } else {
            // Failed msg in Message
            toast("Unable to fetch ward data by id!");
        }
    };

  // fetch bed by bed id
  const fetchBedById = async () => {
    // Call fetchBedById API in apiCenter
    const msg = await apiCenter.fetchBedById(bedStateId);
    console.log("fetch bed by id")
    console.log(msg);
    if (msg && msg instanceof FetchBedsMessage) {
        // If the request succeeded with 200 status code
        if (msg.isSucc) {
            // Set Room
            setBed(msg.beds);
        }
    } else {
        // Failed msg in Message
        toast("Unable to fetch bed data by id!");
    }
};
    //get all wards for room editing form
  const getWards = async () => {
    const responseFetchWards = await getData("wards");
    if (responseFetchWards.isSuccessful) {
      setWards(responseFetchWards.data);
    }
  };

  //get all rooms for bed editing form
  const getRooms = async () => {
    const responseFetchRooms = await getData("rooms");
    if (responseFetchRooms.isSuccessful) {
      setRooms(responseFetchRooms.data);
    }
  };

  useEffect(() => {
    const callback = () => {
      setShowEditRoom(true);
    };
    //listen room edit button
    emitter.on(`showEditRoom/${roomStateId}`, callback);
    fetchRoomById();
    getWards();
    return () => {
      emitter.removeListener(`showEditRoom/${roomStateId}`, callback);
    };
  }, []);

  useEffect(() => {
    const callback = () => {
      setShowEditWard(true);
    };
    //listen ward edit button
    emitter.on(`showEditWard/${wardStateId}`, callback);
    fetchWardById();
    return () => {
      emitter.removeListener(`showEditWard/${wardStateId}`, callback);
    };
  }, []);

  useEffect(() => {
    const callback = () => {
      setShowEditBed(true);
    };
    //listen bed edit button
    emitter.on(`showEditBed/${bedStateId}`, callback);
    getRooms();
    fetchBedById();
    return () => {
      emitter.removeListener(`showEditBed/${bedStateId}`, callback);
    };
  }, []);


  const inputChangeRoom = (e) => {
    const element = e.target;
    room[element.name] = element.value;
    setRoom(Object.assign(new Room(), room));
    console.log(room);
  };

  const inputChangeWard = (e) => {
    const element = e.target;
    ward[element.name] = element.value;
    setWard(Object.assign(new Ward(), ward));
    console.log(ward);
  };

  const inputChangeBed = (e) => {
    const element = e.target;
    bed[element.name] = element.value;
    setBed(Object.assign(new Bed(), bed));
    console.log(bed);
  };

    // For copy the object instance properties
  const copyObject = (src, tar) => {
    for (const [key, value] of Object.entries(src)) {
      tar[key] = value
    }
  }
  const navigateToStart = () => {
    emitter.emit(
      "menuIdx",
      parseInt(window.localStorage.getItem("lastMenuIdx"))
    );
    setShowEditRoom(false);
    setShowEditWard(false);
    setShowEditBed(false);
  };

  //submit room
  const onSubmitRoom = async (e) => {
    //start the loading animation
    setIsLoading(true)

    e.preventDefault() //so it does not submit to a page

    const msg = await apiCenter.setRoom(room)

    setIsLoading(false)
    if (msg) {
      if (msg.isSucc) {
        //fetchPatient()
        navigateToStart();
        emitter.emit("refresh-ward");
      } else if (msg.infoText === 'Unauthorized') {
        authCenter.logout()
        navigate('/')
      } else {
        alert('Internal Error')
      }
    }
  }

    //submit ward
  const onSubmitWard = async (e) => {
    //start the loading animation
    setIsLoading(true)

    e.preventDefault() //so it does not submit to a page

    const msg = await apiCenter.setWard(ward)

    setIsLoading(false)
    if (msg) {
      if (msg.isSucc) {
        navigateToStart();
        emitter.emit("refresh-ward");
      } else if (msg.infoText === 'Unauthorized') {
        authCenter.logout()
        navigate('/')
      } else {
        alert('Internal Error')
      }
    }
  }

      //submit bed
  const onSubmitBed = async (e) => {
    //start the loading animation
    setIsLoading(true);

    e.preventDefault() //so it does not submit to a page

    const msg = await apiCenter.setBed(bed)

    setIsLoading(false)
    if (msg) {
      if (msg.isSucc) {
        navigateToStart();
        emitter.emit("refresh-ward");
      } else if (msg.infoText === 'Unauthorized') {
        authCenter.logout()
        navigate('/')
      } else {
        alert('Internal Error')
      }
    }
  }
  //define the size option for room add form
  const roomSizeOption = [
    {
      label: "1",
      value: 1
    },
    {
      label: "2",
      value: 2
    },
    {
      label: "3",
      value: 3
    },
    {
      label: "4",
      value: 4
    },
  ];
    return (
    <>
      <Modal
        size={"xl"}
        isCentered
        isOpen={showEditRoom}
        visible={showEditRoom}
        scrollBehavior={"inside"}
        onClose={() => {
          setShowEditRoom(!showEditRoom);
          navigateToStart();
        }}
        motionPreset="slideInLeft"
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(0deg)"
        />

        <ModalContent maxW={"900"} maxH={""} alignItems={"center"}>
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
            mt={"100"}
          >
            Raum bearbeiten
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="editRoomBox">
            <Box my={"10"}>
              <form id="editRoom"onSubmit={onSubmitRoom}>
                <HStack spacing={30} alignItems="top">
                  <Box w={350} alignContent={"top"}>
                    <FormControl isRequired>
                      <FormLabel>Name</FormLabel>
                      <Input
                        name="name"
                        mb={4}
                        type="text"
                        placeholder="Name"
                        value={room.name}
                        isRequired={true}
                        onChange={inputChangeRoom}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Anzahl der Betten</FormLabel>
                      <Select 
                      name="size"
                      placeholder='Anzahl der Betten' 
                      value={room.size} 
                      onChange={inputChangeRoom}
                      isRequired={true}>
                      {roomSizeOption.map((sizeOption) => (
                          <option value={sizeOption.value}>
                            {sizeOption.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <br/>
            
                    <FormControl isRequired>
                      <FormLabel>Station</FormLabel>
                      <Select
                        name="ward"
                        mb={4}
                        placeholder="Wählen Sie eine Station"
                        value={room.ward}
                        isRequired={true}
                        onChange={inputChangeRoom}
                      >
                        {wards.map((ward) => (
                          <option value={ward.id} key={ward.id}>
                            {ward.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </HStack>

                <HStack mt={"10"}>
                  {/** BUTTON FOR EDITTING room */}
                  {isLoading ? (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={true}
                      isLoading
                      spinnerPlacement="end"
                      loadingText="Raum wird bearbeitet"
                    />
                  ) : (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={false}
                    >
                      Änderungen speichern
                    </Button>
                  )}
                </HStack>
              </form>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>

      <Modal
        size={"xl"}
        isCentered
        isOpen={showEditWard}
        visible={showEditWard}
        scrollBehavior={"inside"}
        onClose={() => {
          setShowEditWard(!showEditWard);
          navigateToStart();
        }}
        motionPreset="slideInLeft"
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(0deg)"
        />

        <ModalContent maxW={"900"} maxH={""} alignItems={"center"}>
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
            mt={"100"}
          >
            Station bearbeiten
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="editWardBox">
            <Box my={"10"}>
              <form id="editWard"onSubmit={onSubmitWard}>
                <HStack spacing={30} alignItems="top">
                  <Box w={350} alignContent={"top"}>
                    <FormControl isRequired>
                      <FormLabel>Name</FormLabel>
                      <Input
                        name="name"
                        mb={4}
                        type="text"
                        placeholder="Name"
                        value={ward.name}
                        isRequired={true}
                        onChange={inputChangeWard}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Anzahl der Räume</FormLabel>
                      <Input
                        name="size"
                        mb={4}
                        type="number"
                        placeholder="Size"
                        value={ward.size}
                        isRequired={true}
                        onChange={inputChangeWard}
                        min={1}
                        max={50}
                        step={1}
                      ></Input>
                    </FormControl>
                  </Box>

                </HStack>

                <HStack mt={"10"}>
                  {/** BUTTON FOR EDITTING Ward */}
                  {isLoading ? (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={true}
                      isLoading
                      spinnerPlacement="end"
                      loadingText="Station wird bearbeitet"
                    />
                  ) : (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={false}
                    >
                      Änderungen speichern
                    </Button>
                  )}
                </HStack>
              </form>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>


      <Modal
        size={"xl"}
        isCentered
        isOpen={showEditBed}
        visible={showEditBed}
        scrollBehavior={"inside"}
        onClose={() => {
          setShowEditBed(!showEditBed);
          navigateToStart();
        }}
        motionPreset="slideInLeft"
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(0deg)"
        />

        <ModalContent maxW={"900"} maxH={""} alignItems={"center"}>
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
            mt={"100"}
          >
            Bett bearbeiten
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="editBedBox">
            <Box my={"10"}>
              <form id="editBed"onSubmit={onSubmitBed}>
                <HStack spacing={30} alignItems="top">
                  <Box w={350} alignContent={"top"}>
                    <FormControl isRequired>
                      <FormLabel>Name</FormLabel>
                      <Input
                        name="name"
                        mb={4}
                        type="text"
                        placeholder="Name"
                        value={bed.name}
                        isRequired={true}
                        onChange={inputChangeBed}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Raum</FormLabel>
                      <Select
                        name="room"
                        mb={4}
                        placeholder="Wählen Sie einen Raum"
                        value={bed.room}
                        isRequired={true}
                        onChange={inputChangeBed}
                      >
                        {rooms.map((room) => (
                          <option value={room.id} key={room.id}>
                            {room.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </HStack>

                <HStack mt={"10"}>
                  {/** BUTTON FOR EDITTING bed */}
                  {isLoading ? (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={true}
                      isLoading
                      spinnerPlacement="end"
                      loadingText="Bett wird bearbeitet"
                    />
                  ) : (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={false}
                    >
                      Änderungen speichern
                    </Button>
                  )}
                </HStack>
              </form>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );

}

export default EditBaseDataComponent;
