import {useEffect, useState } from "react";
import emitter from "../util/event";

//import Chakra UI components
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  HStack,
} from "@chakra-ui/react";

import "../index.css";
import Bed from "../models/Bed";
import Room from "../models/Room";
import Ward from "../models/Ward";
import APICenter from "../apis/APICenter";
import APIUtils from "../apis/APIUtils";
import {FetchWardsMessage, FetchRoomsMessage} from "../apis/Message";
import {toast} from "react-toastify";



const AddBaseDataComponent = (state) => {
  const [showAddWard, setShowAddWard] = useState(false);
  const [showAddRoom, setShowAddRoom] = useState(false);
  const [showAddBed, setShowAddBed] = useState(false);

  const [ward, setWard] = useState(new Ward());
  const [wardState, setWardState] = useState(new Ward());
  const [wards, setWards] = useState([]);
  const [bed, setBed] = useState(new Bed());
  const [room, setRoom] = useState(new Room());
  const [roomState, setRoomState] = useState(new Room());
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [automaticCreateRequested, setAutomaticCreateRequested] = useState(false);

  const apiCenter = new APICenter();
  const {getData} = APIUtils();
  const wardStateId = state.wardState;
  const roomStateId = state.roomState; 
  const showWardAdd = state.showWardAdd;


    // fetch ward by ward id
  const fetchWardById = async () => {
        // Call fetchWardById API in apiCenter
        const msg = await apiCenter.fetchWardById(wardStateId);
        // If it is FetchPatientMessage Class, if not authorized then Message class
        if (msg && msg instanceof FetchWardsMessage) {
            // If the request succeeded with 200 status code
            if (msg.isSucc) {
                // Set Ward
                setWardState(msg.wards);
            }
        } else {
            // Failed msg in Message
            toast("Unable to fetch ward data by id!");
        }
    };

      // fetch room by room id
  const fetchRoomById = async () => {
        // Call fetchRoomById API in apiCenter
        const msg = await apiCenter.fetchRoomById(roomStateId);
        // If it is FetchPatientMessage Class, if not authorized then Message class
        if (msg && msg instanceof FetchRoomsMessage) {
            // If the request succeeded with 200 status code
            if (msg.isSucc) {
                // Set Room
                setRoomState(msg.rooms);
            }
        } else {
            // Failed msg in Message
            toast("Unable to fetch room data by id!");
        }
    };

  //get all wards for room adding form
  const getWards = async () => {
    const responseFetchWards = await getData("wards");
    if (responseFetchWards.isSuccessful) {
      setWards(responseFetchWards.data);
    }
  };

  const getRooms = async () => {
    const responseFetchRooms = await getData("rooms");
    if (responseFetchRooms.isSuccessful) {
      setRooms(responseFetchRooms.data);
    }
  };

  useEffect(() => {
    const callback = () => {
      setShowAddRoom(true);
    };
    emitter.on(`showAddRoom/${wardStateId}`, callback);
    fetchWardById();
    getWards();
    return () => {
      emitter.removeListener(`showAddRoom/${wardStateId}`, callback);
    };
  }, []);

  useEffect(() => {
    const callback = (e) => {
      setShowAddWard(e);
    };
    if(showWardAdd == true) { //make sure ward add form can only be called in AddBaseDataTableComponent
      emitter.on("showAddWard", callback);
      return () => {
        emitter.removeListener("showAddWard", callback);
      };
    }

  }, []);

  useEffect(() => {
   const callback = () => {
      setShowAddBed(true);
    };
    emitter.on(`showAddBed/${roomStateId}`, callback);
    fetchRoomById();
    getRooms();
    return () => {
      emitter.removeListener(`showAddBed/${roomStateId}`, callback);
    };
  }, []);

  const automaticCreateChange = (event) => {
    setAutomaticCreateRequested(event.target.checked);
  };

  const inputChangeRoom = (e) => {
    const element = e.target;
    room[element.name] = element.value;
    room.ward = wardStateId;
    setRoom(Object.assign(new Room(), room));
  };

  const inputChange = (e) => {
    const element = e.target;
    ward[element.name] = element.value;
    setWard(Object.assign(new Ward(), ward));
    console.log(ward)
  };

  const inputChangeBed = (e) => {
    const element = e.target;
    bed[element.name] = element.value;
    bed.room = roomStateId;
    setBed(Object.assign(new Bed(), bed));
  };

  const navigateToStart = () => {
    emitter.emit(
      "menuIdx",
      parseInt(window.localStorage.getItem("lastMenuIdx"))
    );
    setShowAddRoom(false);
    setShowAddWard(false);
    setShowAddBed(false);
    setAutomaticCreateRequested(false);
  };

  //submit room
  const onSubmitRoom = async (e) => {
    e.preventDefault(); //so it does not submit to a page

    //if name is not provided
    if (!room.name) {
      return;
    }
    //start the loading animation
    setIsLoading(true);
    const msg = await apiCenter.addRoom(
      room,
      automaticCreateRequested,
    );
    console.log(room);
    setIsLoading(false);

    if (msg) {
      navigateToStart();
      emitter.emit("refresh-ward");
    }
    emitter.emit("menuIdx", 0);
    setShowAddRoom(false);
  };

  //submit ward
  const onSubmitWard = async (e) => {
    e.preventDefault(); //so it does not submit to a page

    //if name is not provided
    if (!ward.name) {
      return;
    }
    //start the loading animation
    setIsLoading(true);
    const msg = await apiCenter.addWard(
      ward,
      automaticCreateRequested,
    );
    console.log(ward);
    setIsLoading(false);

    if (msg) {
      navigateToStart();
      emitter.emit("refresh-ward");
    }
    emitter.emit("menuIdx", 0);
    setShowAddWard(false);
  };

  //submit bed
  const onSubmitBed = async (e) => {
    e.preventDefault(); //so it does not submit to a page
    //if name is not provided
    if (!bed.name) {
      return;
    }
    //start the loading animation
    setIsLoading(true);
    const msg = await apiCenter.addBed(
      bed
    );
    console.log(bed);
    setIsLoading(false);

    if (msg) {
      navigateToStart();
      emitter.emit("refresh-ward");
    }
    emitter.emit("menuIdx", 0);
    setShowAddBed(false);
  };

  //define the size option for room add form
  const roomSizeOption = [
    {
      label: "1",
      value: 1
    },
    {
      label: "2",
      value: 2
    },
    {
      label: "3",
      value: 3
    },
    {
      label: "4",
      value: 4
    },
  ];
    return (
    <>
      <Modal
        size={"xl"}
        isCentered
        isOpen={showAddRoom}
        visible={showAddRoom}
        scrollBehavior={"inside"}
        onClose={() => {
          setShowAddRoom(!showAddRoom);
          navigateToStart();
        }}
        motionPreset="slideInLeft"
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(0deg)"
        />

        <ModalContent maxW={"900"} maxH={""} alignItems={"center"}>
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
            mt={"100"}
          >
            Raum anlegen
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addRoomBox">
            <Box my={"10"}>
              <form id="addRoom"onSubmit={onSubmitRoom}>
                <HStack spacing={30} alignItems="top">
                  <Box w={350} alignContent={"top"}>
                    <FormControl isRequired>
                      <FormLabel>Name</FormLabel>
                      <Input
                        name="name"
                        mb={4}
                        type="text"
                        placeholder="Name"
                        value={room.name}
                        isRequired={true}
                        onChange={inputChangeRoom}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Anzahl der Betten</FormLabel>
                      <Select 
                      name="size"
                      placeholder='Größe auswählen' 
                      value={room.size} 
                      onChange={inputChangeRoom}
                      isRequired={true}>
                      {roomSizeOption.map((sizeOption) => (
                          <option value={sizeOption.value}>
                            {sizeOption.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <br/>

                    <FormControl isRequired>
                      <FormLabel>Station</FormLabel>
                      <Select
                        name="ward"
                        mb={4}
                        placeholder="Wählen Sie eine Station"
                        //placeholder={wardState.name}
                        value={room.ward}
                        isRequired={true}
                        onChange={inputChangeRoom}
                      >
                        <option value={wardState.id} key={wardState.id}>
                          {wardState.name}
                        </option>
                      </Select>
                    </FormControl>
                  </Box>
                </HStack>

                <Text mt={5}> *Pflichtfelder </Text>
                <HStack mt={"10"}>
                  <FormControl>
                    <Checkbox
                      name="disturbs"
                      type="checkbox"
                      value={Boolean()}
                      onChange={automaticCreateChange}
                    >
                      Betten automatisch anlegen
                    </Checkbox>
                  </FormControl>

                  {/** BUTTON FOR ADDING room */}
                  {isLoading ? (
                    <Button
                      width="full"
                      type="submit"
                      colorScheme="brand"
                      disabled={true}
                      isLoading
                      spinnerPlacement="end"
                      loadingText="Raum wird angelegt"
                    />
                  ) : (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={false}
                    >
                      Raum anlegen
                    </Button>
                  )}
                </HStack>
              </form>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>

      <Modal
        size={"xl"}
        isCentered
        isOpen={showAddWard}
        visible={showAddWard}
        scrollBehavior={"inside"}
        onClose={() => {
          setShowAddWard(!showAddWard);
          navigateToStart();
        }}
        motionPreset="slideInLeft"
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(0deg)"
        />

        <ModalContent maxW={"900"} maxH={""} alignItems={"center"}>
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
            mt={"100"}
          >
            Station anlegen
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addWardBox">
            <Box my={"10"}>
              <form id="addWard"onSubmit={onSubmitWard}>
                <HStack spacing={30} alignItems="top">
                  <Box w={350} alignContent={"top"}>
                    <FormControl isRequired>
                      <FormLabel>Name</FormLabel>
                      <Input
                        name="name"
                        mb={4}
                        type="text"
                        placeholder="Name"
                        value={ward.name}
                        isRequired={true}
                        onChange={inputChange}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Anzahl der Räume</FormLabel>
                      <Input
                        name="size"
                        mb={4}
                        type="number"
                        placeholder="Size"
                        value={ward.size}
                        //defaultValue={ward.size}
                        isRequired={true}
                        onChange={inputChange}
                        step={1}
                        max={50}
                        min={1}
                      >
                      </Input>
                    </FormControl>
                  </Box>
                </HStack>

                <Text mt={5}> *Pflichtfelder </Text>
                <HStack mt={"10"}>
                   <FormControl>
                    <Checkbox
                      name="disturbs"
                      mb={4}
                      type="checkbox"
                      value={Boolean()}
                      onChange={automaticCreateChange}
                    >
                      Leere Räume automatisch anlegen
                    </Checkbox>
                  </FormControl>
                  {/** BUTTON FOR ADDING Ward */}
                  {isLoading ? (
                    <Button
                      width="full"
                      type="submit"
                      colorScheme="brand"
                      disabled={true}
                      isLoading
                      spinnerPlacement="end"
                      loadingText="Station wird angelegt"
                    />
                  ) : (
                    <Button
                      width="full"
                      type="submit"
                      colorScheme="brand"
                      disabled={false}
                    >
                      Station anlegen
                    </Button>
                  )}
                </HStack>
              </form>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>

      <Modal
        size={"xl"}
        isCentered
        isOpen={showAddBed}
        visible={showAddBed}
        scrollBehavior={"inside"}
        onClose={() => {
          setShowAddBed(!showAddBed);
          navigateToStart();
        }}
        motionPreset="slideInLeft"
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(0deg)"
        />

        <ModalContent maxW={"900"} maxH={""} alignItems={"center"}>
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
            mt={"100"}
          >
            Bett anlegen
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addBedBox">
            <Box my={"10"}>
              <form id="addBed"onSubmit={onSubmitBed}>
                <HStack spacing={30} alignItems="top">
                  <Box w={350} alignContent={"top"}>
                    <FormControl isRequired>
                      <FormLabel>Name</FormLabel>
                      <Input
                        name="name"
                        mb={4}
                        type="text"
                        placeholder="Name"
                        value={bed.name}
                        isRequired={true}
                        onChange={inputChangeBed}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Raum</FormLabel>
                      <Select
                        name="room"
                        mb={4}
                        //placeholder="Wählen Sie einen Raum"
                        //placeholder={roomState.name}
                        placeholder={null}
                        value={bed.room}
                        isRequired={true}
                        onChange={inputChangeBed}
                      >
                        <option value={roomState.id} key={roomState.id}>
                          {roomState.name}
                        </option>
                      </Select>
                    </FormControl>
                  </Box>

                </HStack>

                <HStack mt={"10"}>
                  {/** BUTTON FOR ADDING Bed */}
                  {isLoading ? (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={true}
                      isLoading
                      spinnerPlacement="end"
                      loadingText="Bett wird angelegt"
                    />
                  ) : (
                    <Button
                      width="full"
                      mt={4}
                      type="submit"
                      colorScheme="brand"
                      disabled={false}
                    >
                      Bett anlegen
                    </Button>
                  )}
                </HStack>
              </form>
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );

}

export default AddBaseDataComponent;
