export const renderDateTime = (d: string) =>
  new Date(d)
    .toLocaleString("de-DE", {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    })
    .replace(",", "")

export const renderDaysUntilEnd = (d: string) => {
  const endDate = Math.ceil(
    (new Date(d).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24)
  )
  if (endDate >= 1) {
    return `In ${endDate} Tag${endDate === 1 ? "" : "en"}`
  }
  if (endDate <= -1) {
    return `Vor ${-endDate} Tag${endDate === -1 ? "" : "en"}`
  }
  return "Heute"
}

export const renderPatientGender = (gender: string) => {
    if (gender === "M") {
      return "Männlich"
    }
    if (gender === "F") {
      return "Weiblich"
    }
    if (gender === "X") {
      return "Divers"
    }
    return gender
  }
  
export const renderPatientStatus = (status: string) => {
    if (status === "F") {
      return "Fix"
    }
    if (status === "R") {
      return "Reserviert"
    }
    if (status === "P") {
      return "Vorläufig"
    }
    if (status === "W") {
      return "Nicht Belegt"
    }
    return status
  }

  export const renderPatientBirthDate = (birthdate: string): string => {
    const parts = birthdate.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

  return `${day}.${month}.${year}`;
}
  