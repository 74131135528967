import React, { useEffect, useState, useContext } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import dayjs from "dayjs";
import NotAssignedAreaComponent from "./NotAssignedAreaComponent";
import APIUtils from "../apis/APIUtils";
import WardComponent from "./WardComponent";
import emitter from '../util/event'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Box,
  VStack,
  HStack,
  useDisclosure,
  Collapse,
  Flex,
  Spacer,
  Icon,
  Text,
  Divider,
  IconButton,
} from "@chakra-ui/react";
import { isSameDay } from "date-fns";
import { getReasonFailed, getComment } from "./ApiHelpers";
import {
  getMovementHistoryFromCookie,
  getFutureHistoryFromCookie,
  resetFutureHistory,
  resetMovementHistory,
  undoKey,
  redoKey,
  setMovementHistory,
  handleUndoSuccess,
  handleRedoSuccess,
  MovementHistory,
} from "./UndoRedo";
import SwitchWardPanelComponent from "./SwitchWardPanelComponent";
import StatisticsSummaryComponent from "./StatisticSummaryComponent";
import DatePicker from "./date-picker/DatePicker";

import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";

import AuthContext from "../context/AuthContext";
import HeadingTitle from "./common/HeadingTitle";
import OpaqueButton from "./common/OpaqueButton";
import Stats from "../models/Stats";
import { useWards } from "../context/WardsContext";
import TransferRequest from "../models/TransferRequest";
import Suggestion from "../models/Suggestion";
import { Assignment } from "../models/Assignment";
import Bed from "../models/Bed";
import Ward from "../models/Ward";
import Room from "../models/Room";

const getOpacity = (arr: MovementHistory[]) => (arr.length > 0 ? 1 : 0.5);

type UndoRedoActionsProps = {
  onUndo: () => void;
  onRedo: () => void;
};

const UndoRedoActions = (props: UndoRedoActionsProps) => (
  <Box display="flex" alignItems="center" mr={1}>
    <OpaqueButton
      opacity={getOpacity(getMovementHistoryFromCookie())}
      onClick={props.onUndo}
    >
      Rückgängig machen
    </OpaqueButton>
    <OpaqueButton
      opacity={getOpacity(getFutureHistoryFromCookie())}
      onClick={props.onRedo}
    >
      Wiederherstellen
    </OpaqueButton>
  </Box>
);

type UserProfileProps = {
  userName: string;
  onLogout: () => void;
};

const UserProfile = (props: UserProfileProps) => (
  <HStack spacing={2} ml={2}>
    <Icon as={FaIcons.FaUserAlt} />
    <Text color="black">{props.userName}</Text>
    <Divider orientation="vertical" />
    <IconButton
      size="sm"
      isRound
      variant="ghost"
      aria-label="Ausloggen"
      icon={<MdIcons.MdLogout />}
      onClick={props.onLogout}
    />
  </HStack>
);

type AssignedAreaComponentProps = {
  assignments: Assignment[];
  rooms: Room[];
  beds: Bed[];
  wards: Ward[];
  wardsSelected: number[];
  suggestion: Suggestion[];
  suggestionPatientId: number | null;
  modifyAssignmentsInState: (as: string, be: string) => void;
  assignmentsFromServerToState: () => Promise<void>;
  selectedDate: Date;
  isDragEnabled: boolean;
  setSelectedDate: (date: Date) => void;
  username?: string;
  stats: Stats;
  setWardsSelected: (wards: number[]) => void;
};

// Main component in RoomComponent
const AssignedAreaComponent = ({
  assignments,
  rooms,
  beds,
  wards,
  wardsSelected,
  suggestion,
  suggestionPatientId,
  modifyAssignmentsInState,
  assignmentsFromServerToState,
  selectedDate,
  isDragEnabled,
  setSelectedDate,
  username,
  stats,
  setWardsSelected,
}) => {

  //if a patient is dragged to an unsuitable bed, the reason is stored in this variable
  const [errorMessage, setErrorMessage] = useState("");
  //get the functions and variables to control the opening and closing of the alert dialog (change bed aborted)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const wards_map = new Map<number, Room[]>();

  // for each ward in wards
  for (const ward of wards) {
    wards_map.set(ward.id, []);
  }

  // for each room in rooms
  for (const room of rooms) {
    // get ward if of room
    const ward_id = room.ward.id;
    wards_map.get(ward_id)?.push(room);
  }

  const _wards = [...wards_map].map(([key, value]) => ({ key, value }));
  const { assignPatientToBed, removePatientFromBed } = APIUtils();
  const { user, logoutUser } = useContext(AuthContext);

  const [sourceDroppableId, setSourceDroppableId] = useState('');

  const onDragStart = (result) => {
    setSourceDroppableId(result.source.droppableId.split(";")[0])
  };

  //functions called after a patent is dragged to another bed
  const onDragEnd = (result) => {
    // console.log(result);

    //when a patient is dropped somewhere
    //get the patient id and the id of the bed (or no of dropped to "not assigned")
    const { destination, draggableId, source } = result;
    if (destination && draggableId) {
      const toBed = destination.droppableId.split(";")[0];
      const fromBed = source.droppableId.split(";")[0];
      const date = selectedDate;
      const assignmentId = draggableId.split(";")[0];
      const patientId = draggableId.split(";")[1];

      if (destination.droppableId === "outReq") {
        // open a panel to create transfer requests
        const assigment_transRequ = assignments.find((a) => { return a.id == assignmentId })
        emitter.emit('showTransferRequest', "sender", assigment_transRequ, suggestion, suggestionPatientId, selectedDate);
      } else {

        //immediately update the state of the frontend
        //this is an optimistic update. If the backend finds that the assignment is not possible it will show an error and revert it
        //this function is implemented in the parent of this component, as the state is stored there
        modifyAssignmentsInState(assignmentId, toBed);

        //the new assignment is sent to the backend where it is checked and saved to the database
        updatePatientAssignment(
          assignmentId,
          toBed,
          undefined,
          undefined,
          undefined
        ).then(() => {
          // save movement to cookie
          const movementHistory = getMovementHistoryFromCookie();

          resetFutureHistory();

          if (movementHistory.length === 5) {
            movementHistory.shift();
          }

          movementHistory.push({
            assignmentId: assignmentId,
            patientId: patientId,
            fromBed: fromBed,
            toBed: toBed,
            date: date,
          });

          setMovementHistory(movementHistory);
        });
      }
    }
  };

  const updatePatientAssignment = async (
    assignmentId,
    bedId,
    undo,
    movementHistory,
    lastMove
  ) => {
    // check if patient is dropped to the "not assigned" area
    if (bedId === "no") {
      await removePatientFromBed(assignmentId, dayjs(selectedDate));
      if (undo) {
        if (undo === undoKey) {
          handleUndoSuccess(movementHistory, lastMove);
          setErrorMessage(getComment(undoKey));
        } else if (undo === redoKey) {
          handleRedoSuccess(movementHistory, lastMove);
          setErrorMessage(getComment(redoKey));
        }
        onOpen();
      }
    } else {
      // assign patient to bed
      const result = await assignPatientToBed(
        assignmentId,
        bedId,
        dayjs(selectedDate)
      );
      if (!result.isSuccessful && result.reason) {
        // if the assignment failed, render the reason for the failure in the alert dialog
        setErrorMessage(getReasonFailed(result.reason));
        if (undo === undoKey) {
          undoFail();
        } else if (undo === redoKey) {
          redoFail();
        } else {
          onOpen();
        }
      }
      if (result.isSuccessful && result.comment && !undo) {
        // if the assignment failed, render the reason for the failure in the alert dialog
        setErrorMessage(getComment(result.comment));
        onOpen();
      } else if (result.isSuccessful && undo) {
        if (undo === undoKey) {
          handleUndoSuccess(movementHistory, lastMove);
          setErrorMessage(getComment(undoKey));
          onOpen();
        } else if (undo === redoKey) {
          handleRedoSuccess(movementHistory, lastMove);
          setErrorMessage(getComment(redoKey));
          onOpen();
        }
      }
    }
    await assignmentsFromServerToState();
  };

  function keydownEvent(event: KeyboardEvent) {
    if (
      (event.metaKey && event.key === "z" && event.shiftKey) ||
      (event.ctrlKey && event.key === "y")
    ) {
      redo();
    } else if ((event.ctrlKey || event.metaKey) && event.key === "z") {
      undo();
    }
  }

  // Add event listener for undo and redo keyboard controls
  useEffect(() => {
    document.addEventListener("keydown", keydownEvent);
    return () => {
      document.removeEventListener("keydown", keydownEvent);
    };
  }, [keydownEvent]);

  function getPatientAssignmentId(patientId): number | undefined {
    let assignment: { assignmentId?: number, date?: string } = {};
    if (assignments) {
      for (const bedAssignment of assignments) {
        if (bedAssignment.patient.id.toString() === patientId) {
          assignment = {
            assignmentId: bedAssignment.id,
            date: bedAssignment.start,
          };
        }
      }
    }
    return assignment.assignmentId;
  }

  function handleUndoRedoFail(undoRedo) {
    setErrorMessage(getReasonFailed(undoRedo));
    onOpen();
  }

  function undoFail() {
    resetMovementHistory();
    handleUndoRedoFail(undoKey);
  }

  function redoFail() {
    resetFutureHistory();
    handleUndoRedoFail(redoKey);
  }

  function undo() {
    const movementHistory = getMovementHistoryFromCookie();
    if (movementHistory.length > 0) {
      const lastMove = movementHistory.pop();
      if (
        lastMove &&
        isSameDay(new Date(lastMove.date), selectedDate)
      ) {
        const assignmentId = getPatientAssignmentId(lastMove.patientId);
        if (assignmentId !== undefined) {
          modifyAssignmentsInState(
            assignmentId.toString(),
            !parseInt(lastMove.fromBed) ? "no" : lastMove.fromBed
          );
          updatePatientAssignment(
            assignmentId,
            !parseInt(lastMove.fromBed) ? "no" : lastMove.fromBed,
            undoKey,
            movementHistory,
            lastMove
          );
        } else {
          undoFail();
        }
      } else {
        undoFail();
      }
    } else {
      undoFail();
    }
  }

  function redo() {
    const futureHistory = getFutureHistoryFromCookie();
    if (futureHistory.length > 0) {
      const lastMove = futureHistory.pop();
      if (
        lastMove &&
        isSameDay(new Date(lastMove.date), selectedDate)
      ) {
        const assignmentId = getPatientAssignmentId(lastMove.patientId);
        if (assignmentId !== undefined) {
          modifyAssignmentsInState(
            assignmentId.toString(),
            !parseInt(lastMove.toBed) ? "no" : lastMove.toBed
          );
          updatePatientAssignment(
            assignmentId,
            !parseInt(lastMove.toBed) ? "no" : lastMove.toBed,
            redoKey,
            futureHistory,
            lastMove
          );
        } else {
          redoFail();
        }
      } else {
        redoFail();
      }
    } else {
      redoFail();
    }
  }


  const [todayToggle, setTodayToggle] = useState(true);
  function handleOnChange() {
    setTodayToggle(!todayToggle);
    // console.log(todayToggle);
  }

  //backend only visible rooms and visible beds
  //concats the rooms of a ward with the extern rooms of other wards, where a patient lies that belongs to this ward
  function getVisibleRooms(ward_key) {

    return rooms
      .filter(r => r.ward.id === ward_key)
      .concat(assignments
        .filter(a => a.bed && a.bed.room && a.bed.room.ward.id !== ward_key && a.patient && a.patient.home_ward === ward_key)
        .map(a => a.bed.room))
      .reduce((acc, r) => {
        if (!acc.find(room => room.id === r.id)) {
          acc.push(r);
        }
        return acc;
      }, []);
  }
  return (
    <VStack spacing={3} ml={0} mr={0} mt={10}>
      <Flex width="100%" justifyItems="bottom" alignItems="flex-end">
        <HeadingTitle>Patientenbelegung</HeadingTitle>
        <Spacer />
        <OpaqueButton
          opacity={1}
          onClick={handleOnChange}>
          Heute
        </OpaqueButton>
        <Flex>
          <UndoRedoActions onRedo={redo} onUndo={undo} />
          <SwitchWardPanelComponent
            wards={wards}
            wardsSelected={wardsSelected}
            setWardsSelected={setWardsSelected}
            username={username ?? "anonym"}
          />
          <UserProfile userName={user} onLogout={logoutUser} />
        </Flex>
      </Flex>

      <StatisticsSummaryComponent stats={stats} />

      <DatePicker
        selectedDate={dayjs(selectedDate)}
        setSelectedDate={d => setSelectedDate(d.toDate())}
        todayToggle={todayToggle}
      />

      <HStack spacing={0} w="full">
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Flex alignItems={"stretch"}>
            <Box>
              {_wards.map((ward) => (
                //ward.key = ward id, ward.value = list of rooms
                <Collapse
                  key={ward.key}
                  in={wardsSelected.includes(ward.key)}
                  animateOpacity
                >
                  <WardComponent
                    key={ward.key}
                    ward={wards.find(w => w.id === ward.key)}
                    wardRooms={getVisibleRooms(ward.key)}
                    beds={beds}
                    assignments={assignments.filter(a => a.assignment_status !== "W" && (a.bed ?? false))}
                    isDragEnabled={isDragEnabled}
                    selectedDate={selectedDate}
                  />
                </Collapse>
              ))}
            </Box>

            <Spacer />

            <NotAssignedAreaComponent
              assignments={assignments.filter(a => a.assignment_status === "W")}
              suggestion={suggestion}
              suggestionPatientId={suggestionPatientId}
              isDragEnabled={isDragEnabled}
              selectedDate={selectedDate}
              sourceDroppableId={sourceDroppableId}
              wardsSelected={wardsSelected}
            />
          </Flex>

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Achtung
                </AlertDialogHeader>

                <AlertDialogBody>{errorMessage}</AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Okay
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </DragDropContext>
      </HStack>
    </VStack>
  );
};

export default AssignedAreaComponent;
