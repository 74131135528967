import dayjs from "dayjs";
import Cookies from "js-cookie";

const movementHistoryCookie = "movementHistory";
const futureHistoryCookie = "futureHistory";
export const undoKey = "undo";
export const redoKey = "redo";

export interface MovementHistory {
  patientId: string;
  assignmentId: number;
  fromBed: string;
  toBed: string;
  date: Date;
}

function getHistoryFromCookie(cookieName: string): MovementHistory[] {
  const movementHistory = Cookies.get(cookieName);
  return movementHistory ? JSON.parse(movementHistory) : [];
}

export function getMovementHistoryFromCookie(): MovementHistory[] {
  return getHistoryFromCookie(movementHistoryCookie);
}

export function getFutureHistoryFromCookie(): MovementHistory[] {
  return getHistoryFromCookie(futureHistoryCookie);
}

function resetHistory(cookieName: string): void {
  Cookies.remove(cookieName);
}

export function resetMovementHistory(): void {
  resetHistory(movementHistoryCookie);
}

export function resetFutureHistory(): void {
  resetHistory(futureHistoryCookie);
}

export function setMovementHistory(movementHistory: MovementHistory[]): void {
  Cookies.set(movementHistoryCookie, JSON.stringify(movementHistory));
}

export function setFutureHistory(futureHistory: MovementHistory[]): void {
  Cookies.set(futureHistoryCookie, JSON.stringify(futureHistory));
}

export function undoMove(
  undoFail: () => void,
  updatePatientAssignment: (
    assignmentId: number,
    fromBed: string,
    date: dayjs.Dayjs,
    undo: string,
    movementHistory: MovementHistory[],
    lastMove: MovementHistory
  ) => Promise<any>
) {
  const movementHistory = getMovementHistoryFromCookie();
  if (movementHistory && movementHistory.length > 0) {
    const lastMove = movementHistory.pop();
    if (lastMove && lastMove.assignmentId && lastMove.assignmentId !== -1) {
      updatePatientAssignment(
        lastMove.assignmentId,
        !parseInt(lastMove.fromBed) ? "no" : lastMove.fromBed,
        dayjs(lastMove.date),
        undoKey,
        movementHistory,
        lastMove
      );
    } else {
      undoFail();
    }
  } else {
    undoFail();
  }
}

export function redoMove(
  redoFail: () => void,
  updatePatientAssignment: (
    assignmentId: number,
    fromBed: string,
    date: dayjs.Dayjs,
    undo: string,
    movementHistory: MovementHistory[],
    lastMove: MovementHistory
  ) => Promise<any>
) {
  const futureHistory = getFutureHistoryFromCookie();
  if (futureHistory.length > 0) {
    const lastMove = futureHistory.pop();
    if (lastMove && lastMove.assignmentId && lastMove.assignmentId !== -1) {
      updatePatientAssignment(
        lastMove.assignmentId,
        !parseInt(lastMove.toBed) ? "no" : lastMove.toBed,
        dayjs(lastMove.date),
        redoKey,
        futureHistory,
        lastMove
      );
    } else {
      redoFail();
    }
  } else {
    redoFail();
  }
}

export function handleUndoSuccess(
  movementHistory: MovementHistory[],
  lastMove: MovementHistory
) {
  setMovementHistory(movementHistory);
  const futureHistory = getFutureHistoryFromCookie();
  futureHistory.push(lastMove);
  setFutureHistory(futureHistory);
}

export function handleRedoSuccess(
  futureHistory: MovementHistory[],
  lastMove: MovementHistory
) {
  setFutureHistory(futureHistory);
  const movementHistory = getMovementHistoryFromCookie();
  movementHistory.push(lastMove);
  if (movementHistory.length > 5) {
    movementHistory.shift();
  }
  setMovementHistory(movementHistory);
}
