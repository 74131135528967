import Cookies from "js-cookie";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";
import { Box, Text, Button } from "@chakra-ui/react";

import { Arrow } from "./Arrow";

import "./DatePicker.css";

const DatePicker = (props) => {

  useEffect(() => {
    // console.log("In Child: " + props.todayToggle);
    handleTodayClick(); 
  }, [props.todayToggle]);

  const currentDate = dayjs().hour(0).minute(0).second(0).millisecond(0);

  const [dates, setDates] = useState(getInitialDates(currentDate));
  const apiRef = useRef();


  const scrollWithDelay = (date) => {
    setTimeout(() => {
      scrollToDate(date, "auto", "start");
    });
  };

  const scrollToDate = (date, behaviour, position) => {
    apiRef.current.scrollToItem(
      apiRef.current.getItemById(date.format("YYYY-MM-DD")),
      behaviour,
      position
    );
  };

  const scrollBackwards = (firstVisibleDate) => {
    // add dates of previous month to the date picker
    if (firstVisibleDate.diff(dates[0], "month") < 1) {
      setDates(addDatesOfPrevMonth(dates));
      scrollWithDelay(firstVisibleDate);
    } else {
      scrollToDate(firstVisibleDate, "smooth", "start");
    }
  };

  const scrollForward = (firstVisibleDate, lastVisibleDate) => {
    // add dates of next month to the date picker if necessary
    if (dates.at(-1).diff(lastVisibleDate, "month") < 1) {
      setDates(addDatesOfNextMonth(dates));
    }
    scrollToDate(firstVisibleDate, "smooth", "start");
  };

  const scrollToPrevDate = () => {
    const previousDate = dayjs(apiRef.current.visibleElements[0]).subtract(
      1,
      "day"
    );
    scrollBackwards(previousDate);
  };

  const restorePosition = () => {
    const dateToRestore = !!Cookies.get("medigital_selectedDate")
      ? dayjs(Cookies.get("medigital_selectedDate"), "YYYY-MM-DD")
      : currentDate;
    props.setSelectedDate(dateToRestore);
    Cookies.set("medigital_selectedDate", dateToRestore.format("YYYY-MM-DD"));

    const diff = dateToRestore.diff(currentDate, "month");
    if (diff === 0) {
      scrollToDate(dateToRestore, "smooth", "center");
    } else {
      if (diff < 0) {
        setDates(addDatesOfPrevMonth(dates, Math.abs(diff)));
      } else {
        setDates(addDatesOfNextMonth(dates, diff));
      }
      setTimeout(() => {
        scrollToDate(dateToRestore, "auto", "center");
      }, 400);
    }
  };

  const scrollToNextDate = () => {
    const newFirstDate = dayjs(apiRef.current.visibleElements[0]).add(1, "day");
    const newLastDate = dayjs(apiRef.current.visibleElements.at(-1)).add(
      1,
      "day"
    );
    scrollForward(newFirstDate, newLastDate);
  };

  const handleDateClick = (date) => {
    if (date.isSame(props.selectedDate, "day")) {
      return false;
    }

    props.setSelectedDate(date);
    Cookies.set("medigital_selectedDate", date.format("YYYY-MM-DD"));

    const middlePosition = Math.floor(
      apiRef.current.visibleElements.length / 2
    ); // middle position of the date picker
    const firstVisibleDate = date.subtract(middlePosition, "day");
    
    const diff = date.diff(props.selectedDate, "month");
    if (diff === 0) {
      scrollToDate(date, "smooth", "center");
    } else if (diff < 0) {
      setDates(addDatesOfPrevMonth(dates, Math.abs(diff)));
      scrollWithDelay(firstVisibleDate);
    } else {
      const lastVisibleDate = firstVisibleDate.add(apiRef.current.visibleElements.length - 1, "day");
      scrollForward(firstVisibleDate, lastVisibleDate);
    }
  };

  const isDateSelected = (date) => {
    if (props.selectedDate) {
      return props.selectedDate.isSame(date, "day");
    }
    return false;
  };

  const isMonthHidden = (date) => {
    const firstVisibleDate = dayjs(apiRef.current.visibleElements[0]);
    if (
      firstVisibleDate.isSame(date, "day") &&
      firstVisibleDate.date() / firstVisibleDate.daysInMonth() < 0.9
    ) {
      return false;
    }
    if (firstVisibleDate.isBefore(date, "month") && date.date() === 1) {
      return false;
    }
    return true;
  };


  const DateItem = ({ dateItem, isSelected }) => {
    return (
      <Box>
        <Text
          className={`datepickerMonth ${
            isMonthHidden(dateItem) ? "hidden" : ""
          }`}
          width="30px"
        >
          {dateItem.format("MMMM YYYY")}
        </Text>
        <Box className="datepickerItem">
          <Box
            className={`datepickerDate ${
              currentDate.isSame(dateItem, "day") && "today"
            }`}
            bg={isSelected ? "#37b7c7" : "transparent"}
            borderRadius="8"
            mx="1"
            width="30px"
            onClick={() => handleDateClick(dateItem)}
          >
            <Text
              className={`datepickerDay ${isSelected && "selected"}`}
              fontSize="lg"
            >
              {dateItem.format("D")}
            </Text>
            <Text
              className={`datepickerWeekday ${isSelected && "selected"}`}
              fontSize="xs"
            >
              {dateItem.format("dd")}
            </Text>
          </Box>
          {currentDate.isSame(dateItem, "day") && (
            <Text my="-1" fontWeight="black">
              ·
            </Text>
          )}
        </Box>
      </Box>
    );
  };

  const LeftArrow = () => {
    return (
      <Arrow
        isDisabled={
          !apiRef.current.initComplete ||
          (apiRef.current.initComplete && apiRef.current.isFirstItemVisible)
        }
        onClick={scrollToPrevDate}
        icon={<TiChevronLeft />}
        ariaLabel="Scroll to previous date"
      ></Arrow>
    );
  };

  const RightArrow = () => {
    return (
      <Arrow
        isDisabled={
          !apiRef.current.initComplete ||
          (apiRef.current.initComplete && apiRef.current.isLastItemVisible)
        }
        onClick={scrollToNextDate}
        icon={<TiChevronRight />}
        ariaLabel="Scroll to next date"
      ></Arrow>
    );
  };

  const handleTodayClick = () => {
    const today = dayjs().hour(0).minute(0).second(0).millisecond(0);
  
    // Set the selected date
    props.setSelectedDate(today);
    Cookies.set("medigital_selectedDate", today.format("YYYY-MM-DD"));
  
    // Calculate the middle position based on the updated selected date
    const middlePosition = Math.floor(apiRef.current.visibleElements.length / 2);
    const firstVisibleDate = today.subtract(middlePosition, "day");
  
    // Scroll to the updated selected date
    scrollWithDelay(firstVisibleDate);
  };

  return (
      <Box
        className="datepicker"
        width={"100%"}
      >

        <ScrollMenu
          apiRef={apiRef}
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onInit={restorePosition}
        >
          {dates.map((date) => (
            <DateItem
              dateItem={date}
              itemId={date.format("YYYY-MM-DD")}
              key={date}
              isSelected={isDateSelected(date)}
            />
          ))}
        </ScrollMenu>
      </Box>
  );
};

const getInitialDates = (currentDate) => {
  const dates = [];
  const previousTwoMonth = currentDate.subtract(1, "month");
  for (let i = 0; i < 3; i++) {
    const month = previousTwoMonth.add(i, "month");
    for (let j = 1; j <= month.daysInMonth(); j++) {
      dates.push(month.date(j));
    }
  }
  return dates;
};

const addDatesOfPrevMonth = (dates, num = 1) => {
  const newDates = [];
  let prevMonth = dates[0].subtract(num + 1, "month");
  for (let i = 0; i < num; i++) {
    prevMonth = prevMonth.add(1, "month");
    for (let j = 1; j <= prevMonth.daysInMonth(); j++) {
      newDates.push(prevMonth.date(j));
    }
  }
  return [...newDates, ...dates];
};

const addDatesOfNextMonth = (dates, num = 1) => {
  const newDates = [];
  let nextMonth = dates.at(-1);
  for (let i = 0; i < num; i++) {
    nextMonth = nextMonth.add(1, "month");
    for (let j = 1; j <= nextMonth.daysInMonth(); j++) {
      newDates.push(nextMonth.date(j));
    }
  }
  return [...dates, ...newDates];
};

export default DatePicker;
