import {
  faMars,
  faVenus,
  faTransgender,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import * as types from "../calendar-types";
import { Avatar } from "./avatar";
import { divider } from "../calendarHelpers";
import { DisturbsIcon, InsuranceIcon } from "../../../theme/icons";

function PatientInfo({
  event,
  patient,
  bgColor,
  fixName,
}: {
  event: types.Event;
  patient: types.Patient;
  bgColor: string;
  fixName?: boolean;
}) {
  return (
    <div className={`flex items-center h-full ${fixName ? "w-[200px]" : ""}`}>
      {!!patient.avatarUrl && (
        <div className="flex items-center">
          <Avatar color={patient.statusColor} src={patient.avatarUrl} />
          {divider}
        </div>
      )}
      {bgColor !== "" && (
        <div className={`${bgColor} w-2 h-4 rounded-md flex mr-1`}></div>
      )}
      <div className={`text-[#0F4046] min-w-max ${event.assignment_proposed ? "font-bold" : ""}`}>{patient.name}</div>
      <DisturbsIcon
        ml="4px"
        color="#0F4046"
        opacity={patient.disturbs ? 1 : 0.25}
      />
      <InsuranceIcon
        ml="4px"
        color="#0F4046"
        opacity={patient.private_insurance ? 1 : 0.25}
      />
    </div>
  );
}

export { PatientInfo };
