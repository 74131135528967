import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//import Chakra UI components
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  HStack,
  Textarea
} from '@chakra-ui/react'
import AuthContext from '../context/AuthContext'
import Patient from '../models/Patient'
import { Assignment } from '../models/Assignment'
import APICenter from '../apis/APICenter'
import emitter from '../util/event'

import APIUtils from '../apis/APIUtils'


// EditPatient Modal popup in PatientView
const EditPatientComponent = (state) => {
  const assignmentState = state.assignmentState
  const patientState = state.patientState
  const homeWard = state.homeWard
  patientState.homeWard = homeWard.id
  const fetchPatient = state.fetchPatient

  const navigate = useNavigate()

  // Listen editPatient from PatientView
  useEffect(() => {
    const callback = () => {
      setShowEditPatient(true)
    }
    emitter.on('editPatient', callback)

    return () => {
      emitter.removeListener('editPatient', callback)
    }
  }, [])

  // For copy the object instance properties
  const copyObject = (src, tar) => {
    for (const [key, value] of Object.entries(src)) {
      tar[key] = value
    }
  }

  // If the property of the patient instance changes
  const patientChange = (e) => {
    const element = e.target
    patient[element.name] = element.value
    setPatient(Object.assign(new Patient(), patient))
  }

  // If the boolean property of the patient instance changes
  const togglePatientBooleanChange = (e) => {
    const element = e.target
    patient[element.name] = !Boolean(patient[element.name])
    setPatient(Object.assign(new Patient(), patient))
  }

  const [showEditPatient, setShowEditPatient] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showChangeStatus, setShowChangeStatus] = useState(false)

  const [patient, setPatient] = useState(new Patient())
  const [assignment, setAssignment] = useState(new Assignment())

  const { authCenter } = useContext(AuthContext)
  const apiCenter = new APICenter()

  //get all wards
  const [wards, setWards] = useState([])
  const { getData, getSuggestions } = APIUtils()

  const getWards = async () => {
    const responseFetchWards = await getData('wards')
    if (responseFetchWards.isSuccessful) {
      setWards(responseFetchWards.data)
    }
  }

  useEffect(() => {
    getWards()

    patientState.home_ward = homeWard.id

    copyObject(patientState, patient)
    copyObject(assignmentState, assignment)

    setShowDelete(checkIfDeleteButton)

    // setAssignmentStatus(assignmentState.assignment_status)
    // setAssignmentEnd(new Date(assignmentState.end).toISOString().slice(0, -8))
    //remove the seconds and milliseconds from the string, as we don't plan on that level of detail
  }, [])

  const checkIfDeleteButton = () => {
    return assignmentState.assignment_status !== 'F'
  }

  const navigateToStart = () => {
    let path = `/room_view`
    navigate(path)
  }

  const navigateToPatient = () => {
    setShowEditPatient(false)
    navigate(`/patient/${patientState.id}`)
  }

  const deletePatient = async () => {
    const msg = await apiCenter.deletePatient(patient.id)
    console.log(msg)
    if (msg && msg.isSucc) {
      navigateToStart()
    } else {
      authCenter.logout()
      navigate('/')
    }
  }

  const onSubmit = async (e) => {
    //start the loading animation
    setIsLoading(true)

    e.preventDefault() //so it does not submit to a page

    const msg = await apiCenter.setPatient(patient)

    setIsLoading(false)
    if (msg) {
      if (msg.isSucc) {
        fetchPatient()
        navigateToPatient()
      } else if (msg.infoText === 'Unauthorized') {
        authCenter.logout()
        navigate('/')
      } else {
        alert('Internal Error')
      }
    }
  }

  return (
    <>
      <Modal
        size={"3xl"}
        isCentered
        isOpen={showEditPatient}
        scrollBehavior={'inside'}
        onClose={() => {
          navigateToPatient()
        }}
        motionPreset='slideInLeft'>

        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px) hue-rotate(0deg)'
        />

        <ModalContent
          maxW={"900"}
          maxH={""}
          alignItems={"center"}>
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
            mt={"100"}>
            Patient {patientState.firstname} {patientState.lastname} bearbeiten
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addPatientBox">


            <Box my={"10"}>
              <form onSubmit={onSubmit}>
                <HStack spacing={30} alignItems="top">
                  <Box w={350} alignContent={"top"} >
                    <FormControl isRequired>

                      <FormLabel>Vorname des Patienten</FormLabel>
                      <Input
                        name='firstname'
                        mb={4}
                        type='text'
                        placeholder='Vorname des Patienten'
                        value={patient.firstname}
                        isRequired={true}
                        onChange={patientChange}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Name des Patienten</FormLabel>
                      <Input
                        name='lastname'
                        mb={4}
                        type='text'
                        placeholder='Name des Patienten'
                        value={patient.lastname}
                        isRequired={true}
                        onChange={patientChange}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Geburtsdatum</FormLabel>
                      <Input
                        name='birth_date'
                        mb={4}
                        type='date'
                        value={patient.birth_date}
                        isRequired={true}
                        onChange={patientChange}
                        max="9999-12-31"
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Geschlecht des Patienten</FormLabel>
                      <Select
                        name='gender'
                        mb={4}
                        placeholder='Wählen Sie ein Geschlecht'
                        value={patient.gender}
                        isRequired={true}
                        onChange={patientChange}
                      >
                        <option value='M'>Männlich</option>
                        <option value='F'>Weiblich</option>
                        {/** Not allowed up to date */}
                        {/*<option value='X'>Divers</option>*/}
                      </Select>
                    </FormControl>

                  </Box>

                  <Box w={350} alignContent={"top"} >
                    <FormControl>
                      <FormLabel>DRG Code</FormLabel>
                      <Input
                        name='drg-code'
                        mb={4}
                        type='text'
                        placeholder='DRG Code eingeben'
                        //value={}
                        //onChange={inputChange}
                        isDisabled
                      ></Input>
                    </FormControl>

                    <FormControl>
                      <Checkbox
                        name='assume-patient-length-of-stay'
                        mb={4}
                        type='checkbox'
                        //value={Boolean()}
                        //onChange={togglePatientBooleanChange}
                        isDisabled
                      >
                        Aufenthaltsdauer aus DRG übernehmen.
                      </Checkbox>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Vorgesehene Station </FormLabel>
                      <Select
                        name='home_ward'
                        mb={4}
                        placeholder='Wählen Sie eine Station'
                        value={patient.home_ward}
                        isRequired={true}
                        onChange={patientChange}
                      >
                        {wards.map((ward) => (
                          <option value={ward.id}>{ward.name}</option>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl>
                      <Checkbox
                        name='isolation_needed'
                        mb={4}
                        type='checkbox'
                        isChecked={patient.isolation_needed}
                        value={Boolean(patient.isolation_needed)}
                        onChange={togglePatientBooleanChange}
                      >
                        Der Patient muss <b>isoliert</b> werden.
                      </Checkbox>
                    </FormControl>

                    <FormControl>
                      <Checkbox
                        name='bed_place_isolation_needed'
                        mb={4}
                        type='checkbox'
                        isChecked={patient.bed_place_isolation_needed}
                        value={Boolean(patient.bed_place_isolation_needed)}
                        onChange={togglePatientBooleanChange}
                      >
                        Der Patient muss <b>bettplatz-isoliert</b> werden.
                      </Checkbox>
                    </FormControl>

                    <FormControl>
                      <Checkbox
                        name='private_insurance'
                        mb={4}
                        type='checkbox'
                        isChecked={patient.private_insurance}
                        value={Boolean(patient.private_insurance)}
                        onChange={togglePatientBooleanChange}
                      >
                        Der Patient ist <b>privatversichert</b>.
                      </Checkbox>
                    </FormControl>

                    {/** Excludet due to current feedback - single room not relevant */}
                    {/** 
                <FormControl >
                  <Checkbox alignItems={"top"}
                    name='single_room_needed'
                    mb={4}
                    type='checkbox'
                    isChecked={patient.single_room_needed}
                    value={Boolean(patient.single_room_needed)}
                    onChange={togglePatientBooleanChange}
                  >
                    Der Patient hat einen Anspruch auf ein <b>Privatzimmer</b>.
                  </Checkbox>
                </FormControl>
                 */}

                    <FormControl>
                      <Checkbox
                        name='disturbs'
                        mb={4}
                        type='checkbox'
                        isChecked={patient.disturbs}
                        value={Boolean(patient.disturbs)}
                        onChange={togglePatientBooleanChange}
                      >
                        Der Patient <b>stört</b> andere Patienten.
                      </Checkbox>
                    </FormControl>
                  </Box>

                </HStack>

                <FormControl>
                  <FormLabel>Zusätzliche Informationen</FormLabel>
                  <Textarea
                    name="additional_patient_information"
                    resize='vertical'
                    mb={4}
                    type="text"
                    placeholder="Zusätzliche Informationen"
                    value={patient.additional_patient_information}
                    onChange={patientChange}
                    disabled
                  ></Textarea>
                </FormControl>


                <Text mt={5}> *Pflichtfelder </Text>
                <HStack mt={"10"}>
                  {isLoading ? (
                    <Button
                      width='full'
                      type='submit'
                      colorScheme='brand'
                      disabled={true}
                      isLoading
                      spinnerPlacement='end'
                      loadingText='Speichern'
                    />
                  ) : (
                    <Button
                      width='full'
                      type='submit'
                      colorScheme='brand'
                      disabled={false}
                    >
                      Änderungen Speichern
                    </Button>
                  )}
                  {showDelete ? (
                    <Button
                      width='full'
                      type='button'
                      colorScheme='brand'
                      onClick={() => {
                        setShowConfirmDelete(true)
                      }}
                    >
                      Löschen
                    </Button>
                  ) : (
                    ''
                  )}
                </HStack>

              </form>
            </Box>

          </ModalBody>
          <ModalFooter />
        </ModalContent>

      </Modal>

      <Modal
        size={"3xl"}
        isCentered
        isOpen={showConfirmDelete}
        scrollBehavior={'inside'}
        onClose={() => {
          setShowConfirmDelete(false)
        }}
        motionPreset='slideInLeft'>

        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px) hue-rotate(0deg)'
        />

        <ModalContent
          maxW={"900"}
          maxH={""}
          alignItems={"center"}
          textAlign={"center"}
        >
          <ModalHeader
            color={"#0F4046"}
            textAlign={"center"}
            fontSize={"32px"}
            fontWeight={"bold"}
          >
            Patient {patient.firstname} {patient.lastname} wirklich löschen?
          </ModalHeader>

          <ModalCloseButton />

          Alle Belegungsdaten des Patienten werden ebenfalls gelöscht. <br></br>
          Die Aktion kann nicht Rückgängig gemacht werden.

          <ModalBody className="addPatientBox">
            <HStack mt={"10"}>
              <Button
                colorScheme='brand'
                disabled={false}
                onClick={() => {
                  setShowConfirmDelete(false)
                }}
              >
                Zurück
              </Button>

              <Button
                colorScheme='brand'
                disabled={false}
                onClick={deletePatient}
              >
                Löschen
              </Button>
            </HStack>
            <ModalFooter />
          </ModalBody>

        </ModalContent>

      </Modal>
    </>
  )
}

export default EditPatientComponent
