import React from "react";
import { useIsomorphicLayoutEffect } from "./use-layout-effect";

export function useLatest<T>(value: T) {
  const ref = React.useRef(value);

  useIsomorphicLayoutEffect(() => {
    ref.current = value;
  });

  return ref;
}
