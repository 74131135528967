class SBFSSettings {
  id: number
  weight_minimize_rooms: number
  weight_no_disturbing: number
  weight_group_age: number
  config_plan_ahead: number
  config_match_threshold: number
  config_max_num_new_plans: number
 

  constructor(
    id = -1,
    weight_minimize_rooms = 0.33,
    weight_no_disturbing = 0.33,
    weight_group_age = 0.44,
    config_plan_ahead = 0.8,
    config_match_threshold = 0.8,
    config_max_num_new_plans = 25,
    
  ) {
    this.id = id
    this.weight_minimize_rooms = weight_minimize_rooms
    this.weight_no_disturbing = weight_no_disturbing
    this.weight_group_age = weight_group_age
    this.config_plan_ahead = config_plan_ahead
    this.config_match_threshold = config_match_threshold
    this.config_max_num_new_plans = config_max_num_new_plans
  }
}

export default SBFSSettings
