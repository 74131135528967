//import Chakra UI components
import { useContext, useEffect, useState } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Avatar,
  Box,
  Button,
  chakra,
  ChakraProvider,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
} from '@chakra-ui/react'
import { FaLock, FaUserAlt } from 'react-icons/fa'
import AuthContext from '../context/AuthContext'

import theme from '../theme/theme'
import emitter from '../util/event'

const CFaUserAlt = chakra(FaUserAlt)
const CFaLock = chakra(FaLock)

const LoginView = () => {
  const { loginUser } = useContext(AuthContext)

  const [showPassword, setShowPassword] = useState(false)
  const handleShowClick = () => setShowPassword(!showPassword)

  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState()

  useEffect(() => {
    const callback = () => {
      setLoginError(true)
    }
    emitter.on('loginError', callback)

    return () => {
      emitter.removeListener('loginError', callback)
    }
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <Flex
        flexDirection='column'
        width='100wh'
        height='100vh'
        backgroundColor='gray.200'
        justifyContent='center'
        alignItems='center'
      >
        <Stack flexDirection='column' justifyContent='top' alignItems='center'>
          <Avatar bg='brand.500' />
          <Heading color='brand.500' mb='10'>
            Wilkommen bei Medigital
          </Heading>
          <Box minW={{ base: '100%', md: '468px' }}>
            <form onSubmit={loginUser} id='login'>
              <Stack
                spacing={4}
                p='1rem'
                backgroundColor='whiteAlpha.900'
                boxShadow='md'
              >
                <FormControl>
                  {/* USERNAME */}
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={<CFaUserAlt color='brand.500' />}
                    />
                    <Input
                      type='text'
                      name='username'
                      placeholder='Nutzername'
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  {/* PASSWORD */}
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      color='gray.300'
                      children={<CFaLock color='brand.500' />}
                    />
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Passwort'
                      name='password'
                    />
                    <InputRightElement width='7rem'>
                      <Button h='1.75rem' size='sm' onClick={handleShowClick}>
                        {showPassword ? 'Ausblenden' : 'Einblenden'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <Button
                  id='buttonHandler'
                  borderRadius={6}
                  type='submit'
                  variant='solid'
                  colorScheme='brand'
                  width='full'
                  isLoading={loading}
                >
                  Einloggen
                </Button>
                {loginError ? (
                  <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>Fehler!</AlertTitle>
                    <AlertDescription>{loginError}</AlertDescription>
                  </Alert>
                ) : (
                  ''
                )}
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    </ChakraProvider>
  )
}

export default LoginView
