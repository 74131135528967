import React from "react";
import * as types from "../../calendar-types";
import { PatientInfo } from "../patientInfo";

interface RoomItemProps {
  bed: types.Bed;
  fixedNames: types.Event[];
}

function FixedName({ bed, fixedNames }: RoomItemProps) {
  const fixedEvent = fixedNames.filter((ev) => {
    if (ev.bedId === bed.id) {
      return true;
    }
    return false;
  })[0];


  var patient = fixedEvent ? fixedEvent.patient : null;

  if (patient && !patient.isInHomeWard) {
    //the isInHomeWard variable is true if the patient is in the selected wards OR if a accepted transferrequest exists
    patient = null;
  }

  const bedPlaceIsolation = patient?.bed_place_isolation_needed
    ? "bg-[#000000]"
    : "";
  const isolationNeeded = patient?.isolation_needed ? "bg-[#ffd16b]" : "";

  return (
    <div
      key={bed.id}
      className={
        "h-6 pl-6 flex text-sm items-center gap-4 text-slate-600 w-[250px] whitespace-nowrap"
      }
    >
      {patient && (
        <PatientInfo
          event={fixedEvent}
          patient={patient}
          bgColor={`${bedPlaceIsolation} ${isolationNeeded}`}
          fixName={true}
        />
      )}
    </div>
  );
}

export { FixedName };
