import { isSameDay } from "date-fns";
import dayjs from "dayjs";
import orderBy from "lodash.orderby"
import React from "react";
import { useDrop } from "react-dnd";
import * as types from "../../calendar-types";
import { Dates } from "../../calendar";
import { EventItem } from "./eventItem";
import Suggestion from "../../../../models/Suggestion";

interface Props {
  bed: types.Bed;
  room: types.Room;
  date: Date;
  dates: Dates;
  defaultEvents?: types.Event[];
  onChangeEvent: (event: types.Event) => void;
  suggestion: Suggestion[];
  suggestionPatientId: number | null;
}

function DayEventsPerBed({
  bed,
  date,
  dates,
  room,
  defaultEvents,
  onChangeEvent,
  suggestion,
  suggestionPatientId,
}: Props) {
  // console.log("day-evnets-per-bed: \nsevents:", defaultEvents)
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "DAY",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item: any) {
      const event = item.ev as types.Event;

      /*
        (DropLogic)
        Dont remove the CustomEvent: The drop event is listened in the CalendarManager
      */
      // only allow DnD (drag and drops) from 
      /**
       * Zu belegende -> Ausgehende : show transferrequest
       * Zu belegende -> Nicht-Aktionsbereich : action as used
       */
      console.log("day-events-per-bed room id", room.id)
      // next if statment blocks that we can DnD in these two areas
      if (!(room.id === "Zu_belegende_Patienten" && event.roomId == "Zu_belegende_Patienten")
        && !(["Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(room.id))
        && !(["Ausgehende_Anfragen", "Eingehende_Anfragen"].includes(event.roomId))) {
        const info = {
          assignmentId: event.assignmentId,
          bedId: bed.id,
          from: date,
          dateDrag: item.date,
          fromBed: event.bedId,
          patientId: event.patient.id,
          split: isSameDay(date, event.from) ? false : true,
        };

        const newEvent = {
          assignmentId: event.assignmentId,
          bedId: bed.id,
          roomId: room.id,
          from: event.from,
          to: event.to,
          dateDrag: item.date,
        };

        const droppedPatientInfo = new CustomEvent("droppedPatientInfo", {
          detail: {
            ...info,
          },
        });
        document.dispatchEvent(droppedPatientInfo);

        if (dayjs(date).isSame(dayjs().toDate(), "day")) {
          onChangeEvent({
            ...event,
            ...newEvent,
          });
        }
      }
    },
  }));
  const events = orderBy(defaultEvents, (event) => event.to, "asc");
  const border = events.length < 1 ? "border-x" : "";
  const zIndex = ""; //events.length === 0 ? "z-20" : "";
  const hover = isOver ? "bg-slate-200 rounded-md" : "";

  return (
    <div
      ref={!isNaN(parseInt(bed.id)) ? drop : undefined}
      className={`flex relative items-center gap-1 h-6 bg-slate-50 border-y ${zIndex} ${hover} ${border}`}
    >
      {events.map((event, i) => {
        return (
          (event.assignment_status == "B" || event.patient.isInHomeWard) ? 
          //the isInHomeWard variable is true if the patient is in the selected wards OR if a accepted transferrequest exists
          <React.Fragment key={event.id}>
            <EventItem
              index={i}
              len={events.length}
              date={date}
              dates={dates}
              event={event}
              suggestion={suggestion}
              suggestionPatientId={suggestionPatientId}
            />
          </React.Fragment>
          :
          ""
        )
        
      })}
    </div>
  );
}

export { DayEventsPerBed };
