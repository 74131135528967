
import { Icon } from "@chakra-ui/react"


export const DismissedTodayIcon = (props) => (
  <Icon viewBox="0 0 17 16" {...props}>
    <path
      fill="currentColor"
      d="M.336 4.532C.336 1.821 2.238 0 5.068 0h6.535c2.83 0 4.733 1.82 4.733 4.532v6.935c0 2.711-1.903 4.533-4.733 4.533H5.068c-2.83 0-4.732-1.822-4.732-4.533V4.532zm1.2 0v6.935c0 2.024 1.386 3.333 3.532 3.333h6.535c2.146 0 3.533-1.309 3.533-3.333V4.532c0-2.024-1.387-3.332-3.533-3.332H5.068c-2.146 0-3.532 1.308-3.532 3.332zM4.466 8a.6.6 0 01.6-.6h5.086L8.17 5.426a.6.6 0 11.847-.85l3.011 3c.02.019.037.04.054.06l-.054-.06c.032.03.06.065.084.103l.015.027c.01.017.02.035.028.053l.006.015a.459.459 0 01.02.058l.009.037.01.045.003.038a.368.368 0 01.002.048l-.004.047-.002.034a.08.08 0 010 .005L12.204 8a.599.599 0 01-.014.131l-.01.037a.458.458 0 01-.02.058l-.007.014a.438.438 0 01-.026.055l-.017.026a.703.703 0 01-.089.11l-3.005 2.992a.6.6 0 01-.847-.85L10.152 8.6H5.067a.6.6 0 01-.6-.6z"
    />
  </Icon>
)

export const DismissedTomorrowIcon = (props) => (
  <Icon viewBox="0 0 17 16" {...props}>
    <path
      fill="currentColor"
      d="M9.17252 13V2.365H8.19752C8.12752 2.765 7.99752 3.095 7.80752 3.355C7.61752 3.615 7.38252 3.82 7.10252 3.97C6.83252 4.12 6.52752 4.225 6.18752 4.285C5.84752 4.335 5.49752 4.36 5.13752 4.36V5.38H7.89752V13H9.17252Z" />
    <path
      fill="currentColor"
      d="M0 4.532C0 1.8208 1.9024 0 4.7328 0H11.2672C14.0976 0 16 1.8208 16 4.532V11.4672C16 14.1784 14.0976 16 11.2672 16H4.7328C1.9024 16 0 14.1784 0 11.4672V4.532ZM1.2 4.532V11.4672C1.2 13.4912 2.5864 14.8 4.7328 14.8H11.2672C13.4136 14.8 14.8 13.4912 14.8 11.4672V4.532C14.8 2.508 13.4136 1.2 11.2672 1.2H4.7328C2.5864 1.2 1.2 2.508 1.2 4.532Z" />
  </Icon>
)

export const DismissedInTwoDaysIcon = (props) => (
  <Icon viewBox="0 0 17 16" {...props}>
    <path
      fill="currentColor"
      d="M0 4.532C0 1.8208 1.9024 0 4.7328 0H11.2672C14.0976 0 16 1.8208 16 4.532V11.4672C16 14.1784 14.0976 16 11.2672 16H4.7328C1.9024 16 0 14.1784 0 11.4672V4.532ZM1.2 4.532V11.4672C1.2 13.4912 2.5864 14.8 4.7328 14.8H11.2672C13.4136 14.8 14.8 13.4912 14.8 11.4672V4.532C14.8 2.508 13.4136 1.2 11.2672 1.2H4.7328C2.5864 1.2 1.2 2.508 1.2 4.532Z" />
    <path
      fill="currentColor"
      d="M4.99252 6.13H6.26752C6.25752 5.81 6.28752 5.495 6.35752 5.185C6.43752 4.865 6.56252 4.58 6.73252 4.33C6.90252 4.08 7.11752 3.88 7.37752 3.73C7.64752 3.57 7.96752 3.49 8.33752 3.49C8.61752 3.49 8.88252 3.535 9.13252 3.625C9.38252 3.715 9.59752 3.845 9.77752 4.015C9.96752 4.185 10.1175 4.39 10.2275 4.63C10.3375 4.86 10.3925 5.12 10.3925 5.41C10.3925 5.78 10.3325 6.105 10.2125 6.385C10.1025 6.665 9.93252 6.925 9.70252 7.165C9.48252 7.405 9.20252 7.645 8.86252 7.885C8.52252 8.115 8.12752 8.37 7.67752 8.65C7.30752 8.87 6.95252 9.105 6.61252 9.355C6.27252 9.605 5.96752 9.895 5.69752 10.225C5.42752 10.555 5.20252 10.945 5.02252 11.395C4.85252 11.835 4.74252 12.37 4.69252 13H11.6375V11.875H6.17752C6.23752 11.545 6.36252 11.255 6.55252 11.005C6.75252 10.745 6.98752 10.505 7.25752 10.285C7.53752 10.065 7.84252 9.86 8.17252 9.67C8.50252 9.47 8.83252 9.27 9.16252 9.07C9.49252 8.86 9.81252 8.64 10.1225 8.41C10.4325 8.18 10.7075 7.925 10.9475 7.645C11.1875 7.355 11.3775 7.03 11.5175 6.67C11.6675 6.31 11.7425 5.895 11.7425 5.425C11.7425 4.925 11.6525 4.485 11.4725 4.105C11.3025 3.725 11.0675 3.41 10.7675 3.16C10.4675 2.9 10.1125 2.705 9.70252 2.575C9.30252 2.435 8.87252 2.365 8.41252 2.365C7.85252 2.365 7.35252 2.46 6.91252 2.65C6.47252 2.84 6.10252 3.105 5.80252 3.445C5.51252 3.775 5.29752 4.17 5.15752 4.63C5.01752 5.09 4.96252 5.59 4.99252 6.13Z" />
  </Icon>
)

export const DismissedInThreeDaysIcon = (props) => (
  <Icon viewBox="0 0 17 16" {...props}>
    <path
      fill="currentColor"
      d="M0 4.532C0 1.8208 1.9024 0 4.7328 0H11.2672C14.0976 0 16 1.8208 16 4.532V11.4672C16 14.1784 14.0976 16 11.2672 16H4.7328C1.9024 16 0 14.1784 0 11.4672V4.532ZM1.2 4.532V11.4672C1.2 13.4912 2.5864 14.8 4.7328 14.8H11.2672C13.4136 14.8 14.8 13.4912 14.8 11.4672V4.532C14.8 2.508 13.4136 1.2 11.2672 1.2H4.7328C2.5864 1.2 1.2 2.508 1.2 4.532Z" />
    <path
      fill="currentColor"
      d="M7.60252 6.925V8.005C7.84252 7.975 8.09752 7.96 8.36752 7.96C8.68752 7.96 8.98252 8.005 9.25252 8.095C9.53252 8.175 9.77252 8.305 9.97252 8.485C10.1725 8.655 10.3325 8.87 10.4525 9.13C10.5725 9.38 10.6325 9.67 10.6325 10C10.6325 10.32 10.5675 10.61 10.4375 10.87C10.3175 11.12 10.1525 11.335 9.94252 11.515C9.73252 11.685 9.48752 11.82 9.20752 11.92C8.92752 12.01 8.63252 12.055 8.32252 12.055C7.59252 12.055 7.03752 11.84 6.65752 11.41C6.27752 10.97 6.07752 10.405 6.05752 9.715H4.78252C4.77252 10.265 4.84752 10.755 5.00752 11.185C5.17752 11.615 5.41752 11.98 5.72752 12.28C6.03752 12.57 6.41252 12.79 6.85252 12.94C7.29252 13.09 7.78252 13.165 8.32252 13.165C8.82252 13.165 9.29252 13.1 9.73252 12.97C10.1825 12.83 10.5725 12.625 10.9025 12.355C11.2325 12.085 11.4925 11.75 11.6825 11.35C11.8825 10.94 11.9825 10.47 11.9825 9.94C11.9825 9.3 11.8225 8.745 11.5025 8.275C11.1925 7.805 10.7125 7.5 10.0625 7.36V7.33C10.4825 7.14 10.8325 6.86 11.1125 6.49C11.3925 6.12 11.5325 5.695 11.5325 5.215C11.5325 4.725 11.4475 4.3 11.2775 3.94C11.1175 3.58 10.8925 3.285 10.6025 3.055C10.3125 2.825 9.96752 2.655 9.56752 2.545C9.17752 2.425 8.75252 2.365 8.29252 2.365C7.76252 2.365 7.29252 2.45 6.88252 2.62C6.48252 2.79 6.14752 3.025 5.87752 3.325C5.60752 3.625 5.39752 3.985 5.24752 4.405C5.10752 4.825 5.02752 5.29 5.00752 5.8H6.28252C6.28252 5.49 6.32252 5.195 6.40252 4.915C6.48252 4.635 6.60252 4.39 6.76252 4.18C6.93252 3.97 7.14252 3.805 7.39252 3.685C7.65252 3.555 7.95252 3.49 8.29252 3.49C8.83252 3.49 9.28252 3.635 9.64252 3.925C10.0025 4.205 10.1825 4.63 10.1825 5.2C10.1825 5.48 10.1275 5.73 10.0175 5.95C9.90752 6.17 9.75752 6.355 9.56752 6.505C9.38752 6.645 9.17252 6.755 8.92252 6.835C8.68252 6.905 8.42752 6.94 8.15752 6.94H7.88752C7.83752 6.94 7.78752 6.94 7.73752 6.94C7.69752 6.94 7.65252 6.935 7.60252 6.925Z"
    />
  </Icon>
)



export const DisturbsIcon = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M9.824.346c.03.02.059.04.085.064 1.268 1.096 1.36 3.133 1.365 7.202v.387c0 4.329-.057 6.46-1.365 7.591a.68.68 0 01-.085.063A2.175 2.175 0 018.646 16c-1.304 0-2.598-1.13-3.605-2.01-.455-.397-1.143-.998-1.38-1.024a12.77 12.77 0 00-.707-.039c-.749-.03-1.34-.051-2.037-.683C.08 11.485.003 9.982 0 8.656v-.434L.003 8 0 7.776 0 7.342c.003-1.325.08-2.828.917-3.587.697-.631 1.29-.654 2.04-.682.205-.008.437-.017.706-.038.235-.028.923-.629 1.378-1.026C6.352.862 8.15-.704 9.824.346zM8.637 1.555c-.814 0-1.855.909-2.722 1.667-.834.727-1.492 1.303-2.149 1.357-.29.022-.54.033-.76.041-.7.026-.878.043-1.194.33-.363.328-.403 1.541-.405 2.451v.368l.002.228v.004l-.001.228v.369c.001.91.041 2.123.404 2.451.315.287.493.303 1.191.331.22.007.471.018.763.042.657.052 1.315.628 2.149 1.356 1.035.903 2.317 2.023 3.166 1.557.688-.656.781-2.361.786-5.969V8c0-3.86-.075-5.657-.786-6.336a.91.91 0 00-.444-.108zm6.354 2.256c1.346 2.588 1.346 5.798 0 8.378a.694.694 0 01-.611.39.649.649 0 01-.35-.104c-.337-.213-.453-.686-.26-1.056 1.098-2.106 1.098-4.726 0-6.838-.193-.372-.076-.845.26-1.057.339-.209.767-.084.96.287z"
    />
  </Icon>
)

export const InsuranceIcon = (props) => (
  <Icon viewBox="0 0 14 16" {...props}>
    <path
      fill="currentColor"
      d="M6.668 0c.89 0 5.563 1.604 6.182 2.223.515.515.507.932.471 2.929-.014.8-.034 1.89-.034 3.399 0 5.41-6.186 7.345-6.449 7.424a.575.575 0 01-.34 0c-.264-.08-6.45-2.015-6.45-7.424 0-1.507-.02-2.597-.034-3.397C.008 4.797.002 4.49 0 4.224v-.299c.007-.984.1-1.315.486-1.702C1.103 1.604 5.776 0 6.668 0zm0 1.179c-.667 0-4.872 1.48-5.357 1.886-.143.143-.149.393-.118 2.067.014.806.034 1.902.034 3.419 0 4.088 4.497 5.903 5.44 6.237.941-.336 5.441-2.16 5.441-6.237 0-1.519.02-2.615.035-3.421.03-1.672.024-1.922-.127-2.073-.476-.397-4.682-1.878-5.348-1.878zm2.925 4.59a.59.59 0 010 .834L6.529 9.668a.586.586 0 01-.324.165l-.093.007a.59.59 0 01-.417-.172l-1.487-1.49a.59.59 0 01.835-.833l1.07 1.071L8.76 5.77c.23-.23.602-.23.833 0z"
    />
    <path
      fill="currentColor"
      d="M6.668 0c.89 0 5.563 1.604 6.182 2.223.515.515.507.932.471 2.929-.014.8-.034 1.89-.034 3.399 0 5.41-6.186 7.345-6.449 7.424a.575.575 0 01-.34 0c-.264-.08-6.45-2.015-6.45-7.424 0-1.507-.02-2.597-.034-3.397C.008 4.797.002 4.49 0 4.224v-.299c.007-.984.1-1.315.486-1.702C1.103 1.604 5.776 0 6.668 0zm0 1.179c-.667 0-4.872 1.48-5.357 1.886-.143.143-.149.393-.118 2.067.014.806.034 1.902.034 3.419 0 4.088 4.497 5.903 5.44 6.237.941-.336 5.441-2.16 5.441-6.237 0-1.519.02-2.615.035-3.421.03-1.672.024-1.922-.127-2.073-.476-.397-4.682-1.878-5.348-1.878zm2.925 4.59a.59.59 0 010 .834L6.529 9.668a.586.586 0 01-.324.165l-.093.007a.59.59 0 01-.417-.172l-1.487-1.49a.59.59 0 01.835-.833l1.07 1.071L8.76 5.77c.23-.23.602-.23.833 0z"
    />
  </Icon>
)

export const GenderMale = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M10.91 0v1.074h3.258L10.28 4.96A6.226 6.226 0 006.255 3.49 6.262 6.262 0 000 9.745 6.262 6.262 0 006.255 16a6.262 6.262 0 006.255-6.255A6.227 6.227 0 0011.04 5.72l3.887-3.887v3.256H16V0h-5.09zM6.256 14.926a5.188 5.188 0 01-5.182-5.181 5.188 5.188 0 015.182-5.182 5.187 5.187 0 015.182 5.182 5.187 5.187 0 01-5.182 5.181z"
    />
  </Icon>
)

export const GenderFemale = (props) => (
  <Icon viewBox="0 0 11 16" {...props}>
    <path
      fill="currentColor"
      d="M8.966 8.966a5.26 5.26 0 000-7.43 5.26 5.26 0 00-7.43 0 5.26 5.26 0 000 7.43 5.227 5.227 0 003.182 1.509v2.149H2.493v1.067h2.225V16h1.066v-2.309H8.01v-1.067H5.784v-2.15a5.227 5.227 0 003.182-1.508zM2.29 2.29a4.174 4.174 0 012.96-1.225c1.072 0 2.144.408 2.96 1.225a4.192 4.192 0 010 5.92 4.192 4.192 0 01-5.92 0 4.191 4.191 0 010-5.92z"
    />
  </Icon>
)

export const ExchangeIcon = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M10 15.167H6C2.38 15.167.833 13.62.833 10V6C.833 2.38 2.38.833 6 .833h4c3.62 0 5.167 1.547 5.167 5.167v4c0 3.62-1.547 5.167-5.167 5.167zM6 1.833C2.927 1.833 1.833 2.927 1.833 6v4c0 3.073 1.094 4.167 4.167 4.167h4c3.073 0 4.167-1.094 4.167-4.167V6c0-3.073-1.094-4.167-4.167-4.167H6z"
    />
    <path
      fill="currentColor"
      d="M9.407 11.74a.494.494 0 01-.499-.5c0-.132.052-.26.145-.354L11.08 8.86a.503.503 0 01.707 0 .503.503 0 010 .707L9.76 11.593a.5.5 0 01-.353.147z"
    />
    <path
      fill="currentColor"
      d="M11.433 9.713H4.567a.504.504 0 01-.5-.5c0-.273.226-.5.5-.5h6.873c.273 0 .5.227.5.5 0 .274-.227.5-.507.5zM4.567 7.287a.494.494 0 01-.499-.5c0-.133.053-.26.145-.354L6.24 4.407a.503.503 0 01.707 0 .503.503 0 010 .706L4.92 7.14c-.1.1-.227.147-.353.147z"
    />
    <path
      fill="currentColor"
      d="M11.433 7.287H4.567a.504.504 0 01-.5-.5c0-.274.226-.5.5-.5h6.873c.273 0 .5.226.5.5 0 .273-.227.5-.507.5z"
    />
  </Icon>
)

export const PendingIcon = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="transparent"
      d="M11.02 19.5H7.5C6.88 19.5 6.33 19.48 5.84 19.41C3.21 19.12 2.5 17.88 2.5 14.5V9.5C2.5 6.12 3.21 4.88 5.84 4.59C6.33 4.52 6.88 4.5 7.5 4.5H10.96M15.02 4.5H16.5C17.12 4.5 17.67 4.52 18.16 4.59C20.79 4.88 21.5 6.12 21.5 9.5V14.5C21.5 17.88 20.79 19.12 18.16 19.41C17.67 19.48 17.12 19.5 16.5 19.5H15.02M15 2V22"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.095 12H11.103M7.09399 12H7.10399"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export const AcceptedIcon = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="transparent"
      d="M9.3 21H14.7C19.2 21 21 19.2 21 14.7V9.3C21 4.8 19.2 3 14.7 3H9.3C4.8 3 3 4.8 3 9.3V14.7C3 19.2 4.8 21 9.3 21Z" stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="transparent"
      d="M9 12.69L10.61 14.3L14.91 10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export const RejectedIcon = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="transparent"
      d="M12 9.00003V14M12 21.41H5.93999C2.46999 21.41 1.01999 18.93 2.69999 15.9L5.81999 10.28L8.75999 5.00003C10.54 1.79003 13.46 1.79003 15.24 5.00003L18.18 10.29L21.3 15.91C22.98 18.94 21.52 21.42 18.06 21.42H12V21.41Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="transparent"
      d="M11.995 17H12.004"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export const BedIcon = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      d="M16.2791 2.93023H7.81395V7.83915H6.91234V6.17054C6.91129 5.22514 6.53528 4.31876 5.86679 3.65026C5.1983 2.98175 4.29193 2.60572 3.34653 2.60465H1.30233V0H0V14H1.30233V12.057L18.2326 12.1924V14H19.5349V6.18605C19.5339 5.32285 19.1906 4.49529 18.5802 3.88492C17.9698 3.27455 17.1423 2.93121 16.2791 2.93023V2.93023ZM1.30233 3.90698H3.34653C3.94663 3.90766 4.52196 4.14635 4.9463 4.57069C5.37064 4.99502 5.60933 5.57035 5.61001 6.17046V7.83906H1.30233V3.90698ZM18.2326 10.89L1.30233 10.7546V9.14147H18.2326V10.89ZM18.2326 7.83915H9.11628V4.23256H16.2791C16.797 4.23315 17.2935 4.43915 17.6597 4.80538C18.026 5.1716 18.232 5.66813 18.2326 6.18605V7.83915Z"
    />
  </Icon>
)

export const EditIcon = (props) => (
  <Icon viewBox="0 0 14 14" {...props}>
    <path d="M3.23167 11.3867C2.87584 11.3867 2.54334 11.2642 2.30417 11.0367C2.00084 10.7508 1.85501 10.3192 1.90751 9.85249L2.12334 7.96249C2.16417 7.60666 2.38001 7.13416 2.63084 6.87749L7.42001 1.80833C8.61584 0.542493 9.86417 0.507493 11.13 1.70333C12.3958 2.89916 12.4308 4.14749 11.235 5.41333L6.44584 10.4825C6.20084 10.745 5.74584 10.99 5.39001 11.0483L3.51167 11.3692C3.41251 11.375 3.32501 11.3867 3.23167 11.3867ZM9.29251 1.69749C8.84334 1.69749 8.45251 1.97749 8.05584 2.39749L3.26667 7.47249C3.15001 7.59499 3.01584 7.88666 2.99251 8.05583L2.77667 9.94583C2.75334 10.1383 2.80001 10.2958 2.90501 10.395C3.01001 10.4942 3.16751 10.5292 3.36001 10.5L5.23834 10.1792C5.40751 10.15 5.68751 9.99833 5.80417 9.87583L10.5933 4.80666C11.3167 4.03666 11.5792 3.32499 10.5233 2.33333C10.0567 1.88416 9.65417 1.69749 9.29251 1.69749Z"
      fill="#0F4046" />
    <path d="M10.115 6.38692H10.0742C9.19012 6.30021 8.36005 5.92183 7.71475 5.31138C7.06945 4.70093 6.64561 3.89313 6.51 3.01525C6.475 2.77609 6.63833 2.55442 6.8775 2.51359C7.11667 2.47859 7.33833 2.64192 7.37917 2.88109C7.48597 3.56746 7.81781 4.1989 8.32252 4.67616C8.82724 5.15342 9.47623 5.44946 10.1675 5.51775C10.4067 5.54109 10.5817 5.75692 10.5583 5.99609C10.5292 6.21775 10.3367 6.38692 10.115 6.38692ZM12.25 13.2708H1.75C1.51083 13.2708 1.3125 13.0725 1.3125 12.8333C1.3125 12.5942 1.51083 12.3958 1.75 12.3958H12.25C12.4892 12.3958 12.6875 12.5942 12.6875 12.8333C12.6875 13.0725 12.4892 13.2708 12.25 13.2708Z"
      fill="#0F4046" />
  </Icon>
)

export const AddIcon = (props) => (
  <Icon viewBox="0 0 22 22" width="22" height="22" {...props}>
    <path
      fill="none"
      d="M7 11H15M11 15V7M11 21C16.5 21 21 16.5 21 11C21 5.5 16.5 1 11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21Z"
      stroke="#DFDFDF"
      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </Icon>
)
