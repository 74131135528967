import WebSocketService from "../apis/WebSocketService";
import { useEffect, useState } from "react";
import emitter from "../util/event";


enum RealTimeUpdateMessageTypes {
	PATIENT = "patient",
	ASSIGNMENT = "assignment",
	BED_WARD_ROOM = "bed_ward_room",
	SETTINGS = "settings",
}

// Max number of attemps if we loss the websocket connection
const MAX_RELOAD_ATTEMPTS = 3;

// 10 sec of time out duration
const TIMEOUT_DURATION = 10000;

const getReloadAttempts = () => {
	// get the data from storage
	return Number(localStorage.getItem("reloadAttempts") || 0);
};

const setReloadAttempts = (value: number) => {
	// write reload attemps to local storage of webpage,
	// otherwise will loss the state of varaible
	localStorage.setItem("reloadAttempts", String(value));
};

const reload = () => {
	// reload the page if websocket connection is down

	let reloadAttempts = getReloadAttempts();

	if (reloadAttempts < MAX_RELOAD_ATTEMPTS) {
		reloadAttempts++;
		setReloadAttempts(reloadAttempts);

		// set timeout for every 10 sec.
		setTimeout(() => {
			// Refresh the page
			window.location.reload();
		}, TIMEOUT_DURATION); //
	}
};

//create socket connection and handle the received messages
export const RealTimeUpdate = () => {
	
	// useEffect used otherwise it opens 2 connection for each UI
	useEffect(() => {
		let webSocketService = new WebSocketService("realtimeupdate");

		//handles the received message from server
		webSocketService.onMessage((event) => {
			const data = JSON.parse(event.data);

			// According to message type specific events are emitted,
			// Emitted events are connected to the specific views to call
			// update function for that view
			if (RealTimeUpdateMessageTypes.PATIENT == data.message_type) {
				console.log("get patient");
				emitter.emit("update_room_view");
				emitter.emit("update_calender_view");
				emitter.emit("update_stats_view");
				emitter.emit("update_patient_view");
			}
			if (RealTimeUpdateMessageTypes.ASSIGNMENT == data.message_type) {
				console.log("get assignments");
				emitter.emit("update_room_view");
				emitter.emit("update_calender_view");
				emitter.emit("update_stats_view");
				emitter.emit("update_patient_view");
			}
			if (RealTimeUpdateMessageTypes.BED_WARD_ROOM == data.message_type) {
				console.log("get bed_ward_room");
				emitter.emit("update_room_view");
				emitter.emit("update_calender_view");
				emitter.emit("update_stats_view");
				emitter.emit("update_settings_view");
			}
			if (RealTimeUpdateMessageTypes.SETTINGS == data.message_type) {
				console.log("get settings");
				emitter.emit("update_settings_view");
			}
		});

		// handle the close event for socket connection
		webSocketService.onClose((event) => {
			console.log("Connection closed", event);
			reload();
		});

		// handle the error event for socket connection
		webSocketService.onError((event) => {
			console.log("Error occurred", event);
			reload();
		});

		webSocketService.onOpen((event) => {
			console.log("Socket connection opened", event);

			// reset the parametes
			setReloadAttempts(0);
		});
	}, []);
};
