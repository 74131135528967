import Cookies from 'js-cookie'
import { createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthCenter from '../apis/AuthCenter'
import emitter from '../util/event'

const AuthContext = createContext()

export default AuthContext

export const AuthProvider = ({ children }) => {
  let [refreshToken, setRefreshToken] = useState(() =>
    Cookies.get('refreshToken') ? decodeURI(Cookies.get('refreshToken')) : null
  )
  let [accessToken, setAccessToken] = useState(() =>
    Cookies.get('accessToken') ? decodeURI(Cookies.get('accessToken')) : null
  )
  let [user, setUser] = useState(() =>
    Cookies.get('username') ? decodeURI(Cookies.get('username')) : null
  )

  const authCenter = new AuthCenter()

  const navigate = useNavigate()

  let loginUser = async (e) => {
    e.preventDefault()
    const msg = await authCenter.login(
      e.target.username.value,
      e.target.password.value
    )
    if (msg.isSucc) {
      setAccessToken(msg.accessToken)
      setUser(msg.username)
      setRefreshToken(msg.refreshToken)
      navigate('/')
    } else {
      emitter.emit('loginError')
      // window.dispatchEvent(new CustomEvent('loginError', {detail: true}))
    }
  }

  let logoutUser = () => {
    // Still not contact with backend
    authCenter.logout()

    setAccessToken(null)
    setRefreshToken(null)
    setUser(null)
    navigate('/login')
  }

  let contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    accessToken: accessToken,
    authCenter: authCenter,
  }

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  )
}
