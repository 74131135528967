import React, {useEffect, useState} from "react";

import dayjs from "dayjs";
import "dayjs/locale/de";
import {compareDesc, parseISO} from "date-fns";

import APIUtils from "../apis/APIUtils";
import AssignedAreaComponent from "../components/AssignedAreaComponent";
import emitter from "../util/event";
import Cookies from "js-cookie";
import Stats from "../models/Stats";
import Suggestion from "../models/Suggestion";
import Bed from "../models/Bed";
import Room from "../models/Room";
import {Assignment, AssignmentType} from "../models/Assignment";
import Ward from "../models/Ward";
import { bool } from "prop-types";

dayjs.locale("de");
const selectedDateFromCookie = () =>
    Cookies.get("medigital_selectedDate")
        ? parseISO(Cookies.get("medigital_selectedDate")!)
        : new Date();

const RoomView = () => {
    const abortController = new AbortController();
    const username = Cookies.get("username");
    const updateSelectedDate = () => setSelectedDate(selectedDateFromCookie());

    const [beds, setBeds] = useState<Bed[]>([]);
    const [stats, setStats] = useState<Stats>(new Stats());
    const [rooms, setRooms] = useState<Room[]>([]);
    const [assignments, setAssignments] = useState<Assignment[]>([]);
    const [wards, setWards] = useState<Ward[]>([]);
    const [wardsSelected, setWardsSelected] = useState<number[]>([]);
    const [suggestion, setSuggestion] = useState<Suggestion[]>([]);
    const [suggestionPatientId, setSuggestionPatientId] = useState<number | null>(null);
    const [selectedDate, setSelectedDate] = useState(selectedDateFromCookie);
    const [isDragEnabled, setIsDragEnabled] = useState(true);


    const {getAssignments, getStats} = APIUtils();


  // Wards, Rooms and Beds load
  useEffect(() => {
    const { getBeds, getRooms, getWards, getAllWards } = APIUtils();

    // update wards
    const updateWards = async () => {
      const response = await getWards();
      if (response?.isSuccessful) {
        return response.wards ?? []
      }
      return [];
    };

    //get wards, rooms, beds at once from the backend and build them to proper objects
    //this ensures the order is static
    const updateRoomsBeds = async () => {
      const r1 = await getAllWards();
      if (r1?.isSuccessful) {
        const r2 = await getRooms();
        if (r2?.isSuccessful) {
          const r3 = await getBeds();
          if (r3?.isSuccessful) {
            let rooms = formatRooms(r2.rooms, r1.wards)
            let beds = formatBeds(r3.beds, rooms)
            return [rooms, beds]
          }
        }
      }
      return [];
    };

    const fetchData = async () => {
      const wards = await updateWards();
      const [rooms, beds] = await updateRoomsBeds();
      setWards(wards)
      setRooms(rooms)
      setBeds(beds)

      const wardsSelected = wards.map(ward => ward.id);
      setWardsSelected(wardsSelected);
    }

        fetchData()

    }, [username]);


    // this event will be emitted by the socket connection at real_time_update module
    // whenever it gets new message type that patient_create_room_view
    useEffect(() => {
        emitter.on("update_room_view", fetchDataLater);

        return () => {
            emitter.removeListener("update_room_view", fetchDataLater);
        };
    }, [])

    const fetchDataLater = () => {
       
        const abortController = new AbortController();
        updateAssignments();
        updateStatistics(abortController);
        // this can be used to disable drag and drop of assignments
        // e.g. to disable changeing the past
        // currently drag and drop is always enabled
        setIsDragEnabled(true);
    }

    useEffect(() => {
        fetchDataLater()
        return () => {
            abortController.abort();
        };
    }, [selectedDate, wardsSelected]);

    //Get the bed information from the server and save it to the state
    const assignmentsFromServerToState = async () => {
        await updateAssignments();
        await updateStatistics();
        setSuggestion([]);
        setSuggestionPatientId(null);
    };

    // fetch bed assignments from backend and update the state
    const updateAssignments = async () => {
        const response = await getAssignments(selectedDate);
        if (response?.isSuccessful) {
            const _assignments = response.assignments ?? [];
            // Sort assignments by end
            _assignments.sort((a: Assignment, b: Assignment) =>
                compareDesc(parseISO(a.start), parseISO(b.start))
            );

            setAssignments(_assignments);
        }
    };

    // fetch statistics from backend and update the state
    const updateStatistics = async (abortController?: AbortController) => {
        const response = await getStats(selectedDate, wardsSelected, {signal: abortController?.signal});
        if (response?.isSuccessful) {
            setStats(response.stats ?? new Stats());
        }
    };

  //get an assignment id (as) and bed id (be) and update the state so that the patient is on the bed
  //this is called as an optimistic update right after a patient assignment is changed
  const modifyAssignmentsInState = (as: string, be: string) => {
    const queryAssignmentId = parseInt(as);
    const queryBedId = parseInt(be);
    let targetAssignment: Assignment | undefined = undefined;
    let targetIdx = -1;

    //get target assignment of assignment 
    for (const [idx, a] of assignments.entries()) {
      if (a.id === queryAssignmentId) {
        targetIdx = idx;
        targetAssignment = a;
      }
    }

    if (targetAssignment) {
      //modify the respective patient
      //1: update the state if the patient is going to "not assigned"
      if (be === "no") {
        targetAssignment.assignment_status = AssignmentType.W;
      }
      //2: change the bed to the new bed
      else {

        targetAssignment.bed = beds.find(bed => bed.id === queryBedId)

        if (targetAssignment.assignment_status !== AssignmentType.F) {
          //if the previous status was "fix", then the new status does not change
          //if the previous status was not "fix", then the new status should be "reserved"
          targetAssignment.assignment_status = AssignmentType.R;
        }
      }

      let new_assignments = [...assignments.slice(0, targetIdx), targetAssignment, ...assignments.slice(targetIdx + 1)];

      setAssignments(new_assignments);
    }
  };


  //ensure that rooms contain wards, not ids
  //Note: This function is obsolete, once the backend only sends objects within objects, instead of ids  
  function formatRooms(rooms, wards) {
    return rooms.map(r => typeof r.ward === "number"
      ? new Room(r.id, r.name, r.size, wards.find(w => w.id === r.ward)) : r)
  }

  //ensure that beds contain rooms, not ids
  //Note: This function is obsolete, once the backend only sends objects within objects, instead of ids  
  function formatBeds(beds, rooms) {
    return beds.map(b => typeof b.room === "number"
      ? new Bed(b.active, b.id, b.name, b.quick_access_possible, rooms.find(r => r.id === b.room), b.type) : b)
  }

  return (
    <AssignedAreaComponent
      assignments={assignments}
      rooms={rooms}
      beds={beds}
      wards={wards}
      wardsSelected={wardsSelected}
      suggestion={suggestion}
      suggestionPatientId={suggestionPatientId}
      modifyAssignmentsInState={modifyAssignmentsInState}
      assignmentsFromServerToState={assignmentsFromServerToState}
      selectedDate={selectedDate}
      isDragEnabled={isDragEnabled}
      setSelectedDate={setSelectedDate}
      username={username}
      stats={stats}
      setWardsSelected={setWardsSelected}

    />
  );
};

export default RoomView;
