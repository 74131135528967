import React from "react";
import classNames from "classnames";

interface Props {
  src: string;
  color: string;
}

function Avatar({ src, color }: Props) {
  return (
    <span className="relative inline-block">
      <img className="h-8 w-8 rounded-full" src={src} alt="" />
      <span
        className={classNames(
          "absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white",
          {
            [color]: true,
          }
        )}
      />
    </span>
  );
}

export { Avatar };
