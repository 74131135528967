// importing the required chakra libraries

import { extendTheme, defineStyle, defineStyleConfig } from '@chakra-ui/react'

const thick = defineStyle({
  borderWidth: 2, // change the width of the border
  borderStyle: "solid", // change the style of the border
  borderColor: "white",
  opacity: '0.25',
  borderRadius: 10, // set border radius to 10
})

const dividerTheme = defineStyleConfig({
  variants: { thick },
})

const theme = extendTheme({

 breakpoints : {
        sm: '576px',     // Porträt-Handy
        md: '768px',     // Tablets
        lg: '1280px',    // HD 13"
        xl: '1920px',    // FHD 14"
        '2xl': '2560px', // QHD 27"
  },

  widthSidebarExpanded : 'calc(100% - 260px)',
  widthSidebarExpandedNoCalc : '260px',
  widthSidebarCollapsed : 'calc(100% - 80px)',
  widthSidebarCollapsedNoCalc : '80px',
  

  colors: {
    sub: {
      400: '#000000',
      500: '#ffd16b'
      
    },

    background: '#0F4046',

    contentBackground: '#F5FAFE', 

    
    selectedMenuItem: {
      500: '#57797E'
    },
    
    brand: {
      100: '#E6F6ED',
      400: '#A6F2FC',
      500: '#37b7c7',
      600: '#6941FA',
      700: '#D9E9EB',
      900: '#0F4046'
    },

    patientStatus: {
      100: '#A6F2FC',
      200: '#37b7c7',
      300: '#FFD3E5',
      400: '#FF84B6',
      500: 'repeating-linear-gradient(135deg, #37b7c7, #37b7c7 10px, #2F99A6 10px, #2F99A6 20px )',
      600: 'repeating-linear-gradient(135deg, #FF84B6, #FF84B6 10px, #DD6F9C 10px, #DD6F9C 20px )'
    },

    bedStatus: {
      100: '#D9E9EB',  // unassigned
      200: '#FFC442',  // isolated
      300: '#FF5143'   // blocked
    }

  },
  components: { Divider: dividerTheme },
})

export default theme
