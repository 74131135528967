import { Box, Divider, Flex, Text, VStack } from '@chakra-ui/react'

// The card component in PatientDetailComponent
const CardComponent = ({ title, content, button, icon }) => {
  return (
    <Box
      p='3'
      boxShadow='md'
      m='4'
      borderRadius='15'
      height={75}
      border='0.5px'
      borderColor='#37b7c7'
      bg='gray.50'
    >
      <Flex alignItems='center' justifyContent='space-between'>
        <Box mr={5} ml={5}>
          <VStack spacing={1}>
            <Text fontWeight='bold' color='#37b7c7'>
              {' '}
              {title}{' '}
            </Text>
            <Divider />
            {content}
          </VStack>
        </Box>
        {title === 'Status der Belegung' ? <Box>{button}</Box> : ''}

        {title === 'Isolation' ? <Box>{icon}</Box> : ''}
      </Flex>
    </Box>
  )
}

export default CardComponent
