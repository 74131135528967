import React, { useEffect, useState } from "react"
import { Droppable } from "react-beautiful-dnd"
import { Box, Text, HStack, Link, Heading, Button, Image, VStack } from "@chakra-ui/react"
import emitter from "../util/event"

import PatientCapsuleComponent from "./PatientCapsuleComponent"

import Filter from "../assets/Filter.svg"
import AddPatient from "../assets/AddPatient.svg"
import MultiAssign from '../assets/Multi_assign.svg'
import { Assignment } from "../models/Assignment"
import Suggestion from "../models/Suggestion"
import APICenter from '../apis/APICenter';
import Patient from "../models/Patient"
import ProposalComponent from './ProposalComponent'

type HeadingSubTitleProps = {
  children: React.ReactNode
}

const HeadingSubTitle = (props: HeadingSubTitleProps) => (
  <Heading fontSize="xl" color="white" fontWeight="bold" mb="2">
    {props.children}
  </Heading>
)

const FilterLink = () => (
  <HStack mb="2" spacing="1">
    <Image width="5" src={Filter} />
    <Link fontSize="md" fontStyle="italic" color="brand.500" href="#">
      Räume Filtern
    </Link>
  </HStack>
)

type IconButtonProps = {
  text: string
  image: string
  onClick: () => void
  bg: string
  bgHover: string
  additionalProps?: ButtonProps
}

const IconButton = (props: IconButtonProps) => (
  <Button
    width={280}
    height={'80px'}
    bg={props.bg}
    mt="2"
    color="white"
    justifyContent='flex-start'
    fontWeight="normal"
    _hover={{ bg: props.bgHover }}
    cursor="pointer"
    onClick={props.onClick}
    whiteSpace={'normal'}
    {...props.additionalProps}
  >
    <Image boxSize='48px' mr="3" src={props.image} />
    {props.text}
  </Button>
)

// The patients which have not yet been assigned to a bed will be shown in this component
type NotAssignedAreaComponentProps = {
  assignments: Assignment[]
  suggestion: Suggestion[]
  suggestionPatientId: number | null
  isDragEnabled: boolean
  selectedDate: Date
  sourceDroppableId: string
  wardsSelected: number[]
}

const NotAssignedAreaComponent = ({
  assignments,
  suggestion,
  suggestionPatientId,
  isDragEnabled,
  selectedDate,
  sourceDroppableId,
  wardsSelected
}: NotAssignedAreaComponentProps) => {

  const apiCenter = new APICenter();

  const showPatientForm = () => {
    emitter.emit("showAddPatient", true)
  }

  const showTransferRequReceiver = (assignment) => {
    emitter.emit('showTransferRequest', "receiver", assignment, suggestion, suggestionPatientId, selectedDate);
  };

  const setSelectionActive = () => {
    setActiveSelection(true)
  }

  const setSelectionInActive = () => {
    setActiveSelection(false)
  }

  const assignSelectedPatients = async () => {
    setIsLoading(true)
    var patient_ids = selectedPatients.map(p => p.id)
    const msg = await apiCenter.createNewProposedPlan(patient_ids);
    if (msg.proposed_plan_id != -1) {
      emitter.emit('showProposal', msg.proposed_plan_id)
    } else {
      alert("Die automatische Belegung konnte nicht durchgeführt werden.")
    }
    setSelectedPatients([])
    setActiveSelection(false)
  }


  useEffect(() => {
    const callback = () => {
      setIsLoading(false)
    }

    emitter.on("show_proposal_successful", callback)
    return () => {
      emitter.removeListener("show_proposal_successful", callback)
    }
  })

  useEffect(() => {
    const callback = () => {
      alert("Es konnte für keinen der Patienten ein Bett gefunden werden.")
      setIsLoading(false)
    }

    emitter.on("show_proposal_failed", callback)
    return () => {
      emitter.removeListener("show_proposal_failed", callback)
    }
  })

  useEffect(() => {
    setSelectedPatients([])
    setActiveSelection(false)
  }, [selectedDate])



  const [activeSelection, setActiveSelection] = useState(false)
  const [selectedPatients, setSelectedPatients] = useState(new Array<Patient>())
  const [isLoading, setIsLoading] = useState(false)



  const showRequestPanel = (assignment) => {
    switch (assignment.request.state) {
      case 'P':
        emitter.emit('showTransferRequest', 'edit', assignment, suggestion, suggestionPatientId, selectedDate);
        break;
      case 'A':
        emitter.emit('showTransferRequest', 'accepted', assignment, suggestion, suggestionPatientId, selectedDate);
        break;
      case 'R':
        emitter.emit('showTransferRequest', 'rejected', assignment, suggestion, suggestionPatientId, selectedDate);
        break;
      default:
        break;
    }
  }

  function isPresent(obj) {
    return obj !== null && obj !== undefined;
  }

  //gets assignments for the patients to be occupied box
  function getAssignmentsForPatientsToBeOccupied() {
    var ass = assignments.filter(function (a) {
      return (!isPresent(a.request) && wardsSelected.includes(a.patient.home_ward))
        || (isPresent(a.request)
          && a.request.state === 'A'
          && !isPresent(a.bed)
          && wardsSelected.includes(a.request.request_receiver_ward))
    })

    return ass
  }

  //gets assignments for the outgoing requests box
  function getAssignmentsWithOutgoingRequests() {
    return assignments.filter(function (a) {
      return isPresent(a.request)
        && (!isPresent(a.bed)
          && (a.request.state === 'P'
            || a.request.state === 'R'
            || a.request.state === 'A'))
        && wardsSelected.includes(a.request.request_sender_ward)
    })
  }

  //gets assignments for the incoming requests box
  function getAssignmentsWithIncomingRequests() {
    return assignments.filter(function (a) {
      return isPresent(a.request)
        && a.request.state === 'P'
        && wardsSelected.includes(a.request.request_receiver_ward)
    })
  }

  return (
    <>
      <VStack p="3" bg="brand.900" borderRadius="10">
        <HeadingSubTitle>Aktionsbereich</HeadingSubTitle>
        <FilterLink />
        <Box p="2" borderRadius="6" width={280} bg="brand.100" aria-label={"unassigned-patient-box"}>
          <Text fontSize="md" color="brand.900" mb={2}>
            Zu belegende Patienten
          </Text>
          <Droppable droppableId="no" isDropDisabled={sourceDroppableId === 'outReq' || sourceDroppableId === 'inReq'}>
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {getAssignmentsForPatientsToBeOccupied().map((a, index) => (
                  <PatientCapsuleComponent
                    key={a.id}
                    index={index}
                    patient={a.patient}
                    assignment={a}
                    suggestion={suggestion}
                    suggestionPatientId={suggestionPatientId}
                    selectedDate={selectedDate}
                    isDragEnabled={isDragEnabled}
                    activeSelection={activeSelection}
                    selectedPatients={selectedPatients}
                    setSelectedPatients={setSelectedPatients}
                  />
                ))}
                {provided.placeholder}
              </Box>)}
          </Droppable>
        </Box>
        <Box p="2" borderRadius="6" width={280} bg="brand.100">
          <Text fontSize="md" color="brand.900" mb={2}>
            Ausgehende Anfragen
          </Text>
          <Droppable droppableId="outReq" isDropDisabled={sourceDroppableId !== 'no'}>
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {getAssignmentsWithOutgoingRequests().map((a, index) => (
                  <PatientCapsuleComponent
                    key={a.id}
                    index={index}
                    patient={a.patient}
                    assignment={a}
                    suggestion={suggestion}
                    suggestionPatientId={suggestionPatientId}
                    selectedDate={selectedDate}
                    isDragEnabled={false}
                    onClick={() => showRequestPanel(a)}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>

        <Box p="2" borderRadius="6" width={280} bg="brand.100">
          <Text fontSize="md" color="brand.900" mb={2}>
            Eingehende Anfragen
          </Text>

          <Droppable droppableId="inReq" isDropDisabled={true}>
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {getAssignmentsWithIncomingRequests().map((a, index) => (
                  <PatientCapsuleComponent
                    key={a.id}
                    index={index}
                    patient={a.patient}
                    assignment={a}
                    suggestion={suggestion}
                    suggestionPatientId={suggestionPatientId}
                    selectedDate={selectedDate}
                    isDragEnabled={false}
                    onClick={() => showTransferRequReceiver(a)}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>
        {!activeSelection &&
          <IconButton text="Patienten für automatische Belegung auswählen" image={MultiAssign} onClick={setSelectionActive} bg="brand.600" bgHover="brand.500" />}
        {(activeSelection && selectedPatients.length === 0) &&
          <IconButton text="Automatische Belegung abbrechen" image={MultiAssign} onClick={setSelectionInActive} bg="#F3B126" bgHover="sub.500" />}
        {(activeSelection && selectedPatients.length > 0) &&
          <IconButton text="Automatische Belegung durchführen" image={MultiAssign} onClick={assignSelectedPatients} bg="#4EE44E" bgHover="#B8F4B8" additionalProps={{
            isLoading: isLoading,
            spinnerPlacement: 'end'
          }} />}
        <IconButton text="Patienten hinzufügen" image={AddPatient} onClick={showPatientForm} bg="brand.600" bgHover="brand.500" />
      </VStack>
      <ProposalComponent></ProposalComponent>
    </>
  )

}

export default NotAssignedAreaComponent
