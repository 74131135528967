import React from "react"
import { Wrap, Box, Text, SimpleGrid } from "@chakra-ui/react"
import { Assignment } from "../models/Assignment"
import Bed from "../models/Bed"
import Room from "../models/Room"
import Ward from "../models/Ward"
import RoomComponent from "./RoomComponent"

type WardComponentProps = {
  ward: Ward
  wardRooms: Room[]
  beds: Bed[]
  assignments: Assignment[]
  isDragEnabled: boolean
  selectedDate: Date
}

const WardComponent = ({
  ward,
  wardRooms,
  beds,
  assignments,
  isDragEnabled,
  selectedDate
}: WardComponentProps) => {

  //all beds that are visible, 
  //i.e. beds of a room in this ward and beds of an extern room, occupied by a patient of this ward
  const visibleBeds = beds
    .filter(b => wardRooms.some(r => r.ward.id === ward.id && b.room.id === r.id))
    .concat(assignments
      .filter(a => 
        a.patient
        && a.patient.home_ward === ward.id
        && a.bed.room.ward.id != ward.id)
      .map(a => a.bed))

  return (
    <Box>
      <Text color="brand.900" fontSize="xl" fontStyle="normal">{ward.name}</Text>
      <Wrap spacing="5" pt="8" pb="8">
        {wardRooms.map(r => (
          <SimpleGrid
            key={r.id}
            spacing="10"
            minChildWidth="120px"
            height={`calc(48px * ${visibleBeds.filter(b => b.room.id === r.id).length + 1})`}
          >
            <RoomComponent
              selectedDate={selectedDate}
              key={r.id}
              room={r}
              beds={visibleBeds.filter(b => b.room.id === r.id)}
              assignments={assignments}
              isDragEnabled={isDragEnabled}
              roomWard={r.ward}
              renderedWardId={ward.id}
            />
          </SimpleGrid>
        ))}
      </Wrap>
    </Box>
  )
}

export default WardComponent
