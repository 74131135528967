import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { format, isSameDay, subDays } from "date-fns";
import { useRef } from "react";
import { Waypoint } from "react-waypoint";
import {
  Room as RoomType,
  Mode,
  Event,
  RoomsStatus,
  Ward,
} from "../../calendar-types";
import { useLatest } from "../../use-latest";
import { useIsomorphicLayoutEffect } from "../../use-layout-effect";
import { DayEventsContent } from "./day-events-content";
import { Dates } from "../../calendar";
import dayjs from "dayjs";
import Suggestion from "../../../../models/Suggestion";

interface Props {
  date: Date;
  dates: Dates;
  daysToShow?: number;
  events: Event[];
  onChangeEvent: (event: Event) => void;
  mode?: Mode;
  onChangeDate?: (date: Date) => void;
  onEnter: (args: Waypoint.CallbackArgs) => void;
  rooms: RoomType[];
  status: RoomsStatus;
  width: number;
  wards: Ward[];
  wardsStatus: RoomsStatus;
  setFixedNames: React.Dispatch<React.SetStateAction<Event[]>>;
  suggestion: Suggestion[];
  suggestionPatientId: number | null;
}

type Position = "above" | "below" | "inside";

function DayEvents({
  date,
  dates,
  events,
  onChangeDate,
  onChangeEvent,
  onEnter,
  rooms,
  status,
  width,
  wards,
  wardsStatus,
  setFixedNames,
  suggestion,
  suggestionPatientId,
}: Props) {
  const onPositionChangeRef = useLatest(onChangeDate);
  const positionRef = useRef<Position | undefined>();
  const elRef = useRef<HTMLDivElement | null>(null);
  const handlePosition = (args: Waypoint.CallbackArgs) => {
    positionRef.current = args.currentPosition as Position;
  };
  useIsomorphicLayoutEffect(() => {
    const handleScroll = () => {
      const el = elRef.current;
      const parentEl = el?.parentElement;
      if (positionRef.current === "inside" && el && parentEl) {
        const rect = el.getBoundingClientRect();
        const offset = width;
        if (rect.left <= width + offset) {
          onPositionChangeRef.current?.(
            subDays(date, Number(format(date, "i")) - 1)
          );
        }
      }
    };
    if (elRef.current) {
      elRef.current.parentElement?.addEventListener("scroll", handleScroll);
      elRef.current.parentElement?.parentElement?.addEventListener(
        "scroll",
        handleScroll
      );
    }
    return () => {
      if (elRef.current) {
        elRef.current.parentElement?.removeEventListener(
          "scroll",
          handleScroll
        );
        elRef.current.parentElement?.parentElement?.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, [elRef]);

  const [currentDate, setCurrentDate] = useState(false);

  useEffect(() => {
    if (elRef.current?.parentElement?.parentElement) {
      const cDate = dayjs(dates[0])
        .add(
          (elRef.current?.parentElement?.parentElement?.scrollLeft - 130) / 200,
          "day"
        )
        .toDate();
      if (dayjs(cDate).isSame(date, "day")) {
        if (!currentDate) {
          setCurrentDate(true);
        }
      } else {
        if (currentDate) {
          setCurrentDate(false);
        }
      }
    }
  }, [dates, elRef.current?.parentElement?.parentElement?.scrollLeft]);

  useEffect(() => {
    if (currentDate && events.length > 0) {
      const newFixedNames = events.filter((event) => {
        if (
          !isSameDay(date, event.from) &&
          !isSameDay(date, event.to) &&
          !isSameDay(date, dayjs(event.from).add(1, "day").toDate())
        ) {
          return true;
        }
        return false;
      });
      setFixedNames(newFixedNames);
    } else if (currentDate && events.length === 0) {
      setFixedNames([]);
    }
  }, [currentDate]);

  return (
    <div
      ref={elRef}
      className={classNames("flex flex-col items-center")}
      style={{ width }}
      id={format(date, "yyyy-MM-dd")}
    >
      <Waypoint
        horizontal
        fireOnRapidScroll
        onPositionChange={handlePosition}
        onEnter={onEnter}
      />
      <DayEventsContent
        onChangeEvent={onChangeEvent}
        date={date}
        dates={dates}
        rooms={rooms}
        wards={wards}
        events={events}
        status={status}
        wardsStatus={wardsStatus}
        suggestion={suggestion}
        suggestionPatientId={suggestionPatientId}
      />
    </div>
  );
}

export { DayEvents };
