import { Assignment } from "../models/Assignment";
import Bed from "../models/Bed";
import Patient from "../models/Patient";
import Room from "../models/Room";
import SBFSSettings from "../models/SBFSSettings";
import { default as Stats, default as StatsRange } from "../models/StatsRange";
import Suggestion from "../models/Suggestion";
import Ward from "../models/Ward";

// Base class for any message
export class Message {
	protected isSuccessful: boolean;
	protected info: string;

	constructor(isSuccessful: boolean, info: string) {
		this.isSuccessful = isSuccessful;
		this.info = info;
	}

	get isSucc(): boolean {
		return this.isSuccessful;
	}

	get infoText(): string {
		return this.info;
	}
}

// AuthMessage for passing token, username, etc.
export class AuthMessage extends Message {
	private username?: string;
	private accessToken?: string;
	private refreshToken?: string;

	get isSucc(): boolean {
		return this.isSuccessful;
	}

	constructor(
		isSuccessful = false,
		info = "undefined",
		username = "undefined",
		accessToken = "undefined",
		refreshToken = "undefined"
	) {
		super(isSuccessful, info);
		this.username = username;
		this.accessToken = accessToken;
		this.refreshToken = refreshToken;
	}
}

// For a failed assignment
export class AssignmentFailMessage extends Message {
	patient: Patient;
	reason: string;
	suggestions: Array<Suggestion>;

	constructor(
		isSuccessful = false,
		info = "undefined",
		patient: Patient = new Patient(),
		reason = "undefined",
		suggestions: Array<Suggestion> = []
	) {
		super(isSuccessful, info);
		this.patient = patient;
		this.reason = reason;
		this.suggestions = suggestions;
	}
}

// For a successful assignment
// Bed and room will be passed in
export class AssignmentSuccMessage extends Message {
	patient: Patient;
	bed: Bed;
	room: Room;

	constructor(
		isSuccessful = false,
		info = "undefined",
		patient: Patient = new Patient(),
		bed: Bed = new Bed(),
		room: Room = new Room()
	) {
		super(isSuccessful, info);
		this.patient = patient;
		this.bed = bed;
		this.room = room;
	}
}

// For passing the patient related information
export class FetchPatientMessage extends Message {
	patient: Patient;
	homeWard: Ward;
	assignment: Array<Assignment>;
	isSuggestionAvailable: boolean;
	suggestion: Array<Suggestion>;

	constructor(
		isSuccessful = false,
		info = "undefined",
		patient: Patient = new Patient(),
		homeWard: Ward = new Ward(),
		assignment: Array<Assignment> = [],
		isSuggestionAvailable = false,
		suggestion: Array<Suggestion> = []
	) {
		super(isSuccessful, info);
		this.patient = patient;
		this.homeWard = homeWard;
		this.assignment = assignment;
		this.isSuggestionAvailable = isSuggestionAvailable;
		this.suggestion = suggestion;
	}
}

// For passing the beds information
export class FetchBedsMessage extends Message {
	beds: Array<Bed> = [];

	constructor(
		isSuccessful = false,
		info = "undefined",
		beds: Array<Bed> = []
	) {
		super(isSuccessful, info);
		this.beds = beds;
	}
}

// For passing the beds information
export class FetchRoomsMessage extends Message {
	rooms: Array<Room> = [];

	constructor(
		isSuccessful = false,
		info = "undefined",
		rooms: Array<Room> = []
	) {
		super(isSuccessful, info);
		this.rooms = rooms;
	}
}

// For passing the assignments information
export class FetchAssignmentsMessage extends Message {
	assignments: Array<Assignment> = [];

	constructor(
		isSuccessful = false,
		info = "undefined",
		assignments: Array<Assignment> = []
	) {
		super(isSuccessful, info);
		this.assignments = assignments;
	}
}

export class FetchSuggestionMessage extends Message {
	patientId: number;
	suggestions: Array<Suggestion>;

	constructor(
		isSuccessful = false,
		info = "undefined",
		patientId = -1,
		suggestions: Array<Suggestion> = []
	) {
		super(isSuccessful, info);
		this.patientId = patientId;
		this.suggestions = suggestions;
	}
}

export class FetchStatsMessage extends Message {
	stats: Stats;

	constructor(
		isSuccessful = false,
		info = "undefined",
		stats: Stats = new Stats()
	) {
		super(isSuccessful, info);
		this.stats = stats;
	}
}

export type WardResponse = {
	name: string;
	id: number;
}

export class FetchStatsRangeMessage extends Message {
	stats_range: { stats: StatsRange[]; wards: WardResponse[] };

	constructor(
		isSuccessful = false,
		info = "undefined",
		stats_range = { stats: [], wards: [] }
	) {
		super(isSuccessful, info);
		this.stats_range = stats_range;
	}
}

export class FetchSBFSSettingsMessage extends Message {
	sbfssettings: SBFSSettings;

	constructor(
		isSuccessful = false,
		info = "undefined",
		sbfssettings: SBFSSettings = new SBFSSettings()
	) {
		super(isSuccessful, info);
		this.sbfssettings = sbfssettings;
	}
}

// For passing the suggestion information
export class SuggestionMessage extends Message {
	suggestion?: Array<Suggestion>;
	reason?: string;

	constructor(
		isSuccessful = false,
		info = "undefined",
		suggestion: Array<Suggestion> = [],
		reason = "undefined"
	) {
		super(isSuccessful, info);
		this.suggestion = suggestion;
		this.reason = reason;
	}
}

export class FetchWardsMessage extends Message {
	wards: Array<Ward> = [];

	constructor(
		isSuccessful = false,
		info = "undefined",
		wards: Array<Ward> = []
	) {
		super(isSuccessful, info);
		this.wards = wards;
	}
}

export class ProposedPlanMessage extends Message {
	proposed_plan_id?: number

	constructor(
		isSuccessful: boolean = false,
		info: string = "undefined",
		proposed_plan_id: number = -1
	) {
		super(isSuccessful, info);
		this.proposed_plan_id = proposed_plan_id
	}
}