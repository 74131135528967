import React from 'react'
import { Box, Heading } from '@chakra-ui/react'

// Wildcard for any unrecognized URL href
const NotFoundView = () => {
  return (
    <Box ml={500} mt={300}>
      <Heading as='span' size='xl' mt={7} color='#37b7c7'>
        There's nothing here!
      </Heading>
    </Box>
  )
}
export default NotFoundView
