import React from "react";
import { VStack, Flex } from "@chakra-ui/react";

import HeadingTitle from "../components/common/HeadingTitle";
import StatisticsGraphComponent from "../components/StatisticsGraphComponent";

const UtilizationView = () => {
  return (
      <>
        <VStack spacing={3} ml={0} mr={0} mt={10} mb={10}>
          <Flex width="100%" justifyItems="bottom" alignItems="flex-end">
            <HeadingTitle>Patientenbelegung</HeadingTitle>
          </Flex>
        </VStack>
        <StatisticsGraphComponent />
      </>
  );
};

export default UtilizationView;