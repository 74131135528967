/* eslint-disable no-undef */

import APICenter from "./APICenter";
import {
  FetchAssignmentsMessage,
  FetchBedsMessage,
  FetchRoomsMessage,
  FetchSuggestionMessage,
  FetchWardsMessage,
  FetchStatsMessage,
  FetchStatsRangeMessage,
} from "./Message";
import { toast } from "react-toastify";
import { format } from "date-fns";

const APIUtils = () => {

  const apiCenter = new APICenter();

  const abort = () => {
    apiCenter.abortRequest();
  }

  const getData = async (dataType, params = {}, requestParams = {}) => {
    let response;
    let messageType;
    switch (dataType) {
      case "assignments":
        response = await apiCenter.fetchAssignments(params, requestParams);
        messageType = FetchAssignmentsMessage;
        break;
      case "beds":
        response = await apiCenter.fetchBeds(requestParams);
        messageType = FetchBedsMessage;
        break;
      case "rooms":
        response = await apiCenter.fetchRooms(requestParams);
        messageType = FetchRoomsMessage;
        break;
      case "wards":
        response = await apiCenter.fetchWards(requestParams);
        messageType = FetchWardsMessage;
        break;
      // case "allWards":
      //   // showAll makes that all wards will be send
      //   response = await apiCenter.fetchAllWards(requestParams);
      //   messageType = FetchWardsMessage;
      //   break;
      case "stats":
        response = await apiCenter.fetchStats(params, requestParams);
        messageType = FetchStatsMessage;
        break;
      case "stats_range":
        response = await apiCenter.fetchStatsRange(params, requestParams);
        messageType = FetchStatsRangeMessage;
        break;
      default:
        return null;
    }

    if (response) {
      if (response.isSucc && response instanceof messageType) {
        return {
          isSuccessful: true,
          data: response[dataType],
        };
      } else if (response.infoText instanceof DOMException && response.infoText.message.includes("AbortError")) {
        console.log("Request got aborted");
        return {
          isSuccessful: false,
        };
      } else {
        toast("Unable to fetch data!");
        return {
          isSuccessful: false,
        };
      }
    } else {
      console.log("Internal Error");
      return {
        isSuccessful: false,
      };
    }
  };

  const getSuggestions = async () => {
    const response = await apiCenter.fetchSuggestion();
    return (response && response instanceof FetchSuggestionMessage && response.isSucc) ?
      {
        isSuccessful: true,
        suggestions: response.suggestions,
        patientId: response.patientId,
      } :
      {
        isSuccessful: false,
      };
  };

  const getWards = async () => {
    const response = await apiCenter.fetchWards();
    return (response && response instanceof FetchWardsMessage && response.isSucc) ?
      {
        isSuccessful: true,
        wards: response.wards,
      } :
      {
        isSuccessful: false,
      };
  };

  const getAllWards = async () => {
    const response = await apiCenter.fetchAllWards();
    return (response && response instanceof FetchWardsMessage && response.isSucc) ?
      {
        isSuccessful: true,
        wards: response.wards,
      } :
      {
        isSuccessful: false,
      };
  };

  const getRooms = async () => {
    const response = await apiCenter.fetchRooms();
    return (response && response instanceof FetchRoomsMessage && response.isSucc) ?
      {
        isSuccessful: true,
        rooms: response.rooms,
      } :
      {
        isSuccessful: false,
      };
  };

  const getBeds = async () => {
    const response = await apiCenter.fetchBeds();
    return (response && response instanceof FetchBedsMessage && response.isSucc) ?
      {
        isSuccessful: true,
        beds: response.beds,
      } :
      {
        isSuccessful: false,
      };
  };

  const getAssignments = async (date) => {
    const response = await apiCenter.fetchAssignments({ date_contains: format(date, "yyyy-MM-dd") });
    return (response && response instanceof FetchAssignmentsMessage && response.isSucc) ?
      {
        isSuccessful: true,
        assignments: response.assignments
      } :
      {
        isSuccessful: false,
      };
  };

  const getStats = async (date, wards, requestParams = {}) => {
    const response = await apiCenter.fetchStats({ date_contains: format(date, "yyyy-MM-dd"), wards }, requestParams);
    if (response.infoText instanceof DOMException && response.infoText.message.includes("AbortError")) {
      console.log("Request got aborted");
    }
    return (response && response instanceof FetchStatsMessage && response.isSucc) ?
      {
        isSuccessful: true,
        stats: response.stats
      } :
      {
        isSuccessful: false,
      };
  };

  const assignPatientToBed = async (assignmentId, bedId, selectedDate) => {
    const response = await apiCenter.updateAssignedBed(
      assignmentId,
      bedId,
      selectedDate.format("YYYY-MM-DD")
    );
    if (response && response.isSucc) {
      console.log("Successfully assigned patient to bed");
      if (response.infoText) {
        return {
          isSuccessful: true,
          comment: response.infoText,
        };
      } else {
        return {
          isSuccessful: true,
        };
      }
    } else {
      if (response.infoText === "Unauthorized") {
        toast("Assigning patient to bed failed!");
        return {
          isSuccessful: false,
        };
      } else {
        return {
          isSuccessful: false,
          reason: response.infoText,
        };
      }
    }
  };

  const removePatientFromBed = async (assignmentId, selectedDate) => {
    const response = await apiCenter.clearAssignment(
      assignmentId,
      selectedDate.format("YYYY-MM-DD")
    );
    if (response && response.isSucc) {
      console.log("Successfully removed patient from bed");
    } else {
      toast("Removing patient from bed failed!");
    }
  };

  return { getData, getSuggestions, getWards, getAllWards, getRooms, getBeds, getAssignments, getStats, assignPatientToBed, removePatientFromBed, abort };
};

export default APIUtils;
